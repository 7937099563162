import { Button, Checkbox, Flex, Form, Modal, Spin } from "antd";
import CreateClassModal from "components/CreateClassModal";
import { useQuiz } from "hook/useQuiz";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.scss";
import { useQuestion } from "hook/useQuestion";
import { NotificationContext } from "provider/NotificationProvider";

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  questionId: string;
  // reloadQuizData: () => void;
}

const AddToQuiz = (props: Props) => {
  const question = useQuestion();
  const { id } = useParams();
  const quiz = useQuiz();
  const [form] = Form.useForm();
  const { isOpen, handleCancel, questionId } = props;
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openAddClassModal, setOpenAddClassModal] = useState(false);
  const [listQuiz, setListQuiz] = useState<Quiz.FullQuizInfo[]>([]);
  const { openNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (isOpen) {
      getQuizzes();
      form.setFieldsValue({
        questions: [questionId],
        quizzes: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getQuizzes = () => {
    setLoading(true);
    quiz.getMyCreatedQuiz({
      callback: {
        onSuccess: (res: Quiz.FullQuizInfo[]) => {
          setListQuiz(res);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const addToQuiz = () => {
    if (!id) return;
    setSubmitting(true);

    question.addQuestionsToQuizzes({
      params: form.getFieldsValue(true),
      callback: {
        onSuccess: () => {
          openNotification("Thêm vào học phần thành công!", "");
          onCancel();
        },
        onFinish: () => {
          setSubmitting(false);
        },
      },
    });
  };

  const onFinish = (values: any) => {
    addToQuiz();
    handleCancel();
  };

  const onCancel = () => {
    handleCancel();
    // reloadQuizData();
  };

  return (
    <>
      <Modal
        className="add-quiz-model"
        title={<h2>Thêm vào học phần</h2>}
        open={isOpen}
        onCancel={() => onCancel()}
        footer={
          <Flex justify="flex-end">
            <Button
              style={{ height: "46px" }}
              className="btn-submit"
              type="primary"
              size="large"
              loading={submitting}
              onClick={() => form.submit()}
            >
              Thêm
            </Button>
          </Flex>
        }
        width={800}
        style={{ top: 20 }}
      >
        <Spin spinning={loading} style={{ minHeight: 400 }}>
          <Flex vertical gap="large" className="form-content">
            <Form
              className="base-form"
              form={form}
              layout="vertical"
              scrollToFirstError
              autoComplete="off"
              onFinish={onFinish}
            >
              <Form.Item
                name="quizzes"
                rules={[{ required: true, message: "Vui lòng chọn học phần!" }]}
              >
                <Checkbox.Group>
                  <Flex vertical gap="small">
                    {listQuiz.map((item) => {
                      return (
                        <Checkbox
                          value={item._id}
                          key={item._id}
                          style={{ lineHeight: "32px" }}
                          // disabled={
                          //   classHaveQuiz.find(
                          //     (classId) => classId === item._id
                          //   )
                          //     ? true
                          //     : false
                          // }
                        >
                          <div className="quiz-bar">{item?.title}</div>
                        </Checkbox>
                      );
                    })}
                  </Flex>
                </Checkbox.Group>
              </Form.Item>
            </Form>
          </Flex>
        </Spin>
      </Modal>
      <CreateClassModal
        isOpen={openAddClassModal}
        handleCancel={() => setOpenAddClassModal(false)}
        reloadData={getQuizzes}
      />
    </>
  );
};

export default AddToQuiz;

import {
  CheckCircleFilled,
  DeleteOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Button, Flex, Tag, Tooltip } from "antd";
import BaseImage from "components/BaseImage";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";
import "./style.scss";

const QuizCard = ({ quizInfo }: { quizInfo: Quiz.FullQuizInfo }) => {
  const navigate = useNavigate();
  return (
    <Flex vertical justify="space-between" className="quiz-card" gap="small">
      <Flex vertical gap="small">
        <span className="name">
          {quizInfo?.title}
          {quizInfo?.verified && quizInfo?.is_public ? (
            <Tooltip title={"Học phần public"}>
              <CheckCircleFilled
                style={{
                  color: "#52c41a",
                  paddingLeft: "8px",
                  fontSize: 14,
                }}
              />
            </Tooltip>
          ) : (
            <></>
          )}
        </span>
        <Flex align="center" gap="small" wrap>
          <Tag color="#55acee" style={{ width: "fit-content" }}>
            {`${quizInfo.question_count} thuật ngữ`}
          </Tag>
          <Tag color="blue" style={{ width: "fit-content" }}>
            {quizInfo?.subject?.subject_name}
          </Tag>
        </Flex>
        {quizInfo?.review_count ? (
          <Flex align="small">
            <StarFilled
              style={{
                color: "#ffcd1f",
                paddingRight: 8,
                fontSize: 14,
              }}
            />
            <span>{`${quizInfo?.review_average} (${quizInfo?.review_count}) đánh giá`}</span>
          </Flex>
        ) : (
          <></>
        )}
      </Flex>
      <Flex gap="small" justify="space-between" align="center">
        <Flex
          gap="small"
          align="center"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/users/${quizInfo.creator?._id}`);
          }}
          className="creator"
        >
          <BaseImage
            className="avatar"
            src={quizInfo?.creator?.avatar}
            width={32}
            height={32}
          />
          <span>{quizInfo?.creator?.name || "user"}</span>
        </Flex>

        {getUserInfo() && (
          <Tooltip placement="bottom" title="Xóa">
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              className="btn-border-gray"
              danger
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

export default QuizCard;

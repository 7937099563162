import { Button, Flex, Form, Input, Modal, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useFolder } from "hook/useFolder";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  reloadFolderData: () => void;
}

const EditFolderModal = (props: Props) => {
  const { id } = useParams();
  const folder = useFolder();
  const { isOpen, handleCancel, reloadFolderData } = props;
  const [form] = Form.useForm<Folder.UpdateFolder>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getFolderInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getFolderInfo = () => {
    if (!id) return;
    setLoading(true);

    folder.getFolderById({
      id,
      callback: {
        onSuccess: (res: Folder.FullInfo) => {
          form.setFieldsValue({
            folder_name: res.folder_name,
            description: res.description,
          });
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const updateFolder = () => {
    if (!id) return;
    setSubmitting(true);

    folder.updateFolder({
      id,
      params: form.getFieldsValue(),
      callback: {
        onSuccess: () => {
          reloadFolderData();
          handleCancel();
        },
        onFinish: () => {
          setSubmitting(false);
        },
      },
    });
  };

  return (
    <Modal
      title={<h2>Sửa thư mục</h2>}
      open={isOpen}
      onCancel={() => handleCancel()}
      footer={
        <Flex justify="end">
          <Button
            style={{ height: "46px" }}
            className="btn-submit"
            type="primary"
            size="large"
            loading={submitting}
            onClick={() => form.submit()}
          >
            Lưu
          </Button>
        </Flex>
      }
      width={800}
      style={{ top: 20 }}
    >
      <Spin spinning={loading}>
        <Form
          className="base-form"
          form={form}
          layout="vertical"
          scrollToFirstError
          autoComplete="off"
          onFinish={updateFolder}
        >
          <Form.Item<Folder.CreateFolder>
            label="Tiêu đề"
            name="folder_name"
            hasFeedback
            rules={[{ required: true, message: "Vui lòng nhập tiêu đề!" }]}
          >
            <Input allowClear placeholder="Tiêu đề" />
          </Form.Item>

          <Form.Item<Folder.CreateFolder> label="Mô tả" name="description">
            <TextArea allowClear placeholder="Mô tả" autoSize />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditFolderModal;

import { Button, Card, Col, Flex, Row } from "antd";
import { useNavigate } from "react-router-dom";
import "./style.scss";

interface FeatureCard {
  value: string;
  image: string;
  title: string;
  description: string;
}
const feature: FeatureCard[] = [
  {
    value: "test",
    image: "/images/test.avif",
    title: "Sẵn sàng 100% cho ngày thi",
    description:
      "Với Học và Kiểm tra, hãy biến thẻ ghi nhớ của bạn thành bài kiểm tra thử và nắm vững tài liệu học.",
  },
  // {
  //   value: "mobile",
  //   image: "/images/mobile.avif",
  //   title: "Mang sự học đi bất cứ đâu",
  //   description:
  //     "Tải ứng dụng miễn phí để học ở mọi nơi. Có trên iOS và Android.",
  // },
  {
    value: "gif",
    image: "/images/gif.avif",
    title: "Làm được nhiều hơn với thẻ ghi nhớ của bạn",
    description:
      "Trên Quizlet, bạn có thể làm được nhiều hơn là lật thẻ ghi nhớ. Với 3 chế độ học để lựa chọn, ai cũng tìm được phương thức phù hợp.",
  },
  {
    value: "find",
    image: "/images/landing-find.avif",
    title: "Tìm thẻ ghi nhớ trực tuyến",
    description:
      "Cần thẻ ghi nhớ để học từ vựng, phương trình hoặc kiến thức giải phẫu? Với hàng triệu thẻ ghi nhớ do các sinh viên và giáo viên khác tạo, bạn có thể tìm thấy thẻ ghi nhớ miễn phí cho bất kỳ chủ đề nào trên Quizlet.",
  },
  {
    value: "create",
    image: "/images/create.avif",
    title: "Tạo thẻ ghi nhớ",
    description:
      "Việc tạo bộ thẻ ghi nhớ của riêng bạn thật đơn giản với trình tạo thẻ ghi nhớ miễn phí của chúng tôi – chỉ cần nhập một thuật ngữ và định nghĩa. Bạn thậm chí có thể thêm một hình ảnh từ thư viện của chúng tôi. Khi bộ thẻ ghi nhớ đã hoàn thành, bạn có thể học và chia sẻ nó với bạn bè.",
  },
];

const FeatureCards = () => {
  const navigate = useNavigate();
  return (
    <Flex vertical gap="4rem" className="feature-cards">
      {feature.map((item) => {
        return (
          <Row gutter={[24, 24]} key={item.value}>
            <Col xs={24} sm={24} md={12}>
              <Card bordered={false}>
                <h1>{item.title}</h1>
                <p>{item.description}</p>
                <Button type="primary" onClick={() => navigate("/login")}>
                  Thử ngay
                </Button>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Card
                bordered={false}
                cover={<img alt="feature" src={item.image} />}
              />
            </Col>
          </Row>
        );
      })}
    </Flex>
  );
};

export default FeatureCards;

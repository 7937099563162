import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Col, Flex, Row } from "antd";
import { getCorrectMessage, getWrongMessage } from "constant/quiz";
import "./style.scss";

const ResultCard = ({ result }: { result: Test.ResultDetails }) => {
  const getResultClass = (answer: Question.Answer): string => {
    if (answer.is_correct) return "correct";
    if (result.userAnswerId === answer._id && !answer.is_correct)
      return "wrong";
    return "";
  };

  return (
    <div className="result-card">
      <p className="question">{result.question.text}</p>
      <div>
        {result.isCorrect ? (
          <p className="title title-correct">{getCorrectMessage()}</p>
        ) : (
          <p className="title title-wrong">{getWrongMessage()}</p>
        )}

        <div className="answers-container">
          <Row gutter={[12, 12]}>
            {result.question.answers.map((item: Question.Answer, index) => {
              return (
                <Col xs={24} md={12} key={item._id}>
                  <Flex
                    className={`answer ${getResultClass(item)}`}
                    align="center"
                    gap="large"
                  >
                    {getResultClass(item) === "" ? (
                      <></>
                    ) : getResultClass(item) === "correct" ? (
                      <CheckOutlined
                        style={{ fontSize: "24px", color: "#12815a" }}
                      />
                    ) : (
                      <CloseOutlined
                        style={{ fontSize: "24px", color: "#b00020" }}
                      />
                    )}
                    <span className="content">{item?.text}</span>
                  </Flex>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ResultCard;

import { Flex, Image, Spin } from "antd";
import "./style.scss";

interface Props {
  [key: string]: any;
}

const BaseImage = (props: Props) => {
  return (
    <Image
      {...props}
      preview={props?.preview || false}
      src={props?.src || "/images/default-avatar.svg"}
      placeholder={
        <Flex align="center" justify="center" className="image-placeholder">
          <Spin />
        </Flex>
      }
    ></Image>
  );
};

export default BaseImage;

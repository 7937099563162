import { Button, Form, Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useFolder } from "hook/useFolder";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  reloadData?: () => void;
}

const initialFormValue: Folder.CreateFolder = {
  folder_name: "",
  description: "",
};

const CreateFolderModal = (props: Props) => {
  const folder = useFolder();
  const navigate = useNavigate();
  const { isOpen, handleCancel, reloadData } = props;
  const [form] = Form.useForm<Folder.CreateFolder>();
  const [submitting, setSubmitting] = useState(false);
  const { openNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue(initialFormValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const createFolder = () => {
    setSubmitting(true);
    folder.createFolder({
      params: form.getFieldsValue(),
      callback: {
        onSuccess: (res: Folder.CreateFolderResponse) => {
          if (reloadData) {
            reloadData();
          } else {
            navigate(`/folders/${res._id}`);
          }
          handleCancel();
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setSubmitting(false);
        },
      },
    });
  };

  return (
    <Modal
      title={<h2>Tạo thư mục mới</h2>}
      open={isOpen}
      onCancel={() => handleCancel()}
      footer={
        <Button
          style={{ height: "46px" }}
          className="btn-submit"
          type="primary"
          size="large"
          loading={submitting}
          onClick={() => form.submit()}
        >
          Tạo thư mục
        </Button>
      }
      width={800}
      style={{ top: 20 }}
    >
      <Form
        className="base-form"
        form={form}
        layout="vertical"
        scrollToFirstError
        autoComplete="off"
        onFinish={createFolder}
      >
        <Form.Item<Folder.CreateFolder>
          label="Tiêu đề"
          name="folder_name"
          hasFeedback
          rules={[{ required: true, message: "Vui lòng nhập tiêu đề!" }]}
        >
          <Input allowClear placeholder="Tiêu đề" />
        </Form.Item>

        <Form.Item<Folder.CreateFolder> label="Mô tả" name="description">
          <TextArea allowClear placeholder="Mô tả" autoSize />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateFolderModal;

import { useHttpRequest } from "./useHttpRequest";

export const useQuizAPI = () => {
  const httpRequest = useHttpRequest();

  const createNewQuiz = async (
    params: Quiz.NewQuizSetParams
  ): Promise<Quiz.NewQuizSetResponse> => httpRequest.POST("/quizzes", params);

  const getMyCreatedQuiz = async (
    params?: App.Pagination
  ): Promise<Quiz.GetMyCreatedQuizPaginationResponse> =>
    httpRequest.GET("/quizzes/created", { params });

  const getQuizInfoById = async (id: string): Promise<Quiz.FullQuizInfo> =>
    httpRequest.GET(`/quizzes/${id}`);

  const getQuizInfoByFolderId = async (
    id: string
  ): Promise<Quiz.FullQuizInfo[]> =>
    httpRequest.GET(`/quizzes/by-folder/${id}`);

  const getQuizInfoByClassId = async (
    id: string
  ): Promise<Quiz.FullQuizInfo[]> =>
    httpRequest.GET(`/quizzes/by-classroom/${id}`);

  const getQuizInfoByAccountId = async (
    id: string,
    params?: App.Pagination
  ): Promise<Quiz.FullQuizInfo[]> =>
    httpRequest.GET(`/quizzes/by-account/${id}`, { params });

  const finishQuiz = async (params: Quiz.FinishQuiz): Promise<void> =>
    httpRequest.POST("/quiz-records", params);

  const getAllQuiz = async (
    params: App.Pagination
  ): Promise<Quiz.GetMyCreatedQuizPaginationResponse | Quiz.FullQuizInfo[]> =>
    httpRequest.GET("/quizzes", { params });

  const updateQuiz = async (
    id: string,
    params: Quiz.UpdateQUiz
  ): Promise<Quiz.NewQuizSetResponse> =>
    httpRequest.PATCH(`/quizzes/${id}`, params);

  const getQuizHistory = async (id: string): Promise<Quiz.History[]> =>
    httpRequest.GET(`/attempts/by-quiz/${id}`);

  return {
    createNewQuiz,
    getMyCreatedQuiz,
    getQuizInfoById,
    finishQuiz,
    getQuizInfoByFolderId,
    getQuizInfoByAccountId,
    getQuizInfoByClassId,
    getAllQuiz,
    updateQuiz,
    getQuizHistory,
  };
};

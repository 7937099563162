import { Button, Col, Flex, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import firework from "assets/json/firework.json";
import BaseImage from "components/BaseImage";
import { alphabet } from "constant/quiz";
import { memo } from "react";
import Lottie from "react-lottie";
import { useNavigate, useParams } from "react-router-dom";
import "./style.scss";

interface Props {
  listFlashcard: Question.Value[];
  resetCard: () => void;
}

const FinishLearn = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { listFlashcard, resetCard } = props;

  const getQuestion = (question: Question.Value) => {
    if (question.answers.length === 1) {
      return question.text;
    }

    const answer =
      question.answers.map((item, index) => {
        return `\n\n${alphabet[index]}. ${item.text}`;
      }) || "";

    return `${question.text}${answer}`;
  };

  const getAnswer = (question: Question.Value) => {
    if (question.answers.length === 1) {
      return question.answers[0].text;
    }

    for (let i = 0; i < question.answers.length; i++) {
      if (question.answers[i].is_correct) {
        return `${alphabet[i]}. ${question.answers[i].text}`;
      }
    }

    return "";
  };

  return (
    <div className="finish-learn">
      <Flex vertical gap="1.5rem" className="show-image" align="center">
        <div className="firework">
          <Lottie
            options={{
              loop: 1,
              autoplay: true,
              animationData: firework,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            speed={0.5}
            isPaused={false}
          />
        </div>
        <Flex gap={24}>
          <h1>Chúc mừng! Bạn đã học tất cả các thuật ngữ.</h1>
          <div className="congratulation-image">
            <BaseImage src="/images/congratulation.svg" />
          </div>
        </Flex>
        <Flex align="center" gap="large" wrap>
          <Button
            className="btn-border-gray"
            size="large"
            onClick={() => navigate(`/quizzes/${id}/test`)}
          >
            Làm bài kiểm tra
          </Button>
          <Button type="primary" size="large" onClick={resetCard}>
            Ôn luyện thêm
          </Button>
        </Flex>
      </Flex>
      <div className="learned-questions">
        <p className="learned-questions__title">
          Thuật ngữ đã học trong vòng này
        </p>
        <Flex gap="large" vertical>
          {listFlashcard.map((item: Question.Value) => {
            return (
              <Row
                gutter={[8, 12]}
                className="learned-questions__row"
                key={item?._id}
              >
                <Col xs={24} md={9} className="learned-questions__question">
                  <TextArea
                    className="transparent"
                    value={getQuestion(item)}
                    onChange={(e) => {}}
                    autoSize
                    disabled
                  />
                </Col>
                <Col xs={24} md={15} className="learned-questions__answer">
                  <TextArea
                    className="transparent"
                    value={getAnswer(item)}
                    onChange={(e) => {}}
                    autoSize
                    disabled
                  />
                </Col>
              </Row>
            );
          })}
        </Flex>
      </div>
    </div>
  );
};

export default memo(FinishLearn);

import { Button, Checkbox, Flex, Form, Modal, Spin } from "antd";
import { useClass } from "hook/useClass";
import { useQuiz } from "hook/useQuiz";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./style.scss";

interface Props {
  quizOfClass: string[];
  isOpen: boolean;
  handleCancel: () => void;
  reloadQuizData: () => void;
}

const AddQuizModal = (props: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const quiz = useQuiz();
  const classroom = useClass();
  const [form] = Form.useForm<Class.UpdateClass>();
  const { isOpen, handleCancel, reloadQuizData, quizOfClass } = props;
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [listQuizzes, setListQuizzes] = useState<Quiz.FullQuizInfo[]>([]);

  useEffect(() => {
    if (isOpen) {
      getQuizzes();
      form.setFieldsValue({
        quizzes: quizOfClass,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getQuizzes = () => {
    setLoading(true);

    quiz.getMyCreatedQuiz({
      callback: {
        onSuccess: (res: Quiz.FullQuizInfo[]) => {
          setListQuizzes(res);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const updateFolder = () => {
    if (!id) return;
    setSubmitting(true);

    classroom.updateClass({
      id,
      params: form.getFieldsValue(),
      callback: {
        onSuccess: () => {
          reloadQuizData();
          handleCancel();
        },
        onFinish: () => {
          setSubmitting(false);
        },
      },
    });
  };

  const onFinish = (values: any) => {
    updateFolder();
  };

  return (
    <Modal
      className="add-quiz-model"
      title={<h2>Thêm học phần</h2>}
      open={isOpen}
      onCancel={() => handleCancel()}
      footer={
        <Flex justify="flex-end">
          <Button
            style={{ height: "46px" }}
            className="btn-submit"
            type="primary"
            size="large"
            loading={submitting}
            onClick={() => form.submit()}
          >
            Thêm học phần
          </Button>
        </Flex>
      }
      width={800}
      style={{ top: 20 }}
    >
      <Flex vertical gap="large">
        <Button
          type="primary"
          size="large"
          onClick={() => navigate("/new-quiz-set")}
          style={{ width: "fit-content", marginLeft: "8px" }}
        >
          + Tạo học phần mới
        </Button>
        <Spin spinning={loading} style={{ minHeight: 400 }}>
          <div className="form-content">
            <Form
              className="base-form"
              form={form}
              layout="vertical"
              scrollToFirstError
              autoComplete="off"
              onFinish={onFinish}
            >
              <Form.Item<Class.UpdateClass>
                name="quizzes"
                rules={[{ required: true, message: "Vui lòng chọn học phần!" }]}
              >
                <Checkbox.Group>
                  <Flex vertical gap="small">
                    {listQuizzes.map((item) => {
                      return (
                        <Checkbox
                          value={item._id}
                          key={item._id}
                          style={{ lineHeight: "32px" }}
                        >
                          <div className="quiz-bar">{item.title}</div>
                        </Checkbox>
                      );
                    })}
                  </Flex>
                </Checkbox.Group>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Flex>
    </Modal>
  );
};

export default AddQuizModal;

import { useAuth } from "hook/useAuth";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router";

function GuestRoute(props: any) {
  const auth = useAuth();
  const logged = auth.isLoggedIn();

  return !logged ? <Outlet /> : <Navigate to="/dashboard" />;
}

export default GuestRoute;

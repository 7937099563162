import { Button, Flex, Form, Input, Modal } from "antd";
import { memo } from "react";

interface Props {
  isOpen: boolean;
  handleSave: () => void;
  handleCancel: () => void;
}

const UpdatePasswordModal = (props: Props) => {
  const { isOpen, handleCancel, handleSave } = props;
  // const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<User.UpdatePasswordParams>();

  const updatePasswordInfo = () => {
    handleSave();
    form.resetFields();
  };

  const onCancel = () => {
    handleCancel();
    form.resetFields();
  };

  return (
    <>
      <Modal
        title="Đổi mật khẩu"
        open={isOpen}
        style={{ top: 20 }}
        onCancel={onCancel}
        footer={
          <Flex align="center" gap="middle">
            <Button
              style={{ marginLeft: "auto" }}
              className="btn-outline"
              type="text"
              size="large"
              // disabled={loading}
              onClick={() => onCancel()}
            >
              Hủy
            </Button>
            <Button
              style={{ height: "46px" }}
              className="btn-submit"
              type="primary"
              size="large"
              // loading={loading}
              onClick={() => form.submit()}
            >
              Lưu
            </Button>
          </Flex>
        }
      >
        <Form
          className="base-form"
          form={form}
          layout="vertical"
          scrollToFirstError
          autoComplete="off"
          onFinish={() => updatePasswordInfo()}
        >
          <Form.Item<User.UpdatePasswordParams>
            label="Mật khẩu hiện tại"
            name="oldPassword"
            hasFeedback
            rules={[
              { required: true, message: "Vui lòng nhập mật khẩu hiện tại!" },
            ]}
          >
            <Input.Password
              allowClear
              placeholder="Nhập mật khẩu hiện tại của bạn"
            />
          </Form.Item>
          <Form.Item<User.UpdatePasswordParams>
            label="Mật khẩu mới"
            name="newPassword"
            hasFeedback
            rules={[{ required: true, message: "Vui lòng nhập mật khẩu mới!" }]}
          >
            <Input.Password
              allowClear
              placeholder="Nhập mật khẩu mới của bạn"
            />
          </Form.Item>

          <Form.Item
            label="Xác nhận mật khẩu mới"
            name="confirmPassword"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Vui lòng nhập xác nhận mật khẩu mới!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Xác nhận mật khẩu mới không khớp!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              allowClear
              placeholder="Nhập lại mật khẩu mới của bạn"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default memo(UpdatePasswordModal);

import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "assets/scss/index.scss";
import NotificationProvider from "provider/NotificationProvider";
import QuizInfoPageProvider from "provider/QuizInfoPageProvider";
import UserInfoProvider from "provider/UserInfoProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <NotificationProvider>
    <UserInfoProvider>
      <QuizInfoPageProvider>
        <App />
      </QuizInfoPageProvider>
    </UserInfoProvider>
  </NotificationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Button, Col, Flex, Form, FormProps, Image, Input, Row } from "antd";
import { useAuth } from "hook/useAuth";
import { NotificationContext } from "provider/NotificationProvider";
import { UserInfoContext } from "provider/UserInfoProvider";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";

const initialFormValues: Auth.LoginParams = { email: "", password: "" };

const Login = () => {
  const { openNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { setUserInfo } = useContext(UserInfoContext);

  const onFinish: FormProps<Auth.LoginParams>["onFinish"] = (values) => {
    login(values);
  };

  const login = async (values: Auth.LoginParams): Promise<void> => {
    setLoading(true);
    auth.login({
      params: values,
      callback: {
        onSuccess: (res: Auth.LoginResponseParams) => {
          setUserInfo(res);
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <div>
      <Row className="login-page">
        <Col xs={0} md={10} lg={12} className="login-page__bg">
          <h1>Học hiệu quả mà thật thoải mái.</h1>
          <Link to="/">
            <Image src="/images/piki-logo-word.svg" alt="" preview={false} />
          </Link>
        </Col>
        <Col xs={24} md={14} lg={12} className="login-page__content">
          <div>
            <h2 className="title">Đăng nhập</h2>
            <Form
              className="base-form"
              form={form}
              layout="vertical"
              initialValues={initialFormValues}
              onFinish={onFinish}
              scrollToFirstError
              autoComplete="off"
            >
              <Form.Item<Auth.LoginParams>
                label="Email"
                name="email"
                hasFeedback
                rules={[
                  { required: true, message: "Vui lòng nhập địa chỉ email!" },
                  { type: "email", message: "Email không đúng định dạng!" },
                ]}
              >
                <Input allowClear placeholder="Nhập địa chỉ email của bạn" />
              </Form.Item>

              <Form.Item<Auth.LoginParams>
                label="Mật khẩu"
                name="password"
                hasFeedback
                rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
              >
                <Input.Password
                  allowClear
                  placeholder="Nhập mật khẩu của bạn"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  className="btn-submit btn-block"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                >
                  Đăng nhập
                </Button>
              </Form.Item>
            </Form>
            <Flex vertical gap="large">
              <Button
                className="btn-block btn-outline"
                type="text"
                disabled={loading}
                onClick={() => navigate("/register")}
              >
                Mới sử dụng Piki? Tạo tài khoản
              </Button>
              <Button
                className="btn-block btn-outline"
                type="text"
                disabled={loading}
                onClick={() => navigate("/")}
              >
                Quay lại trang chủ
              </Button>
            </Flex>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;

import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import RouterPage from "router";

function App() {
  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#4255ff",
            colorText: "#282e3e",
            fontFamily: "Lexend Deca, sans-serif",
          },
          components: {
            Button: {
              fontWeight: 600,
              contentFontSizeLG: 14,
            },
          },
        }}
      >
        <RouterPage />
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;

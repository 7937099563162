import { Card, Col, Flex, Row } from "antd";
import { useNavigate } from "react-router-dom";

const { Meta } = Card;

interface FeatureCardProps {
  value: string;
  image: string;
  title: string;
  description: string;
  path?: string;
}
const feature: FeatureCardProps[] = [
  {
    value: "find",
    image: "/images/find.png",
    title: "Tìm kiếm mạnh mẽ hơn",
    description: "Tìm thẻ ghi nhớ bạn cần để học về bất kỳ chủ đề nào",
    path: "/search",
  },
  {
    value: "learn",
    image: "/images/learn.png",
    title: "Cải thiện điểm số với chế độ Học",
    description: "Học với các câu hỏi hay hơn, kiểm soát nhiều hơn, và gợi ý",
  },
  {
    value: "test",
    image: "/images/test.svg",
    title: "Hãy sẵn sàng cho kỳ thi",
    description: "Kiểm tra với gợi ý và chỉ dẫn học được tăng cường AI",
  },
];

const FeatureCard = (props: FeatureCardProps) => {
  const { value, image, title, description, path } = props;
  const navigate = useNavigate();

  return (
    <Card
      className={`feature-card ${value}`}
      cover={<img alt="feature" src={image} />}
      onClick={() => {
        if (path) {
          navigate(path);
        }
      }}
    >
      <Meta title={title} description={description} />
    </Card>
  );
};

const Features = () => {
  return (
    <Flex vertical gap="large">
      <h2>Hãy thử các tính năng cập nhật này</h2>
      <Row gutter={[24, 24]}>
        {feature.map((item) => {
          return (
            <Col xs={24} sm={12} md={8} lg={8} key={item.value}>
              <FeatureCard {...item} />
            </Col>
          );
        })}
      </Row>
    </Flex>
  );
};

export default Features;

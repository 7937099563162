import {
  EllipsisOutlined,
  FolderAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Flex, MenuProps } from "antd";
import { useClass } from "hook/useClass";
import { useFolder } from "hook/useFolder";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddToClass from "../AddToClass";
import AddToFolder from "../AddToFolder";

const MoreDropdown = () => {
  const { id } = useParams();
  const folder = useFolder();
  const classroom = useClass();

  const [openAddToFolderModal, setOpenAddToFolderModal] = useState(false);
  const [openAddToClassModal, setOpenAddToClassModal] = useState(false);
  const [foldersHaveQuiz, setFoldersHaveQuiz] = useState<string[]>([]);
  const [classHaveQuiz, setClassHaveQuiz] = useState<string[]>([]);

  useEffect(() => {
    getFoldersHaveQuiz();
    getClassHaveQuiz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const items: MenuProps["items"] = [
    {
      label: (
        <span
          className="add-dropdown-item"
          onClick={() => setOpenAddToFolderModal(true)}
        >
          <Flex align="center" gap={16}>
            <FolderAddOutlined />
            <span>Thêm vào thư mục</span>
          </Flex>
        </span>
      ),
      key: "0",
    },
    {
      label: (
        <span
          className="add-dropdown-item"
          onClick={() => setOpenAddToClassModal(true)}
        >
          <Flex align="center" gap={16}>
            <UsergroupAddOutlined />
            <span>Thêm vào lớp</span>
          </Flex>
        </span>
      ),
      key: "1",
    },
    //   {
    //     label: (
    //       <span className="add-dropdown-item">
    //         <Flex align="center" gap={16}>
    //           <ShrinkOutlined />
    //           <span>Ghép học phần</span>
    //         </Flex>
    //       </span>
    //     ),
    //     key: "2",
    //   },
  ];

  const getFoldersHaveQuiz = () => {
    if (!id) return;

    folder.getFolderListByQuizId({
      id: id,
      callback: {
        onSuccess: (res: Folder.FullInfo[]) => {
          setFoldersHaveQuiz(res.map((item) => item._id));
        },
      },
    });
  };

  const getClassHaveQuiz = () => {
    if (!id) return;

    classroom.getClassListByQuizId({
      id: id,
      callback: {
        onSuccess: (res: Class.FullInfo[]) => {
          setClassHaveQuiz(res.map((item) => item._id));
        },
      },
    });
  };

  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
        <Button
          size="large"
          icon={<EllipsisOutlined />}
          className="btn-border-gray"
        />
      </Dropdown>
      <AddToFolder
        isOpen={openAddToFolderModal}
        handleCancel={() => setOpenAddToFolderModal(false)}
        foldersHaveQuiz={foldersHaveQuiz}
        reloadQuizData={getFoldersHaveQuiz}
      />

      <AddToClass
        isOpen={openAddToClassModal}
        handleCancel={() => setOpenAddToClassModal(false)}
        classHaveQuiz={classHaveQuiz}
        reloadQuizData={getClassHaveQuiz}
      />
    </>
  );
};

export default MoreDropdown;

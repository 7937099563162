import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Empty,
  Flex,
  Input,
  Pagination,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import ClassCard from "components/ClassCard";
import QuizCard from "components/QuizCard";
import { LevelEnum } from "constant/quiz";
import { useClass } from "hook/useClass";
// import { useFolder } from "hook/useFolder";
import { useQuiz } from "hook/useQuiz";
import { useSubject } from "hook/useSubject";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";
import ReviewQuizModal from "./ReviewQuizModal";
import "./style.scss";

const { Option } = Select;

const allSubject = { value: "", label: "Tất cả chủ đề" };
const allLevel = { value: "", label: "Tất cả cấp độ" };

enum Tab {
  // All = "1",
  Quiz = "2",
  Folder = "3",
  Class = "4",
  // User = "5",
}

const Search = () => {
  const subject = useSubject();
  const quiz = useQuiz();
  // const folder = useFolder();
  const classroom = useClass();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [selectedSubject, setSelectedSubject] = useState<string>("");
  const [selectedLevel, setSelectedLevel] = useState<LevelEnum | "">("");
  const [selectedQuiz, setSelectedQuiz] = useState<Quiz.FullQuizInfo>();
  const [openReviewModal, setOpenReviewModal] = useState(false);

  const [loadingSubject, setLoadingSubject] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(Tab.Quiz);
  const [subjectList, setSubjectList] = useState<SelectProps["options"]>([]);

  const [quizResult, setQuizResult] =
    useState<Quiz.GetMyCreatedQuizPaginationResponse>();
  // const [folderResult, setFolderResult] =
  //   useState<Folder.MyFoldersPaginationResponse>();
  const [classResult, setClassResult] =
    useState<Class.ClassesPaginationResponse>();

  const getQuizTab = () => {
    return (
      <Flex vertical gap="large">
        <div>
          <Typography.Title level={4}>Bộ lọc</Typography.Title>
          <Flex gap="large" wrap>
            <div>
              <p>Chủ đề</p>
              <Select
                loading={loadingSubject}
                className="select-subject"
                options={subjectList}
                value={selectedSubject}
                style={{ width: "200px" }}
                onChange={(value: string) => setSelectedSubject(value)}
              />
            </div>

            <div>
              <p>Cấp độ</p>
              <Select
                loading={loadingSubject}
                className="select-subject"
                value={selectedLevel}
                style={{ width: "200px" }}
                onChange={(value: LevelEnum | "") => setSelectedLevel(value)}
              >
                <Option value={LevelEnum.Beginner}>Dễ</Option>
                <Option value={LevelEnum.Intermediate}>Trung bình</Option>
                <Option value={LevelEnum.Advanced}>Khó</Option>
                <Option value={allLevel.value}>Tất cả cấp độ</Option>
              </Select>
            </div>

            {/* <div>
              <Typography.Title level={5}>Chủ đề</Typography.Title>
              <Select
              loading={loadingSubject}
              className="select-subject"
              options={subjectList}
              value={selectedSubject}
              style={{ width: "200px" }}
              onChange={(value: string) => setSelectedSubject(value)}
            />
            </div> */}
          </Flex>
        </div>
        <Spin spinning={loading}>
          {quizResult && (
            <div>
              <Typography.Title level={4}>Học phần</Typography.Title>
              {quizResult?.quizzes.length > 0 ? (
                <>
                  <Row style={{ paddingTop: "1rem" }} gutter={[24, 24]}>
                    {quizResult.quizzes.map((item) => {
                      return (
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          lg={8}
                          key={item._id}
                          onClick={() => navigate(`/quizzes/${item._id}`)}
                        >
                          <QuizCard quizInfo={item} key={item._id}>
                            <Button
                              size="large"
                              className="btn-border-gray"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedQuiz(item);
                                setOpenReviewModal(true);
                              }}
                              style={{ marginLeft: "auto" }}
                            >
                              Xem trước
                            </Button>
                          </QuizCard>
                        </Col>
                      );
                    })}
                  </Row>
                  <Flex justify="end" className="pagination">
                    <Pagination
                      total={quizResult?.count || 0}
                      showQuickJumper
                    />
                  </Flex>
                  <ReviewQuizModal
                    isOpen={openReviewModal}
                    handleCancel={() => setOpenReviewModal(false)}
                    quizInfo={selectedQuiz}
                  />
                </>
              ) : (
                <Empty description="Không có kết quả phù hợp" />
              )}
            </div>
          )}
        </Spin>
      </Flex>
    );
  };

  const getClassTab = () => {
    return (
      <Flex vertical gap="large">
        <Spin spinning={loading}>
          {classResult && (
            <div>
              <Typography.Title level={4}>Lớp học</Typography.Title>
              {getUserInfo()?._id === undefined && (
                <Typography.Title level={5}>
                  Đăng nhập và tham gia lớp học để xem chi tiết lớp học
                </Typography.Title>
              )}

              {classResult?.classrooms?.length > 0 ? (
                <>
                  <Row style={{ paddingTop: "1rem" }} gutter={[24, 24]}>
                    {classResult?.classrooms.map((item) => {
                      return (
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          lg={8}
                          key={item._id}
                          onClick={() => {
                            getUserInfo()?._id &&
                              navigate(`/classes/${item._id}`);
                          }}
                        >
                          <ClassCard
                            classInfo={item}
                            key={item._id}
                            showCreator
                          />
                        </Col>
                      );
                    })}
                  </Row>
                  <Flex justify="end" className="pagination">
                    <Pagination
                      total={classResult?.count || 0}
                      showQuickJumper
                    />
                  </Flex>
                </>
              ) : (
                <Empty description="Không có kết quả phù hợp" />
              )}
            </div>
          )}
        </Spin>
      </Flex>
    );
  };

  const tabItems: TabsProps["items"] = [
    // { key: Tab.All, label: "Tất cả kết quả", children: "Content of Tab Pane 1" },
    { key: Tab.Quiz, label: "Học phần", children: getQuizTab() },
    // { key: Tab.Folder, label: "Thư mục", children: "Content of Tab Pane 3" },
    { key: Tab.Class, label: "Lớp học", children: getClassTab() },
    // { key: Tab.User, label: "Người dùng", children: "Content of Tab Pane 3" },
  ];

  useEffect(() => {
    getSubjectList();
    getClassResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getQuizResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject, selectedLevel]);

  const getSubjectList = async (): Promise<void> => {
    setLoadingSubject(true);

    subject.getSubjectList({
      callback: {
        onSuccess: (res: Subject.Value[]) => {
          const options = res.map((item) => {
            return {
              label: item.subject_name,
              value: item._id,
            };
          });
          options.push(allSubject);
          setSubjectList(options);
        },
        onFailure: (err: any) => {
          setSubjectList([]);
        },
        onFinish: () => {
          setLoadingSubject(false);
        },
      },
    });
  };

  const getQuizResult = async (): Promise<void> => {
    setLoading(true);
    console.log(searchValue);

    quiz.getAllQuiz({
      params: {
        page: 1,
        limit: 10,
        title: searchValue,
        subject: selectedSubject,
        level: selectedLevel,
      },
      callback: {
        onSuccess: (res: Quiz.GetMyCreatedQuizPaginationResponse) => {
          setQuizResult(res);
        },
        onFailure: (err: any) => {
          ///
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  // const getFolderResult = async (): Promise<void> => {
  //   setLoading(true);

  //   folder.getAllFolder({
  //     params: {
  //       page: 1,
  //       limit: 10,
  //     },
  //     callback: {
  //       onSuccess: (res: Folder.MyFoldersPaginationResponse) => {
  //         setFolderResult(res);
  //       },
  //       onFailure: (err: any) => {
  //         ///
  //       },
  //       onFinish: () => {
  //         setLoading(false);
  //       },
  //     },
  //   });
  // };

  const getClassResult = async (): Promise<void> => {
    setLoading(true);

    classroom.getAllClass({
      params: {
        page: 1,
        limit: 10,
        classroom_name: searchValue,
      },
      callback: {
        onSuccess: (res: Class.ClassesPaginationResponse) => {
          setClassResult(res);
        },
        onFailure: (err: any) => {
          ///
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const onSearch = () => {
    getQuizResult();
    // getFolderResult();
    getClassResult();
  };

  return (
    <Flex vertical gap={24} className="search">
      <div className="title">
        <h1>Tìm trên Piki</h1>
        <p>Tìm học phần, lớp học, người dùng, v.v.</p>
      </div>

      <Flex gap="large" wrap align="center">
        <Space.Compact className="search-bar" style={{ width: "100%" }}>
          <Input
            prefix={<SearchOutlined />}
            className="search-input"
            placeholder="Tìm kiếm ..."
            value={searchValue}
            onChange={(e: any) => setSearchValue(e.target.value)}
          />
          <Button type="primary" className="btn-find" onClick={onSearch}>
            Tìm kiếm
          </Button>
        </Space.Compact>
      </Flex>

      <Tabs
        size="large"
        activeKey={activeTab}
        items={tabItems}
        onChange={(key: string) => setActiveTab(key)}
      />
    </Flex>
  );
};

export default Search;

import { useHttpRequest } from "./useHttpRequest";

export const useClassAPI = () => {
  const httpRequest = useHttpRequest();

  const getClassById = async (id: string): Promise<Class.FullInfo[]> =>
    httpRequest.GET(`/classrooms/${id}`);

  const getAllClass = async (
    params: App.Pagination
  ): Promise<Class.ClassesPaginationResponse | Class.FullInfo[]> =>
    httpRequest.GET("/classrooms", { params });

  const updateClass = async (
    id: string,
    params: Class.UpdateClass
  ): Promise<void> => httpRequest.PATCH(`/classrooms/${id}`, params);

  const joinClass = async (id: string): Promise<Class.JoinResponse> =>
    httpRequest.POST(`/classrooms/join/${id}`);

  const createClass = async (
    params: Class.CreateClass
  ): Promise<Class.FullInfo> => httpRequest.POST("/classrooms", params);

  const getMyClass = async (
    params?: App.Pagination
  ): Promise<Class.ClassesPaginationResponse | Class.FullInfo[]> =>
    httpRequest.GET("/classrooms/created", { params });

  const getMyStatusInClass = async (id: string): Promise<number | string> =>
    httpRequest.GET(`/classrooms/check-join/${id}`);

  const getClassListByQuizId = async (id: string): Promise<Class.FullInfo[]> =>
    httpRequest.GET(`/classrooms/by-quiz/${id}`);

  const addQuizToManyClasses = async (
    params: Class.AddQuizToManyClasses
  ): Promise<any> => httpRequest.POST("/classrooms/add-quiz", params);

  const getClassInfoByAccountId = async (
    id: string,
    params?: App.Pagination
  ): Promise<Class.ClassesPaginationResponse> =>
    httpRequest.GET(`/classrooms/by-account/${id}`, { params });

  const deleteClassById = async (id: string): Promise<Class.FullInfo[]> =>
    httpRequest.DELETE(`/classrooms/${id}`);

  return {
    getAllClass,
    getClassById,
    updateClass,
    joinClass,
    createClass,
    getMyClass,
    getMyStatusInClass,
    getClassListByQuizId,
    addQuizToManyClasses,
    getClassInfoByAccountId,
    deleteClassById,
  };
};

import React, { createContext, useState } from "react";

interface Props {
  listQuestion: Question.Value[];
  setListQuestion: (value: Question.Value[]) => void;
  toggleStarQuestion: (questionId: string, starValue: boolean) => void;
}

export const QuizInfoPageContext = createContext<Props>({
  listQuestion: [],
  setListQuestion: () => {},
  toggleStarQuestion: () => {},
});

const QuizInfoPageProvider = ({ children }: { children: React.ReactNode }) => {
  const [listQuestion, setListQuestion] = useState<Question.Value[]>([]);

  const toggleStarQuestion = (questionId: string, starValue: boolean) => {
    const index = listQuestion.findIndex((item) => item?._id === questionId);

    if (index !== -1) {
      const newList = [...listQuestion];
      newList[index].is_star = starValue;
      setListQuestion(newList);
    }
  };

  const setNewListQuestion = (value: Question.Value[]) => {
    setListQuestion(value);
  };

  return (
    <QuizInfoPageContext.Provider
      value={{
        listQuestion: listQuestion,
        setListQuestion: setNewListQuestion,
        toggleStarQuestion,
      }}
    >
      {children}
    </QuizInfoPageContext.Provider>
  );
};

export default QuizInfoPageProvider;

import { useHttpRequest } from "./useHttpRequest";

export const useAuthAPI = () => {
  const httpRequest = useHttpRequest();

  const login = async (
    params: Auth.LoginParams
  ): Promise<Auth.LoginResponseParams> =>
    httpRequest.POST("/auth/login", params);

  const register = async (
    params: Auth.RegisterParams
  ): Promise<Auth.LoginResponseParams> =>
    httpRequest.POST("/auth/register", params);

  return { login, register };
};

import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Radio, Row, Spin } from "antd";
import SelectMode from "components/SelectMode";
import { DisplayQuizTypeEnum, alphabet } from "constant/quiz";
import { useQuestion } from "hook/useQuestion";
import { useQuiz } from "hook/useQuiz";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";
import Submitting from "./Submitting";
import TestResult from "./TestResult";
import "./style.scss";

const getTestResult = (
  correctAnswers: Question.Value[],
  userAnswers: Question.NoAnswerValue[],
  quizName: string
): Test.Result => {
  let rightNumber: number = 0;
  let wrongNumber: number = 0;
  const details: Test.ResultDetails[] = [];

  const userAnswersMap = new Map<string, Question.NoAnswerValue>();
  userAnswers.forEach((userAnswer) => {
    if (userAnswer._id) {
      userAnswersMap.set(userAnswer._id, userAnswer);
    }
  });

  correctAnswers.forEach((question) => {
    if (!question._id) {
      return;
    }

    const userAnswer = userAnswersMap.get(question._id);
    let isCorrect = false;

    if (userAnswer && userAnswer.userAnswerId) {
      const correctAnswer = question.answers.find(
        (answer) => answer._id === userAnswer.userAnswerId
      );
      isCorrect = !!correctAnswer?.is_correct;
    }

    if (isCorrect) {
      rightNumber++;
    } else {
      wrongNumber++;
    }

    details.push({
      question: question,
      userAnswerId: userAnswer?.userAnswerId,
      isCorrect: isCorrect,
    });
  });

  return {
    rightNumber,
    wrongNumber,
    numberOfQuestion: rightNumber + wrongNumber,
    score: Number(
      ((rightNumber / (rightNumber + wrongNumber)) * 10).toFixed(2)
    ),
    details,
    quizName,
    time: 20,
  } as Test.Result;
};

const getAnsweredQuestion = (value: any) => {
  return (
    value?.value?.filter((item: Question.NoAnswerValue) => item?.userAnswerId)
      ?.length || 0
  );
};

const TestMode = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const quiz = useQuiz();
  const question = useQuestion();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [quizInfo, setQuizInfo] = useState<Quiz.FullQuizInfo>();
  const [listQuestion, setListQuestion] = useState<Question.NoAnswerValue[]>(
    []
  );

  const [testResult, setTestResult] = useState<Test.Result>();
  const { openNotification } = useContext(NotificationContext);

  useEffect(() => {
    getQuizInfo();
    getListQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    form.setFieldsValue({
      value: [...listQuestion],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQuestion]);

  const getQuizInfo = () => {
    if (!id) return;

    quiz.getQuizInfoById({
      id: id,
      callback: {
        onSuccess: (res: Quiz.FullQuizInfo) => {
          setQuizInfo(res);
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
      },
    });
  };

  const getListQuestion = () => {
    if (!id) return;

    setLoading(true);

    question.getQuestionsNoAnswerOfQuiz({
      id: id,
      callback: {
        onSuccess: (res: Question.NoAnswerValue[]) => {
          setListQuestion(res);
        },
        onFailure: (err: any) => {
          setListQuestion([]);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getListAnswerQuestion = (formValue: Question.NoAnswerValue[]) => {
    if (!id) return;

    setSubmitting(true);

    question.getQuestionsAnswerOfQuiz({
      id: id,
      callback: {
        onSuccess: (res: Question.Value[]) => {
          const resultDetails: Test.Result = getTestResult(
            res,
            formValue,
            quizInfo?.title || ""
          );
          setTestResult(resultDetails);

          if (getUserInfo()?._id) {
            finishQuiz(resultDetails);
          }
        },
        onFailure: (err: any) => {
          setListQuestion([]);
          setSubmitting(false);
        },
        onFinish: () => {
          if (submitted) setSubmitting(false);
        },
      },
    });
  };

  const finishQuiz = (resultDetails: Test.Result) => {
    if (!id) return;
    quiz.finishQuiz({
      params: {
        quiz: id,
        quiz_type: DisplayQuizTypeEnum.Test,
        attempt: {
          score: resultDetails.score,
          time: resultDetails?.time,
        },
      },
      callback: {},
    });
  };

  const onFinish = (values: any) => {
    if (!id) return;

    getListAnswerQuestion(values?.value);

    setTimeout(() => {
      setSubmitting(false);
      setSubmitted(true);
    }, 2000);
  };

  const numberOfAnsweredQuestion = Form.useWatch(
    (values) => getAnsweredQuestion(values),
    form
  );

  return (
    <div className="no-layout-mode no-layout scroll test-mode">
      <Flex vertical className="header" gap="small">
        <Flex justify="space-between" align="center">
          <SelectMode mode={DisplayQuizTypeEnum.Test} />
          <Flex vertical className="title">
            <div>
              {!submitting &&
                numberOfAnsweredQuestion !== undefined &&
                !submitted &&
                `${numberOfAnsweredQuestion} / ${listQuestion.length}`}
            </div>
            <div>{quizInfo?.title}</div>
          </Flex>
          <Flex gap={16}>
            {/* <Button
              size="large"
              className="btn-border-gray"
              icon={<SettingOutlined />}
            /> */}
            <Button
              size="large"
              className="btn-border-gray"
              icon={<CloseOutlined />}
              onClick={() => {
                navigate(`/quizzes/${id}`);
              }}
            />
          </Flex>
        </Flex>
        <Flex vertical className="mobile-title">
          <div>
            {!submitting &&
              numberOfAnsweredQuestion !== undefined &&
              !submitted &&
              `${numberOfAnsweredQuestion} / ${listQuestion.length}`}
          </div>
          <div>{quizInfo?.title}</div>
        </Flex>
      </Flex>
      <div className="mode-content">
        {submitting ? (
          <Submitting />
        ) : !submitted ? (
          <Spin spinning={loading}>
            {listQuestion.length ? (
              <Form
                form={form}
                layout="vertical"
                scrollToFirstError
                autoComplete="off"
                onFinish={onFinish}
              >
                <Form.List name="value">
                  {(fields) => (
                    <Flex vertical gap={16}>
                      {fields.map((field, index) => (
                        <div className="test-card" key={field.key}>
                          <span>{`${field.name + 1} / ${
                            listQuestion.length
                          }`}</span>
                          <p className="question">
                            {form.getFieldValue("value")[index]?.text}
                          </p>
                          <div>
                            <p className="title">Chọn câu trả lời đúng</p>
                            <div className="answers-container">
                              <Form.Item
                                name={[field.name, "userAnswerId"]}
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: "Vui lòng chọn đáp án!",
                                  },
                                ]}
                              >
                                <Radio.Group
                                  style={{ width: "100%", marginBottom: "8px" }}
                                >
                                  <Row gutter={[12, 12]}>
                                    {form
                                      .getFieldValue("value")
                                      [index].answers.map(
                                        (
                                          item: Question.NoCorrectAnswer,
                                          subIndex: number
                                        ) => {
                                          return (
                                            <Col xs={24} md={12} key={item._id}>
                                              <Radio value={item._id}>
                                                <Flex
                                                  className="answer"
                                                  align="center"
                                                  gap="large"
                                                >
                                                  <span className="count">
                                                    {alphabet[subIndex]}
                                                  </span>
                                                  <span className="content">
                                                    {item?.text}
                                                  </span>
                                                </Flex>
                                              </Radio>
                                            </Col>
                                          );
                                        }
                                      )}
                                  </Row>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Flex>
                  )}
                </Form.List>
                <Form.Item style={{ marginTop: "24px" }}>
                  <Button block type="primary" size="large" htmlType="submit">
                    Nộp bài kiểm tra
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <></>
            )}
          </Spin>
        ) : (
          <TestResult testResult={testResult} />
        )}
      </div>
    </div>
  );
};

export default TestMode;

import { Empty, Table, TableProps, Tag } from "antd";
import { RequestEnum } from "constant/class";
import { useRequest } from "hook/useRequest";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const getRequest = (status: number) => {
  switch (status) {
    case RequestEnum.Accepted:
      return (
        <Tag color="green" style={{ padding: "4px 8px", fontSize: "14px" }}>
          Đã tham gia
        </Tag>
      );
    case RequestEnum.Pending:
      return (
        <Tag color="blue" style={{ padding: "4px 8px", fontSize: "14px" }}>
          Chờ xác nhận
        </Tag>
      );
    case RequestEnum.Rejected:
      return (
        <Tag color="volcano" style={{ padding: "4px 8px", fontSize: "14px" }}>
          Từ chối
        </Tag>
      );
    default:
      return "";
  }
};

const SentRequest = () => {
  const request = useRequest();
  const [loading, setLoading] = useState(false);
  const [requestList, setRequestList] = useState<Request.FullDetails[]>([]);

  useEffect(() => {
    getRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRequestList = async (): Promise<void> => {
    setLoading(true);

    request.getAllSentRequest({
      callback: {
        onSuccess: (res: Request.FullDetails[]) => {
          setRequestList(res);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };
  const columns: TableProps<Request.FullDetails>["columns"] = [
    {
      title: "STT",
      dataIndex: "classroom",
      key: "index",
      align: "center",
      render: (_: any, record: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Lớp học",
      dataIndex: "classroom",
      key: "classroom",
      render: (_: any, record: Request.FullDetails) => (
        <Link to={`/classes/${record?.classroom?._id}`}>
          {record?.classroom?.classroom_name}
        </Link>
      ),
      sorter: (a: Request.FullDetails, b: Request.FullDetails) =>
        a?.classroom && b?.classroom
          ? a?.classroom?.classroom_name?.length -
            b?.classroom?.classroom_name?.length
          : 1,
    },
    {
      title: "Quản trị viên",
      dataIndex: "receiver",
      key: "receiver",
      render: (_: any, record: Request.FullDetails) => (
        <Link to={`/users/${record?.receiver?._id}`}>
          {record?.receiver?.name}
        </Link>
      ),
      sorter: (a: Request.FullDetails, b: Request.FullDetails) =>
        a?.receiver?.name?.length - b?.receiver?.name?.length,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (status: number) => <>{getRequest(status)}</>,
      sorter: (a: Request.FullDetails, b: Request.FullDetails) =>
        a?.status - b?.status,
    },
    {
      title: "Ngày yêu cầu tham gia",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => (
        <>{moment(createdAt).format("DD/MM/YYYY HH:mm")}</>
      ),
      sorter: (a: Request.FullDetails, b: Request.FullDetails) => {
        return moment(a?.createdAt).isAfter(b?.createdAt) ? 1 : 0;
      },
    },
    {
      title: "Ngày phản hồi yêu cầu",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt: string) => (
        <>{moment(updatedAt).format("DD/MM/YYYY HH:mm")}</>
      ),
      sorter: (a: Request.FullDetails, b: Request.FullDetails) => {
        return moment(a?.updatedAt).isAfter(b?.updatedAt) ? 1 : 0;
      },
    },
  ];

  return (
    <Table
      dataSource={requestList}
      columns={columns}
      loading={loading}
      rowKey={(record) => record._id}
      showSorterTooltip={false}
      locale={{ emptyText: <Empty description="Dữ liệu rỗng" /> }}
    />
  );
};

export default SentRequest;

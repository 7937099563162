import { useNavigate } from "react-router-dom";
import { get, noop } from "lodash";
import {
  getAccessToken,
  revokeUser,
  setAccessToken,
  setUserInfo,
  //   setRefreshToken
} from "utils/localStorage";
import { useAuthAPI } from "api/useAuthAPI";

export const useAuth = () => {
  const navigate = useNavigate();
  const authAPI = useAuthAPI();

  const isLoggedIn = () => {
    return getAccessToken() ? true : false;
  };

  const logout = () => {
    revokeUser();
    navigate("/");
  };

  const login = async ({
    params,
    callback,
  }: {
    params: Auth.LoginParams;
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await authAPI.login(params);
      loginSuccess(response);
      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  const register = async ({
    params,
    callback,
  }: {
    params: Auth.RegisterParams;
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await authAPI.register(params);
      loginSuccess(response);
      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  const loginSuccess = (res: Auth.LoginResponseParams) => {
    setAccessToken(res.accessToken);
    // setRefreshToken(res.refreshToken)
    setUserInfo(res);
    navigate("/");
  };

  return {
    logout,
    isLoggedIn,
    login,
    register,
  };
};

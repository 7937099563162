import { Button, Flex, Form, Input, Modal, Select } from "antd";
import { useAccount } from "hook/useAccount";
import { NotificationContext } from "provider/NotificationProvider";
import { memo, useContext, useEffect, useState } from "react";

const { Option } = Select;

interface Props {
  profileData: User.UpdateInfoParams;
  isOpen: boolean;
  handleSave: () => void;
  handleCancel: () => void;
}

const UpdateUserInfoModal = (props: Props) => {
  const account = useAccount();
  const { isOpen, profileData, handleCancel, handleSave } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<User.UpdateInfoParams>();
  const { openNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue(profileData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const updateUserInfo = () => {
    setLoading(true);
    account.updateUserInfo({
      params: form.getFieldsValue(),
      callback: {
        onSuccess: () => {
          handleSave();
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <>
      <Modal
        title="Cập nhật thông tin cá nhân"
        open={isOpen}
        style={{ top: 20 }}
        onCancel={() => handleCancel()}
        footer={
          <Flex align="center" gap="middle">
            <Button
              style={{ marginLeft: "auto" }}
              className="btn-outline"
              type="text"
              size="large"
              disabled={loading}
              onClick={() => handleCancel()}
            >
              Hủy
            </Button>
            <Button
              style={{ height: "46px" }}
              className="btn-submit"
              type="primary"
              size="large"
              loading={loading}
              onClick={() => form.submit()}
            >
              Lưu
            </Button>
          </Flex>
        }
      >
        <Form
          className="base-form"
          form={form}
          layout="vertical"
          scrollToFirstError
          autoComplete="off"
          onFinish={() => updateUserInfo()}
        >
          <Form.Item<User.UpdateInfoParams>
            label="Email"
            name="email"
            hasFeedback
            rules={[
              { required: true, message: "Vui lòng nhập địa chỉ email!" },
              { type: "email", message: "Email không đúng định dạng!" },
            ]}
          >
            <Input allowClear placeholder="Nhập địa chỉ email của bạn" />
          </Form.Item>

          <Form.Item<User.UpdateInfoParams>
            label="Tên người dùng"
            name="name"
            hasFeedback
            rules={[
              { required: true, message: "Vui lòng nhập tên người dùng!" },
            ]}
          >
            <Input allowClear placeholder="Nhập tên của bạn" />
          </Form.Item>

          <Form.Item<User.UpdateInfoParams>
            name="gender"
            label="Giới tính"
            hasFeedback
            rules={[{ required: true, message: "Vui lòng chọn giới tính!" }]}
          >
            <Select placeholder="Chọn giới tính">
              <Option value={true}>Nữ</Option>
              <Option value={false}>Nam</Option>
            </Select>
          </Form.Item>

          <Form.Item<User.UpdateInfoParams>
            label="Số điện thoại"
            name="phone"
            hasFeedback
          >
            <Input allowClear placeholder="Nhập số điện thoại" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default memo(UpdateUserInfoModal);

import {
  CheckCircleFilled,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ShareAltOutlined,
  StarFilled,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Empty,
  Flex,
  Popconfirm,
  PopconfirmProps,
  Row,
  Spin,
  Table,
  TableProps,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import BaseImage from "components/BaseImage";
import ReceivedRequest from "components/ReceivedRequest";
import ShareModal from "components/ShareModal";
import { RequestEnum } from "constant/class";
import { useClass } from "hook/useClass";
import { useQuiz } from "hook/useQuiz";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";
import AddQuizModal from "./AddQuizModal";
import EditClassModal from "./EditClassModal";
import "./style.scss";
import { useAccount } from "hook/useAccount";

const Class = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState<string>(
    query?.get("tab") || "quizzes"
  );

  const quiz = useQuiz();
  const account = useAccount();
  const classroom = useClass();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingMember, setLoadingMember] = useState(false);
  const [classInfo, setClassInfo] = useState<Class.FullInfo>();
  const [listQuizzesOfClass, setListQuizzesOfClass] = useState<
    Quiz.FullQuizInfo[]
  >([]);
  const [quizOfClass, setQuizOfClass] = useState<string[]>([]);
  const [openAddQuizzesModal, setOpenAddQuizzesModal] = useState(false);
  const [openEditClassModal, setOpenEditClassModal] = useState(false);
  // const [openAddUserModal, setOpenAddUseModal] = useState(false);
  const [submitJoin, setSubmitJoin] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userStatusOfClass, setUserStatusOfClass] = useState<RequestEnum>();
  const { openNotification } = useContext(NotificationContext);
  const userInfo = getUserInfo();
  const [isOpenShare, setIsUpdateOpenShare] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [memberList, setMemberList] = useState<User.Info[]>([]);

  const columns: TableProps<User.Info>["columns"] = [
    {
      title: "STT",
      dataIndex: "_id",
      key: "index",
      align: "center",
      render: (_: any, record: any, index: number) => {
        return <>{index + 1}</>;
      },
      width: 150,
    },
    {
      title: "Thành viên",
      key: "user",
      render: (_: any, record: User.Info) => (
        <Link to={`/users/${record?._id}`}>{record?.name}</Link>
      ),
    },
  ];

  useEffect(() => {
    getClassInfo();
    getQuizList();
    getMyStatusInClass();
    getMemberList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const currentQuery = new URLSearchParams(location.search);
    if (activeTab) {
      currentQuery.set("tab", activeTab);
      navigate({ search: currentQuery.toString() }, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, location.search]);

  const getClassInfo = () => {
    if (!id) return;
    setLoading(true);

    classroom.getClassById({
      id,
      callback: {
        onSuccess: (res: Class.FullInfo) => {
          setClassInfo(res);
          if (userInfo && userInfo._id === res?.creator?._id) {
            setIsEdit(true);
          }
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getQuizList = () => {
    if (!id) return;
    setLoading(true);

    quiz.getQuizInfoByClassId({
      id,
      callback: {
        onSuccess: (res: Quiz.FullQuizInfo[]) => {
          setListQuizzesOfClass(res);
          setQuizOfClass(res.map((item) => item._id));
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getMemberList = () => {
    if (!id) return;
    if (!getUserInfo()?._id) return;
    setLoadingMember(true);

    account.getMemberOfClass({
      id,
      callback: {
        onSuccess: (res: any) => {
          setMemberList(res?.accounts);
        },
        onFinish: () => {
          setLoadingMember(false);
        },
      },
    });
  };

  const getMyStatusInClass = () => {
    if (!id) return;
    if (!userInfo) return;
    setLoading(true);

    classroom.getMyStatusInClass({
      id,
      callback: {
        onSuccess: (res: number | "") => {
          if (res) {
            setUserStatusOfClass(res);
          } else {
            setUserStatusOfClass(RequestEnum.NotJoin);
          }
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const joinClass = () => {
    if (!classInfo?._id) return;
    setSubmitJoin(true);

    classroom.joinClass({
      id: classInfo._id,
      callback: {
        onSuccess: (res: Class.JoinResponse) => {
          openNotification(
            "Đã gửi yêu cầu tham gia đến quản trị viên lớp học !",
            ""
          );
          getMyStatusInClass();
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setSubmitJoin(false);
        },
      },
    });
  };

  const getUserStatusOfClass = () => {
    switch (userStatusOfClass) {
      case RequestEnum.Accepted:
      case RequestEnum.Admin:
        return <></>;
      case RequestEnum.Pending:
        return (
          <span className="notification">
            Vui lòng đợi quản trị viên duyệt yêu cầu tham gia của bạn!
          </span>
        );
      case RequestEnum.Rejected:
      case RequestEnum.NotJoin:
        return (
          <Button
            type="primary"
            size="large"
            style={{ width: "fit-content" }}
            loading={submitJoin}
            onClick={joinClass}
          >
            Gửi yêu cầu tham gia lớp học
          </Button>
        );
      default:
        return <></>;
    }
  };

  const quizzesTab = () => {
    return (
      <Spin spinning={loading}>
        {listQuizzesOfClass.length ? (
          <Row style={{ paddingTop: "1rem" }} gutter={[24, 24]}>
            {listQuizzesOfClass.map((item) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  key={item._id}
                  onClick={() => navigate(`/quizzes/${item._id}`)}
                >
                  <Flex vertical gap="small" className="quiz-bar">
                    <span className="quiz-title">
                      {item.title}
                      {item?.verified && item?.is_public ? (
                        <Tooltip title={"Học phần public"}>
                          <CheckCircleFilled
                            style={{
                              color: "#52c41a",
                              paddingLeft: "8px",
                              fontSize: 14,
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </span>
                    <span className="quizzes">{`${item.question_count} thuật ngữ`}</span>
                    {item?.review_count ? (
                      <Flex align="small">
                        <StarFilled
                          style={{
                            color: "#ffcd1f",
                            paddingRight: 8,
                            fontSize: 14,
                          }}
                        />
                        <span>{`${item?.review_average} (${item?.review_count}) đánh giá`}</span>
                      </Flex>
                    ) : (
                      <></>
                    )}
                  </Flex>
                </Col>
              );
            })}
          </Row>
        ) : (
          <Flex vertical align="center" gap="large">
            <Flex vertical align="center">
              <h2>Lớp học này chưa có học phần nào</h2>
              {isEdit ? (
                <span>
                  Thêm một học phần hiện có hoặc tạo học phần mới để chia sẻ.
                </span>
              ) : (
                <></>
              )}
            </Flex>
            {isEdit ? (
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => setOpenAddQuizzesModal(true)}
              >
                Thêm học phần
              </Button>
            ) : (
              <></>
            )}
          </Flex>
        )}
      </Spin>
    );
  };

  const membersTab = () => {
    return (
      <Table
        dataSource={memberList}
        columns={columns}
        loading={loadingMember}
        rowKey={(record) => record._id}
        showSorterTooltip={false}
        locale={{ emptyText: <Empty description="Dữ liệu rỗng" /> }}
      />
    );
  };

  const confirm: PopconfirmProps["onConfirm"] = (e) => {
    if (!id) return;
    setDeleting(true);

    classroom.deleteClassById({
      id,
      callback: {
        onSuccess: () => {
          navigate("/my-library?tab=3");
        },
        onFinish: () => {
          setDeleting(false);
        },
      },
    });
  };

  return (
    <div className="class-page">
      <Spin spinning={loading}>
        <Flex vertical gap="large" wrap>
          <Flex gap="large" className="title" align="center" wrap>
            <span>
              <UsergroupAddOutlined />
            </span>
            <h1>{classInfo?.classroom_name}</h1>
            <Tag
              color="blue"
              style={{ fontSize: 16, padding: "4px 8px" }}
            >{`${classInfo?.quiz_count} học phần`}</Tag>
            {classInfo?.user_count !== undefined && (
              <Tag
                color="blue"
                style={{ fontSize: 16, padding: "4px 8px" }}
              >{`${classInfo.user_count + 1} thành viên`}</Tag>
            )}
          </Flex>
          <Flex wrap gap="large" justify="space-between" align="start">
            <div>
              {classInfo?.creator && (
                <Flex
                  gap="small"
                  align="center"
                  onClick={() => navigate(`/users/${classInfo.creator?._id}`)}
                  className="creator"
                >
                  <span>Tạo bởi</span>
                  <BaseImage
                    className="avatar"
                    src={classInfo.creator?.avatar}
                    width={20}
                    height={20}
                  />
                  <span className="name">{classInfo.creator?.name}</span>
                </Flex>
              )}
              {classInfo?.description && (
                <p>{`Mô tả: ${classInfo?.description}`}</p>
              )}
            </div>
            <Flex
              align="center"
              gap="small"
              style={{ marginTop: "0.67em" }}
              className="circle-btn"
            >
              {isEdit ? (
                <>
                  <Tooltip placement="bottom" title="Thêm học phần">
                    <Button
                      size="large"
                      shape="circle"
                      icon={<PlusOutlined />}
                      className="btn-border-gray"
                      onClick={() => setOpenAddQuizzesModal(true)}
                    />
                  </Tooltip>
                  {/* <Tooltip placement="bottom" title="Thêm thành viên">
                    <Button
                      size="large"
                      shape="circle"
                      icon={<UserAddOutlined />}
                      className="btn-border-gray"
                      onClick={() => setOpenAddUseModal(true)}
                    />
                  </Tooltip> */}
                  <Tooltip placement="bottom" title="Sửa">
                    <Button
                      size="large"
                      shape="circle"
                      icon={<EditOutlined />}
                      className="btn-border-gray"
                      onClick={() => setOpenEditClassModal(true)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Bạn có chắn chắn xóa lớp học này không?"
                    description="Việc xóa lớp học sẽ không hoàn tác!"
                    onConfirm={confirm}
                    okText="Có"
                    cancelText="Không"
                    placement="bottomRight"
                  >
                    <Tooltip placement="bottom" title="Xóa lớp học này">
                      <Button
                        size="large"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        className="btn-border-gray"
                        loading={deleting}
                      />
                    </Tooltip>
                  </Popconfirm>
                </>
              ) : (
                <></>
              )}
              <Tooltip placement="bottom" title="Chia sẻ">
                <Button
                  size="large"
                  shape="circle"
                  icon={<ShareAltOutlined />}
                  className="btn-border-gray"
                  onClick={() => setIsUpdateOpenShare(true)}
                />
              </Tooltip>
            </Flex>
          </Flex>
          {getUserStatusOfClass()}
          <Tabs
            size="large"
            activeKey={activeTab}
            items={
              getUserInfo()?._id && userStatusOfClass === RequestEnum.Admin
                ? [
                    {
                      key: "quizzes",
                      label: "Học phần",
                      children: quizzesTab(),
                    },
                    {
                      key: "members",
                      label: "Thành viên",
                      children: membersTab(),
                    },
                    {
                      key: "requests",
                      label: "Yêu cầu tham gia",
                      children: <ReceivedRequest classId={id} />,
                    },
                  ]
                : getUserInfo()?._id &&
                  userStatusOfClass === RequestEnum.Accepted
                ? [
                    {
                      key: "quizzes",
                      label: "Học phần",
                      children: quizzesTab(),
                    },
                  ]
                : []
            }
            onChange={(key: string) => setActiveTab(key)}
          />
        </Flex>
        <AddQuizModal
          isOpen={openAddQuizzesModal}
          handleCancel={() => setOpenAddQuizzesModal(false)}
          reloadQuizData={() => {
            getClassInfo();
            getQuizList();
          }}
          quizOfClass={quizOfClass}
        />
        {/* <AddUserModal
          isOpen={openAddUserModal}
          handleCancel={() => setOpenAddUseModal(false)}
        /> */}
        <EditClassModal
          isOpen={openEditClassModal}
          handleCancel={() => setOpenEditClassModal(false)}
          reloadClassData={getClassInfo}
        />
        <ShareModal
          isOpen={isOpenShare}
          handleCancel={() => setIsUpdateOpenShare(false)}
          title="Chia sẻ lớp học này"
        />
      </Spin>
    </div>
  );
};

export default Class;

import { CloseOutlined } from "@ant-design/icons";
import { Button, Flex, Spin } from "antd";
import LearnCard from "components/LearnCard";
import SelectMode from "components/SelectMode";
import { DisplayQuizTypeEnum } from "constant/quiz";
import { useQuestion } from "hook/useQuestion";
import { useQuiz } from "hook/useQuiz";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FinishLearn from "./FinishLearn";
import "./style.scss";

const LearnMode = () => {
  const { id } = useParams();
  const quiz = useQuiz();
  const question = useQuestion();
  const navigate = useNavigate();
  const [quizInfo, setQuizInfo] = useState<Quiz.FullQuizInfo>();
  const [listQuestion, setListQuestion] = useState<Question.Value[]>([]);
  const [loading, setLoading] = useState(true);
  const { openNotification } = useContext(NotificationContext);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answered, setAnswered] = useState(false);

  useEffect(() => {
    getQuizInfo();
    getListQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getQuizInfo = () => {
    if (!id) return;

    quiz.getQuizInfoById({
      id: id,
      callback: {
        onSuccess: (res: Quiz.FullQuizInfo) => {
          setQuizInfo(res);
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
      },
    });
  };

  const getListQuestion = () => {
    if (!id) return;

    setLoading(true);

    question.getQuestionsAnswerOfQuiz({
      id: id,
      callback: {
        onSuccess: (res: Question.Value[]) => {
          setListQuestion(res);
        },
        onFailure: (err: any) => {
          setListQuestion([]);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const onClickNextQuestion = () => {
    if (currentQuestionIndex !== listQuestion.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setAnswered(false);
    }
  };

  return (
    <div className="no-layout-mode no-layout scroll learn-mode">
      <Flex vertical className="header" gap="small">
        <Flex justify="space-between" align="center">
          <SelectMode mode={DisplayQuizTypeEnum.Learn} />
          <Flex vertical className="title">
            {listQuestion.length && (
              <>
                {currentQuestionIndex >= listQuestion.length ? (
                  <div>{`${listQuestion.length} / ${listQuestion.length}`}</div>
                ) : (
                  <div>{`${currentQuestionIndex + 1} / ${
                    listQuestion.length
                  }`}</div>
                )}
              </>
            )}
            <div>{quizInfo?.title}</div>
          </Flex>
          <Flex gap={16}>
            {/* <Button
              size="large"
              className="btn-border-gray"
              icon={<SettingOutlined />}
            /> */}
            <Button
              size="large"
              className="btn-border-gray"
              icon={<CloseOutlined />}
              onClick={() => {
                navigate(`/quizzes/${id}`);
              }}
            />
          </Flex>
        </Flex>
        <Flex vertical className="mobile-title">
          {listQuestion.length && (
            <>
              {currentQuestionIndex >= listQuestion.length ? (
                <div>{`${listQuestion.length} / ${listQuestion.length}`}</div>
              ) : (
                <div>{`${currentQuestionIndex + 1} / ${
                  listQuestion.length
                }`}</div>
              )}
            </>
          )}
          <div>{quizInfo?.title}</div>
        </Flex>
      </Flex>
      <div className="mode-content">
        <Spin spinning={loading}>
          {listQuestion.length &&
            (currentQuestionIndex !== listQuestion.length ? (
              <div className="test-content">
                <Flex align="center">
                  <LearnCard
                    question={listQuestion[currentQuestionIndex]}
                    setAnswered={() => setAnswered(true)}
                  />
                </Flex>
                {answered && (
                  <Flex className="btn-next" justify="center" align="center">
                    <Button
                      block
                      type="primary"
                      size="large"
                      onClick={onClickNextQuestion}
                    >
                      Tiếp tục
                    </Button>
                  </Flex>
                )}
              </div>
            ) : (
              <FinishLearn
                listFlashcard={listQuestion}
                resetCard={() => setCurrentQuestionIndex(0)}
              />
            ))}
        </Spin>
      </div>
    </div>
  );
};

export default LearnMode;

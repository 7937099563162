import React, { createContext, useState } from "react";
import { getUserInfo } from "utils/localStorage";

export const UserInfoContext = createContext({
  userInfo: getUserInfo(),
  setUserInfo: (userInfo: Auth.LoginResponseParams) => {},
});

const UserInfoProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUserInfo] = useState<Auth.LoginResponseParams | undefined>(
    getUserInfo()
  );

  return (
    <UserInfoContext.Provider
      value={{
        userInfo: user,
        setUserInfo: setUserInfo,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};

export default UserInfoProvider;

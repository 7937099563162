import MainLayout from "layouts";
import Chat from "pages/Chat";
import Class from "pages/Class";
import CreateQuizSet from "pages/CreateQuizSet";
import Dashboard from "pages/Dashboard";
import EditQuizInfo from "pages/EditQuizInfo";
import Folder from "pages/Folder";
import LandingPage from "pages/LandingPage";
import Login from "pages/Login";
import FlashcardMode from "pages/Mode/FlashcardMode";
import LearnMode from "pages/Mode/LearnMode";
import TestMode from "pages/Mode/TestMode";
import MyLibrary from "pages/MyLibrary";
import MyRequest from "pages/MyRequest";
import QuizInfo from "pages/QuizInfo";
import Register from "pages/Register";
import Search from "pages/Search";
import Setting from "pages/Setting";
import User from "pages/User";
import { Route, Routes } from "react-router";
import GuestRoute from "./GuestRoute";
import PrivateRoute from "./PrivateRoute";

const RouterPage = () => {
  return (
    <Routes>
      <Route path="/" element={<GuestRoute />}>
        <Route element={<MainLayout />}>
          <Route index element={<LandingPage />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/new-quiz-set" element={<CreateQuizSet />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/quizzes/:id/edit" element={<EditQuizInfo />} />
        <Route path="/my-library" element={<MyLibrary />} />
        <Route path="/my-request" element={<MyRequest />} />
        <Route path="/chat" element={<Chat />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/search" element={<Search />} />
        <Route path="/quizzes/:id" element={<QuizInfo />} />
        <Route path="/quizzes/:id/flashcard" element={<FlashcardMode />} />
        <Route path="/quizzes/:id/learn" element={<LearnMode />} />
        <Route path="/quizzes/:id/test" element={<TestMode />} />
        <Route path="/folders/:id" element={<Folder />} />
        <Route path="/classes/:id" element={<Class />} />
        <Route path="/users/:id" element={<User />} />
      </Route>
    </Routes>
  );
};

export default RouterPage;

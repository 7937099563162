import { Flex, Tag } from "antd";
import BaseImage from "components/BaseImage";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const FolderCard = ({
  folderInfo,
  showCreator,
}: {
  folderInfo: Folder.FullInfo;
  showCreator?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <Flex vertical justify="space-between" className="folder-card" gap="small">
      <Flex vertical gap="small">
        <span className="name">{folderInfo?.folder_name}</span>
        <span className="description">{folderInfo?.description}</span>
        <Flex gap="small">
          <Tag color="#55acee" style={{ width: "fit-content" }}>
            {`${folderInfo?.quizzes} học phần`}
          </Tag>
        </Flex>
      </Flex>
      {showCreator && (
        <Flex
          gap="small"
          align="center"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/users/${folderInfo?.creator?._id}`);
          }}
          className="creator-container"
        >
          <BaseImage
            className="avatar"
            src={folderInfo?.creator?.avatar}
            width={32}
            height={32}
          />
          <span>{folderInfo?.creator?.name || "user"}</span>
        </Flex>
      )}
    </Flex>
  );
};

export default FolderCard;

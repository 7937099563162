import { get, noop } from "lodash";
import { useStarAPI } from "api/useStarAPI";

export const useStar = () => {
  const quizAPI = useStarAPI();

  const starQuestion = async ({
    question,
    callback,
  }: {
    question: string;
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await quizAPI.starQuestion(question);

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  const toggleStarQuestion = async ({
    question,
    callback,
  }: {
    question: string;
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await quizAPI.toggleStarQuestion(question);

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  const getStarQuestionOfQuiz = async ({
    id,
    callback,
  }: {
    id: string;
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await quizAPI.getStarQuestionOfQuiz(id);

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  return { starQuestion, getStarQuestionOfQuiz, toggleStarQuestion };
};

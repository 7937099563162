import {
  FileAddOutlined,
  FolderAddOutlined,
  PlusOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Flex, MenuProps } from "antd";
import CreateClassModal from "components/CreateClassModal";
import CreateFolderModal from "components/CreateFolderModal";
import { useState } from "react";
import { Link } from "react-router-dom";

const AddQuizDropdown = () => {
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const [openCreateClass, setOpenCreateClass] = useState(false);

  const items: MenuProps["items"] = [
    {
      label: (
        <Link to="/new-quiz-set" className="add-dropdown-item">
          <Flex align="center" gap={16}>
            <FileAddOutlined />
            <span>Học phần</span>
          </Flex>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <span
          className="add-dropdown-item"
          onClick={() => setOpenCreateFolder(true)}
        >
          <Flex align="center" gap={16}>
            <FolderAddOutlined />
            <span>Thư mục</span>
          </Flex>
        </span>
      ),
      key: "1",
    },
    {
      label: (
        <span
          className="add-dropdown-item"
          onClick={() => setOpenCreateClass(true)}
        >
          <Flex align="center" gap={16}>
            <UsergroupAddOutlined />
            <span>Lớp</span>
          </Flex>
        </span>
      ),
      key: "2",
    },
  ];
  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
        <Button
          size="large"
          type="primary"
          shape="circle"
          onClick={(e) => e.preventDefault()}
          icon={<PlusOutlined />}
        />
      </Dropdown>
      <CreateFolderModal
        isOpen={openCreateFolder}
        handleCancel={() => setOpenCreateFolder(false)}
      />
      <CreateClassModal
        isOpen={openCreateClass}
        handleCancel={() => setOpenCreateClass(false)}
      />
    </>
  );
};

export default AddQuizDropdown;

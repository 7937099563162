import {
  CloseOutlined,
  EditFilled,
  PlusOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Button, Col, Flex, Form, Row, Spin, Switch, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { alphabet } from "constant/quiz";
import { useQuestion } from "hook/useQuestion";
import { useStar } from "hook/useStar";
import { Fragment, memo, useEffect, useMemo, useState } from "react";
import { getUserInfo } from "utils/localStorage";
import "./style.scss";

interface Props {
  question: Question.Value;
  creatorId: string;
  setSelectedQuestion: () => void;
  editSuccess: (newQuestion: Question.Value) => void;
  toggleStar: (questionId: string, starValue: boolean) => void;
}

const Question = (props: Props) => {
  const question = useQuestion();
  const [form] = Form.useForm();
  const star = useStar();
  const { text, answers, _id, is_star } = props.question;
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingEditQuiz, setLoadingEditQuiz] = useState(false);

  useEffect(() => {
    form.setFieldsValue(props.question);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.question]);

  const questions = useMemo(() => {
    const answer =
      answers.map((item, index) => {
        return `${alphabet[index]}. ${item.text}`;
      }) || "";

    return `${text}\n\n${answer.join("\n\n")}`;
  }, [answers, text]);

  const answer = useMemo(() => {
    if (answers.length === 1) return answers[0].text;

    for (let i = 0; i < answers.length; i++) {
      if (answers[i].is_correct) {
        return alphabet[i];
      }
    }

    return "";
  }, [answers]);

  const toggleStarQuestion = () => {
    if (!getUserInfo()?._id) return;
    setLoading(true);

    star.toggleStarQuestion({
      question: _id!,
      callback: {
        onSuccess: (status: number) => {
          props.toggleStar(_id!, Boolean(status));
        },
        onFailure: (err: any) => {
          console.log(err);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const onChooseAnswer = (answerIndex: number, value: boolean) => {
    if (
      value &&
      form
        .getFieldsValue()
        .answers.find(
          (item: Question.Answer, index: number) =>
            item.is_correct === true && answerIndex !== index
        )
    ) {
      const newQuestion: Question.Value = form.getFieldsValue();
      newQuestion.answers = newQuestion.answers.map((item, index) => {
        if (answerIndex === index || item?.text === undefined)
          return { ...item };
        return {
          ...item,
          is_correct: false,
        };
      });

      form.setFieldsValue(newQuestion);
    }

    form.validateFields();
  };

  const onDeleteAnswer = () => {
    const newQuestion: Question.Value = form.getFieldsValue();

    if (newQuestion?.answers.length === 1) {
      newQuestion.answers[0].is_correct = true;

      form.setFieldsValue(newQuestion);
    }
  };

  const submit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    Promise.all([form.validateFields()])
      .then(() => {
        editQuiz();
      })
      .catch(() => {});
  };

  const editQuiz = () => {
    setLoadingEditQuiz(true);

    question.updateAQuestion({
      params: form.getFieldsValue(true),
      callback: {
        onSuccess: () => {
          getNewQuestion();
        },
        onFailure: (err: any) => {
          console.log(err);
        },
        onFinish: () => {
          setLoadingEditQuiz(false);
        },
      },
    });
  };

  const getNewQuestion = () => {
    question.getQuestionById({
      id: props.question?._id!,
      callback: {
        onSuccess: (newQuestion: Question.Value) => {
          props.editSuccess(newQuestion);
          setEdit(false);
        },
        onFailure: (err: any) => {
          console.log(err);
        },
      },
    });
  };

  return (
    <div className="question-container">
      {!edit ? (
        <Row gutter={[24, 24]}>
          <Col xs={24} md={10}>
            <TextArea
              className="transparent"
              value={questions}
              onChange={(e) => {}}
              autoSize
              disabled={!edit}
            />
          </Col>
          <Col xs={24} md={14} className="answer-col">
            <TextArea
              className="transparent"
              value={answer}
              onChange={(e) => {}}
              autoSize
              disabled={!edit}
            />
          </Col>
        </Row>
      ) : (
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          className="edit-form"
        >
          <Flex vertical gap={20}>
            <Form.Item
              name="text"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập câu hỏi/ thuật ngữ!",
                },
              ]}
              validateTrigger={["onChange", "onBlur"]}
              className="question-text"
            >
              <TextArea
                autoSize
                placeholder="Nhập câu hỏi/ thuật ngữ"
                disabled={loadingEditQuiz}
              />
            </Form.Item>
            <Form.Item>
              <Form.List
                name="answers"
                rules={[
                  {
                    validator: async (_, answers: Question.Answer[]) => {
                      if (!answers.find((item) => item.is_correct === true)) {
                        return Promise.reject(
                          new Error("Vui lòng chọn 1 đáp án đúng!")
                        );
                      }
                    },
                  },
                ]}
              >
                {(subFields, subOpt, answerErrors) => (
                  <div style={{ paddingLeft: "16px" }}>
                    {subFields.map((subField) => {
                      const data = form.getFieldValue("answers")[subField.name];

                      return (
                        <Fragment key={subField.key}>
                          {data?.text !== undefined ? (
                            <Form.Item style={{ marginBottom: "10px" }}>
                              <Flex align="center" gap="small">
                                <Form.Item
                                  noStyle
                                  name={[subField.name, "text"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Vui lòng nhập đáp án/ định nghĩa!",
                                    },
                                  ]}
                                  validateTrigger={["onChange", "onBlur"]}
                                >
                                  <TextArea
                                    placeholder="Nhập đáp án/ định nghĩa"
                                    autoSize
                                    disabled={loadingEditQuiz}
                                  />
                                </Form.Item>

                                <Form.Item
                                  noStyle
                                  name={[subField.name, "is_correct"]}
                                >
                                  <Switch
                                    title="Chọn câu trả lời đúng"
                                    onChange={(checked: boolean) =>
                                      onChooseAnswer(subField.name, checked)
                                    }
                                    disabled={loadingEditQuiz}
                                  />
                                </Form.Item>
                                {subFields.length > 1 && (
                                  <CloseOutlined
                                    disabled={loadingEditQuiz}
                                    onClick={() => {
                                      if (data?._id) {
                                        const newList =
                                          form.getFieldValue("answers");
                                        newList[subField.name] = {
                                          _id: data?._id,
                                        };
                                        form.setFieldValue("answers", newList);
                                      } else {
                                        subOpt.remove(subField.name);
                                      }

                                      onDeleteAnswer();
                                    }}
                                  />
                                )}
                              </Flex>
                            </Form.Item>
                          ) : (
                            <></>
                          )}
                        </Fragment>
                      );
                    })}
                    <Form.ErrorList errors={answerErrors?.errors} />
                    <Button
                      className="btn-add"
                      onClick={() =>
                        subOpt.add({
                          is_correct: false,
                          text: "",
                        })
                      }
                      disabled={loadingEditQuiz}
                    >
                      + Thêm đáp án/ định nghĩa
                    </Button>
                  </div>
                )}
              </Form.List>
            </Form.Item>
            <Flex align="center" justify="end">
              <Button
                size="large"
                type="primary"
                onClick={(e) => submit(e)}
                loading={loadingEditQuiz}
              >
                Hoàn tất
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
      <Flex gap={10} align="center" className="feature">
        {getUserInfo()?._id && (
          <Spin spinning={loading}>
            <Tooltip placement="bottom" title="Gắn sao">
              <Flex
                align="center"
                justify="center"
                className={`feature-item ${is_star ? "active" : ""}`}
                onClick={toggleStarQuestion}
              >
                <StarFilled />
              </Flex>
            </Tooltip>
          </Spin>
        )}
        {props.creatorId === getUserInfo()?._id && (
          <>
            <Tooltip placement="bottom" title="Chỉnh sửa">
              <Flex
                align="center"
                justify="center"
                className={`feature-item ${edit ? "active" : ""}`}
                onClick={() => setEdit(!edit)}
              >
                <EditFilled />
              </Flex>
            </Tooltip>
            <Tooltip placement="bottom" title="Thêm vào học phần khác">
              <Flex
                align="center"
                justify="center"
                className="feature-item"
                onClick={() => {
                  props.setSelectedQuestion();
                }}
              >
                <PlusOutlined />
              </Flex>
            </Tooltip>
          </>
        )}
      </Flex>
    </div>
  );
};

export default memo(Question);

import { Button, Empty, Flex, Table, TableProps, Tag } from "antd";
import { RequestEnum } from "constant/class";
import { useRequest } from "hook/useRequest";
import moment from "moment";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";

// const getRequest = (status: number) => {
//   switch (status) {
//     case RequestEnum.Accepted:
//       return (
//         <Tag color="green" style={{ padding: "4px 8px", fontSize: "14px" }}>
//           Đã tham gia
//         </Tag>
//       );
//     case RequestEnum.Pending:
//       return (
//         <Tag color="blue" style={{ padding: "4px 8px", fontSize: "14px" }}>
//           Chờ xác nhận
//         </Tag>
//       );
//     case RequestEnum.Rejected:
//       return (
//         <Tag color="volcano" style={{ padding: "4px 8px", fontSize: "14px" }}>
//           Từ chối
//         </Tag>
//       );
//     default:
//       return "";
//   }
// };

const ReceivedRequest = ({ classId }: { classId?: string }) => {
  const request = useRequest();
  const [loading, setLoading] = useState(false);
  const [requestList, setRequestList] = useState<Request.FullDetails[]>([]);
  const [loadingList, setLoadingList] = useState<
    { id: string; status: number }[]
  >([]);
  const { openNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (getUserInfo()?._id) {
      getRequestList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRequestList = async (): Promise<void> => {
    setLoading(true);

    request.getAllReceivedRequest({
      callback: {
        onSuccess: (res: Request.FullDetails[]) => {
          if (classId) {
            setRequestList(
              res.filter(
                (item) =>
                  item.status === RequestEnum.Pending &&
                  item.classroom?._id === classId
              )
            );
          } else {
            setRequestList(
              res.filter(
                (item) => item.status === RequestEnum.Pending && item?.classroom
              )
            );
          }
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const updateRequest = (id: string, status: RequestEnum) => {
    request.updateRequest({
      id,
      status,
      callback: {
        onSuccess: (res: any) => {
          const newRequestList = [...requestList];
          const foundIndex = newRequestList.findIndex(
            (item) => item?._id === id
          );
          if (foundIndex >= 0) {
            newRequestList[foundIndex] = {
              ...newRequestList[foundIndex],
              status: status,
            };
          }
          setRequestList(
            newRequestList.filter(
              (item) =>
                item.status === RequestEnum.Pending &&
                item.classroom?._id === classId
            )
          );
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setLoadingList(loadingList.filter((item) => item.id !== id));
        },
      },
    });
  };

  const loadingItem = (record: Request.FullDetails) =>
    loadingList.find((item) => item.id === record?._id);

  const columns: TableProps<Request.FullDetails>["columns"] = [
    {
      title: "STT",
      dataIndex: "_id",
      key: "index",
      align: "center",
      render: (_: any, record: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Lớp học",
      dataIndex: "classroom",
      key: "classroom",
      render: (_: any, record: Request.FullDetails) => (
        <Link to={`/classes/${record?.classroom?._id}`}>
          {record?.classroom?.classroom_name}
        </Link>
      ),
      sorter: (a: Request.FullDetails, b: Request.FullDetails) =>
        a?.classroom && b?.classroom
          ? a?.classroom?.classroom_name?.length -
            b?.classroom?.classroom_name?.length
          : 1,
    },
    {
      title: "Người dùng",
      dataIndex: "requester",
      key: "requester",
      render: (_: any, record: Request.FullDetails) => (
        <Link to={`/users/${record?.requester?._id}`}>
          {record?.requester?.name}
        </Link>
      ),
      sorter: (a: Request.FullDetails, b: Request.FullDetails) =>
        a?.requester.name.length - b?.requester.name.length,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: () => (
        <Tag color="blue" style={{ padding: "4px 8px", fontSize: "14px" }}>
          Chờ xác nhận
        </Tag>
      ),
      sorter: (a: Request.FullDetails, b: Request.FullDetails) =>
        a?.status - b?.status,
    },
    {
      title: "Ngày yêu cầu tham gia",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => (
        <>{moment(createdAt).format("DD/MM/YYYY HH:mm")}</>
      ),
      sorter: (a: Request.FullDetails, b: Request.FullDetails) => {
        return moment(a?.createdAt).isAfter(b?.createdAt) ? 1 : 0;
      },
    },
    {
      title: "",
      dataIndex: "confirm",
      key: "confirm",
      render: (_: any, record: Request.FullDetails) => {
        const loading = loadingItem(record);
        return (
          // <>
          //   {record?.status === RequestEnum.Pending ? (
          //     <Flex justify="space-between" gap="large">
          //     <Button
          //       className="btn-border-gray"
          //       size="large"
          //       loading={
          //         loading?.status === RequestEnum.Accepted &&
          //         loading?.id === record?._id
          //       }
          //       disabled={loading?.id === record?._id}
          //       onClick={() => {
          //         onClickUpdateRequest(record, RequestEnum.Accepted);
          //       }}
          //     >
          //       Chấp nhận
          //     </Button>
          //     <Button
          //       danger
          //       size="large"
          //       loading={
          //         loading?.status === RequestEnum.Rejected &&
          //         loading?.id === record?._id
          //       }
          //       disabled={loading?.id === record?._id}
          //       onClick={() => {
          //         onClickUpdateRequest(record, RequestEnum.Rejected);
          //       }}
          //     >
          //       Từ chối
          //     </Button>
          //   </Flex>
          //   ) : record?.status === RequestEnum.Accepted ? (
          //     <Button
          //       danger
          //       size="large"
          //       loading={
          //         loading?.status === RequestEnum.Rejected &&
          //         loading?.id === record?._id
          //       }
          //       disabled={loading?.id === record?._id}
          //       onClick={() => {
          //         onClickUpdateRequest(record, RequestEnum.Rejected);
          //       }}
          //     >
          //       Xóa khỏi lớp
          //     </Button>
          //   ) : record?.status === RequestEnum.Rejected ? (
          //     <Button
          //       className="btn-border-gray"
          //       size="large"
          //       loading={
          //         loading?.status === RequestEnum.Accepted &&
          //         loading?.id === record?._id
          //       }
          //       disabled={loading?.id === record?._id}
          //       onClick={() => {
          //         onClickUpdateRequest(record, RequestEnum.Accepted);
          //       }}
          //     >
          //       Chấp nhận
          //     </Button>
          //   ) : (
          //     <></>
          //   )}
          // </>
          <Flex align="center" gap="large">
            <Button
              className="btn-border-gray"
              size="large"
              loading={
                loading?.status === RequestEnum.Accepted &&
                loading?.id === record?._id
              }
              disabled={loading?.id === record?._id}
              onClick={() => {
                onClickUpdateRequest(record, RequestEnum.Accepted);
              }}
            >
              Chấp nhận
            </Button>
            <Button
              danger
              size="large"
              loading={
                loading?.status === RequestEnum.Rejected &&
                loading?.id === record?._id
              }
              disabled={loading?.id === record?._id}
              onClick={() => {
                onClickUpdateRequest(record, RequestEnum.Rejected);
              }}
            >
              Từ chối
            </Button>
          </Flex>
        );
      },
    },
  ];

  const onClickUpdateRequest = (
    record: Request.FullDetails,
    status: number
  ) => {
    setLoadingList([...loadingList, { id: record._id, status }]);
    updateRequest(record._id!, status);
  };

  return (
    <Table
      dataSource={requestList}
      columns={
        classId ? columns : [...columns.slice(0, 1), ...columns.slice(2)]
      }
      loading={loading}
      rowKey={(record) => record._id}
      showSorterTooltip={false}
      locale={{ emptyText: <Empty description="Dữ liệu rỗng" /> }}
    />
  );
};

export default ReceivedRequest;

import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Flex, Form, Modal, Spin } from "antd";
import CreateClassModal from "components/CreateClassModal";
import { useClass } from "hook/useClass";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.scss";

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  classHaveQuiz: string[];
  reloadQuizData: () => void;
}

const AddToClass = (props: Props) => {
  const { id } = useParams();
  const classroom = useClass();
  const [form] = Form.useForm();
  const { isOpen, handleCancel, classHaveQuiz, reloadQuizData } = props;
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openAddClassModal, setOpenAddClassModal] = useState(false);
  const [listClass, setListClass] = useState<Class.FullInfo[]>([]);
  const { openNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (isOpen) {
      getClasses();
      form.setFieldsValue({
        classrooms: classHaveQuiz,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getClasses = () => {
    setLoading(true);
    classroom.getMyClass({
      callback: {
        onSuccess: (res: Class.FullInfo[]) => {
          setListClass(res);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const addToClass = () => {
    if (!id) return;
    setSubmitting(true);

    classroom.addQuizToManyClasses({
      params: {
        quiz: id,
        classrooms: form.getFieldValue("classrooms"),
      },
      callback: {
        onSuccess: () => {
          openNotification("Thêm vào lớp học thành công!", "");
          onCancel();
        },
        onFinish: () => {
          setSubmitting(false);
        },
      },
    });
  };

  const onFinish = (values: any) => {
    addToClass();
  };

  const onCancel = () => {
    handleCancel();
    reloadQuizData();
  };

  return (
    <>
      <Modal
        className="add-class-model"
        title={<h2>Thêm vào lớp học</h2>}
        open={isOpen}
        onCancel={() => onCancel()}
        footer={
          <Flex justify="flex-end">
            <Button
              style={{ height: "46px" }}
              className="btn-submit"
              type="primary"
              size="large"
              loading={submitting}
              onClick={() => form.submit()}
            >
              Thêm
            </Button>
          </Flex>
        }
        width={800}
        style={{ top: 20 }}
      >
        <Spin spinning={loading} style={{ minHeight: 400 }}>
          <Flex vertical gap="large" className="form-content">
            <Button
              className="btn-border-gray btn-new-class"
              icon={<PlusOutlined />}
              type="text"
              size="large"
              block
              onClick={() => setOpenAddClassModal(true)}
            >
              Tạo lớp mới
            </Button>
            <Form
              className="base-form"
              form={form}
              layout="vertical"
              scrollToFirstError
              autoComplete="off"
              onFinish={onFinish}
            >
              <Form.Item
                name="classrooms"
                rules={[{ required: true, message: "Vui lòng chọn học phần!" }]}
              >
                <Checkbox.Group>
                  <Flex vertical gap="small">
                    {listClass.map((item) => {
                      return (
                        <Checkbox
                          value={item._id}
                          key={item._id}
                          style={{ lineHeight: "32px" }}
                          disabled={
                            classHaveQuiz.find(
                              (classId) => classId === item._id
                            )
                              ? true
                              : false
                          }
                        >
                          <div className="quiz-bar">{item.classroom_name}</div>
                        </Checkbox>
                      );
                    })}
                  </Flex>
                </Checkbox.Group>
              </Form.Item>
            </Form>
          </Flex>
        </Spin>
      </Modal>
      <CreateClassModal
        isOpen={openAddClassModal}
        handleCancel={() => setOpenAddClassModal(false)}
        reloadData={getClasses}
      />
    </>
  );
};

export default AddToClass;

import { MessageOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import MyLibrary from "components/MyLibrary";
import { useAuth } from "hook/useAuth";
import { Link, NavLink, useNavigate } from "react-router-dom";
import AddQuizDropdown from "./AddQuizDropdown";
import AvatarDropdown from "./AvatarDropdown";
import "./style.scss";

const Header = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const logged = auth.isLoggedIn();

  return (
    <Flex justify="space-between" className="header">
      <div className="header__left">
        <Link to="/">
          <img src="/images/full-logo-primary.svg" alt="Logo" />
        </Link>
        <Flex align="stretch" className="menu">
          {logged ? (
            <>
              <NavLink to="/dashboard" className="menu-item">
                Trang chủ
              </NavLink>
              <div className="menu-item popover">
                <MyLibrary />
              </div>
            </>
          ) : (
            <NavLink to="/" className="menu-item">
              Trang chủ
            </NavLink>
          )}
        </Flex>
      </div>
      <div className="header__right">
        <Button
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          className="btn-border-gray"
          onClick={() => navigate("/search")}
        />
        {logged ? (
          <Flex align="center" gap={16}>
            <AddQuizDropdown />
            <Button
              size="large"
              shape="circle"
              icon={<MessageOutlined />}
              className="btn-border-gray"
              onClick={() => navigate("/chat")}
            />
            <AvatarDropdown />
          </Flex>
        ) : (
          <Flex align="center" gap={16}>
            <Button
              size="large"
              type="text"
              className="btn-transparent"
              onClick={() => navigate("/login")}
            >
              Đăng nhập
            </Button>
            <Button
              size="large"
              type="text"
              className="btn-yellow"
              onClick={() => navigate("/register")}
            >
              Đăng kí
            </Button>
          </Flex>
        )}
      </div>
    </Flex>
  );
};

export default Header;

import {
  CheckCircleFilled,
  FolderOutlined,
  StarFilled,
  UserOutlined,
} from "@ant-design/icons";
import { Empty, Flex, Pagination, Spin, Tabs, Tooltip } from "antd";
import { LibraryMode } from "constant/user";
import { useClass } from "hook/useClass";
import { useFolder } from "hook/useFolder";
import { useQuiz } from "hook/useQuiz";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";

const MyLibrary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState<string>(
    query?.get("tab") || LibraryMode.Quiz
  );

  useEffect(() => {
    const currentQuery = new URLSearchParams(location.search);
    if (activeTab) {
      currentQuery.set("tab", activeTab);
      navigate({ search: currentQuery.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, location.search]);

  const libraryMode = [
    { key: LibraryMode.Quiz, label: "Học phần", children: <QuizCard /> },
    { key: LibraryMode.Folder, label: "Thư mục", children: <FolderCard /> },
    { key: LibraryMode.Class, label: "Lớp học", children: <ClassCard /> },
  ];

  return (
    <Flex vertical gap={24} className="my-library-page">
      <h1>Thư viện của bạn</h1>

      <Tabs
        size="large"
        activeKey={activeTab}
        items={libraryMode}
        onChange={(key: string) => setActiveTab(key)}
      />
    </Flex>
  );
};

export default MyLibrary;

const QuizCard = () => {
  const quiz = useQuiz();
  const [loading, setLoading] = useState(false);
  const [quizData, setQuizData] =
    useState<Quiz.GetMyCreatedQuizPaginationResponse>();

  useEffect(() => {
    getQuizData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuizData = async (): Promise<void> => {
    setLoading(true);

    quiz.getMyCreatedQuiz({
      params: {
        page: 1,
        limit: 10,
      },
      callback: {
        onSuccess: (res: Quiz.GetMyCreatedQuizPaginationResponse) => {
          setQuizData(res);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <div className="content">
      <Spin spinning={loading}>
        {quizData?.quizzes?.length ? (
          <>
            <div className="tab-content">
              {quizData?.quizzes &&
                quizData?.quizzes.map((item) => {
                  return (
                    <Link
                      to={`/quizzes/${item._id}`}
                      key={item._id}
                      className="tab-content__item"
                    >
                      <Flex vertical>
                        <Flex gap="small" wrap align="center">
                          <span>{`${item?.question_count} thuật ngữ`}</span>
                          {item?.review_count ? (
                            <Flex align="small">
                              <StarFilled
                                style={{
                                  color: "#ffcd1f",
                                  paddingRight: 8,
                                  fontSize: 14,
                                }}
                              />
                              <span>{`${item?.review_average} (${item?.review_count}) đánh giá`}</span>
                            </Flex>
                          ) : (
                            <></>
                          )}
                        </Flex>
                        <span className="quiz-title">
                          {item?.title}
                          {item?.verified && item?.is_public ? (
                            <Tooltip title={"Học phần public"}>
                              <CheckCircleFilled
                                style={{
                                  color: "#52c41a",
                                  paddingLeft: "8px",
                                  fontSize: "14px",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </span>
                      </Flex>
                    </Link>
                  );
                })}
              <Flex justify="end" className="pagination">
                <Pagination total={quizData?.count || 0} showQuickJumper />
              </Flex>
            </div>
          </>
        ) : (
          <Empty description="Dữ liệu rỗng" />
        )}
      </Spin>
    </div>
  );
};

const FolderCard = () => {
  const folder = useFolder();
  const [loading, setLoading] = useState(false);
  const [folderData, setFolderData] =
    useState<Folder.MyFoldersPaginationResponse>();

  useEffect(() => {
    getFolderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFolderData = async (): Promise<void> => {
    setLoading(true);

    folder.getMyFolders({
      params: {
        page: 1,
        limit: 10,
      },
      callback: {
        onSuccess: (res: Folder.MyFoldersPaginationResponse) => {
          setFolderData(res);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };
  return (
    <div className="content">
      <Spin spinning={loading}>
        {folderData?.folders?.length ? (
          <>
            <div className="tab-content">
              {folderData?.folders &&
                folderData?.folders.map((item) => {
                  return (
                    <Link
                      to={`/folders/${item._id}`}
                      key={item._id}
                      className="tab-content__item"
                    >
                      <Flex vertical>
                        <span>{`${item?.quizzes} học phần`}</span>
                        <Flex gap="small" align="center">
                          <FolderOutlined />
                          <span className="quiz-title">
                            {item?.folder_name}
                          </span>
                        </Flex>
                      </Flex>
                    </Link>
                  );
                })}
              <Flex justify="end" className="pagination">
                <Pagination total={folderData?.count || 0} showQuickJumper />
              </Flex>
            </div>
          </>
        ) : (
          <Empty description="Dữ liệu rỗng" />
        )}
      </Spin>
    </div>
  );
};

const ClassCard = () => {
  const classroom = useClass();
  const [loading, setLoading] = useState(false);
  const [classData, setClassData] = useState<Class.ClassesPaginationResponse>();
  useEffect(() => {
    getClassData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClassData = async (): Promise<void> => {
    setLoading(true);

    classroom.getMyClass({
      params: {
        page: 1,
        limit: 10,
      },
      callback: {
        onSuccess: (res: Class.ClassesPaginationResponse) => {
          setClassData(res);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <div className="content">
      <Spin spinning={loading}>
        {classData?.classrooms?.length ? (
          <>
            <div className="tab-content">
              {classData?.classrooms &&
                classData?.classrooms.map((item) => {
                  return (
                    <Link
                      to={`/classes/${item._id}`}
                      key={item._id}
                      className="tab-content__item"
                    >
                      <Flex vertical>
                        <Flex gap="small" align="center">
                          <span>{`${item?.quiz_count} học phần`}</span>
                          <span className="border-left">{`${
                            item.user_count! + 1
                          } thành viên`}</span>
                        </Flex>

                        <Flex gap="small" align="center">
                          <UserOutlined />
                          <span className="quiz-title">
                            {item?.classroom_name}
                          </span>
                        </Flex>
                      </Flex>
                    </Link>
                  );
                })}
              <Flex justify="end" className="pagination">
                <Pagination total={classData?.count || 0} showQuickJumper />
              </Flex>
            </div>
          </>
        ) : (
          <Empty description="Dữ liệu rỗng" />
        )}
      </Spin>
    </div>
  );
};

import { CheckCircleFilled, DownOutlined } from "@ant-design/icons";
import { Empty, Flex, Popover, Spin, Tabs, Tooltip } from "antd";
import { LibraryMode, libraryMode } from "constant/user";
import { useClass } from "hook/useClass";
import { useFolder } from "hook/useFolder";
import { useQuiz } from "hook/useQuiz";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";

interface CardInfo {
  _id: string;
  title: string;
  questions?: number;
  quizzes?: number;
  user_count?: number;
  verified?: boolean;
}

const MyLibrary = () => {
  const navigate = useNavigate();
  return (
    <Popover
      placement="bottom"
      title={<></>}
      content={<PopoverContent />}
      destroyTooltipOnHide
    >
      <Flex
        align="center"
        justify="center"
        onClick={() => navigate("/my-library?tab=1")}
      >
        <span>Thư viện của bạn</span>
        <DownOutlined />
      </Flex>
    </Popover>
  );
};

const TabContent = ({ activeTab }: { activeTab: string }) => {
  const quiz = useQuiz();
  const folder = useFolder();
  const classroom = useClass();
  const [data, setData] = useState<CardInfo[]>([]);
  const [loading, setLoading] = useState(false);

  const getQuizData = async (): Promise<void> => {
    setLoading(true);

    quiz.getMyCreatedQuiz({
      params: {
        page: 1,
        limit: 5,
      },
      callback: {
        onSuccess: (res: Quiz.GetMyCreatedQuizPaginationResponse) => {
          setData(
            res.quizzes.map((item) => {
              return {
                _id: item._id,
                title: item.title,
                questions: item.question_count,
                verified: item?.is_public && item?.verified,
              };
            })
          );
        },
        onFailure: (err: any) => {
          setData([]);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getFolderData = async (): Promise<void> => {
    setLoading(true);

    folder.getMyFolders({
      params: {
        page: 1,
        limit: 5,
      },
      callback: {
        onSuccess: (res: Folder.MyFoldersPaginationResponse) => {
          setData(
            res.folders.map((item) => {
              return {
                _id: item._id,
                title: item.folder_name,
                quizzes: item.quizzes,
              };
            })
          );
        },
        onFailure: (err: any) => {
          setData([]);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getClassData = async (): Promise<void> => {
    setLoading(true);

    classroom.getMyClass({
      params: {
        page: 1,
        limit: 5,
      },
      callback: {
        onSuccess: (res: Class.ClassesPaginationResponse) => {
          setData(
            res.classrooms.map((item) => {
              return {
                _id: item._id,
                title: item.classroom_name,
                quizzes: item.quiz_count,
                user_count: item.user_count,
              };
            })
          );
        },
        onFailure: (err: any) => {
          setData([]);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  useEffect(() => {
    if (activeTab === LibraryMode.Quiz) {
      getQuizData();
    } else if (activeTab === LibraryMode.Folder) {
      getFolderData();
    } else if (activeTab === LibraryMode.Class) {
      getClassData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <Spin spinning={loading}>
      {data?.length ? (
        <>
          <div className="tab-content">
            {data &&
              data.map((item) => {
                return (
                  <Link
                    to={`${
                      activeTab === LibraryMode.Quiz
                        ? `/quizzes/${item._id}`
                        : activeTab === LibraryMode.Folder
                        ? `/folders/${item._id}`
                        : activeTab === LibraryMode.Class
                        ? `/classes/${item._id}`
                        : "#"
                    }`}
                    key={item._id}
                    className="tab-content__item"
                  >
                    <span className="title-gray-1rem title">
                      {item.title}
                      {item?.verified ? (
                        <Tooltip title={"Học phần public"}>
                          <CheckCircleFilled
                            style={{ color: "#52c41a", paddingLeft: "8px" }}
                          />
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </span>
                    <Flex align="center">
                      <Flex gap="large" wrap>
                        {item?.questions !== undefined && (
                          <span className="text-gray quizzes">{`${item.questions} thuật ngữ`}</span>
                        )}
                        {item?.quizzes !== undefined && (
                          <span className="text-gray quizzes">{`${item.quizzes} học phần`}</span>
                        )}
                        {item?.user_count !== undefined && (
                          <span className="text-gray quizzes">{`${
                            item.user_count + 1
                          } thành viên`}</span>
                        )}
                      </Flex>
                    </Flex>
                  </Link>
                );
              })}
          </div>
          <div className="footer">
            {activeTab === LibraryMode.Quiz ? (
              <Link to={`/my-library?tab=${LibraryMode.Quiz}`}>
                Xem tất cả các học phần
              </Link>
            ) : activeTab === LibraryMode.Folder ? (
              <Link to={`/my-library?tab=${LibraryMode.Folder}`}>
                Xem tất cả các thư mục
              </Link>
            ) : (
              <Link to={`/my-library?tab=${LibraryMode.Class}`}>
                Xem tất cả các lớp học
              </Link>
            )}
          </div>
        </>
      ) : (
        <Empty description="Dữ liệu rỗng" />
      )}
    </Spin>
  );
};

const PopoverContent = () => {
  const [activeTab, setActiveTab] = useState<string>(libraryMode[0].key);

  return (
    <Flex vertical className="popover-content">
      <Tabs
        activeKey={activeTab}
        items={libraryMode}
        onChange={(key: string) => setActiveTab(key)}
      />
      <div className="content">
        <TabContent activeTab={activeTab} />
      </div>
    </Flex>
  );
};

export default MyLibrary;

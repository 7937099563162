import { get, noop } from "lodash";
import { useSubjectAPI } from "api/useSubjectAPI";

export const useSubject = () => {
  const subjectAPI = useSubjectAPI();

  const getSubjectList = async ({
    callback,
  }: {
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await subjectAPI.getSubjectList();

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  return { getSubjectList };
};

import {
  DeleteOutlined,
  EditOutlined,
  FolderOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Flex,
  Popconfirm,
  PopconfirmProps,
  Row,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import BaseImage from "components/BaseImage";
import ShareModal from "components/ShareModal";
import { useFolder } from "hook/useFolder";
import { useQuiz } from "hook/useQuiz";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";
import AddQuizModal from "./AddQuizModal";
import EditFolderModal from "./EditFolderModal";
import QuizCard from "./QuizCard";
import "./style.scss";

const Folder = () => {
  const navigate = useNavigate();
  const quiz = useQuiz();
  const folder = useFolder();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [openAddQuizzesModal, setOpenAddQuizzesModal] = useState(false);
  const [openEditQuizzesModal, setOpenEditQuizzesModal] = useState(false);
  const [folderInfo, setFolderInfo] = useState<Folder.FullInfo>();
  const [listQuizzesOfFolder, setListQuizzesOfFolder] = useState<
    Quiz.FullQuizInfo[]
  >([]);
  const [isOpenShare, setIsUpdateOpenShare] = useState(false);

  const [quizOfFolder, setQuizOfFolder] = useState<string[]>([]);
  const userInfo = getUserInfo();

  useEffect(() => {
    getFolderInfo();
    getQuizList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getFolderInfo = () => {
    if (!id) return;
    setLoading(true);

    folder.getFolderById({
      id,
      callback: {
        onSuccess: (res: Folder.FullInfo) => {
          setFolderInfo(res);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getQuizList = () => {
    if (!id) return;
    setLoading(true);

    quiz.getQuizInfoByFolderId({
      id,
      callback: {
        onSuccess: (res: Quiz.FullQuizInfo[]) => {
          setListQuizzesOfFolder(res);
          setQuizOfFolder(res.map((item) => item._id));
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const confirm: PopconfirmProps["onConfirm"] = (e) => {
    if (!id) return;
    setDeleting(true);

    folder.deleteFolderById({
      id,
      callback: {
        onSuccess: () => {
          navigate("/my-library?tab=2");
        },
        onFinish: () => {
          setDeleting(false);
        },
      },
    });
  };

  return (
    <div className="folder-page">
      <Spin spinning={loading}>
        <Flex vertical gap="large">
          <Flex gap="large" className="title" align="center">
            <span>
              <FolderOutlined />
            </span>
            <h1>{folderInfo?.folder_name}</h1>
            {listQuizzesOfFolder?.length ? (
              <Tag
                color="blue"
                style={{ fontSize: 16, padding: "4px 8px" }}
              >{`${listQuizzesOfFolder?.length} học phần`}</Tag>
            ) : (
              <></>
            )}
          </Flex>
          <Flex wrap gap="large" justify="space-between" align="start">
            <div>
              {folderInfo?.creator && (
                <Flex
                  gap="small"
                  align="center"
                  onClick={() => navigate(`/users/${folderInfo.creator?._id}`)}
                  className="creator"
                >
                  <span>Tạo bởi</span>
                  <BaseImage
                    className="avatar"
                    src={folderInfo.creator?.avatar}
                    width={20}
                    height={20}
                  />
                  <span className="name">{folderInfo.creator?.name}</span>
                </Flex>
              )}
              {folderInfo?.description && (
                <p>{`Mô tả: ${folderInfo.description}`}</p>
              )}
            </div>
            <Flex
              align="center"
              gap="small"
              style={{ marginTop: "0.67em" }}
              className="circle-btn"
            >
              {userInfo && userInfo?._id === folderInfo?.creator?._id && (
                <>
                  <Tooltip placement="bottom" title="Thêm học phần">
                    <Button
                      size="large"
                      shape="circle"
                      icon={<PlusOutlined />}
                      className="btn-border-gray"
                      onClick={() => setOpenAddQuizzesModal(true)}
                    />
                  </Tooltip>
                  <Tooltip placement="bottom" title="Sửa">
                    <Button
                      size="large"
                      shape="circle"
                      icon={<EditOutlined />}
                      className="btn-border-gray"
                      onClick={() => setOpenEditQuizzesModal(true)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Bạn có chắn chắn xóa thư mục này không?"
                    description="Việc xóa thư mục sẽ không hoàn tác!"
                    onConfirm={confirm}
                    okText="Có"
                    cancelText="Không"
                    placement="bottomRight"
                  >
                    <Tooltip placement="bottom" title="Xóa thư mục này">
                      <Button
                        size="large"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        className="btn-border-gray"
                        loading={deleting}
                      />
                    </Tooltip>
                  </Popconfirm>
                </>
              )}

              {/* <Tooltip placement="bottom" title="Học">
                <Button
                  size="large"
                  shape="circle"
                  icon={<BulbOutlined />}
                  className="btn-border-gray"
                />
              </Tooltip> */}
              <Tooltip placement="bottom" title="Chia sẻ">
                <Button
                  size="large"
                  shape="circle"
                  icon={<ShareAltOutlined />}
                  className="btn-border-gray"
                  onClick={() => setIsUpdateOpenShare(true)}
                />
              </Tooltip>
            </Flex>
          </Flex>
          {listQuizzesOfFolder.length ? (
            <Row style={{ paddingTop: "1rem" }} gutter={[24, 24]}>
              {listQuizzesOfFolder.map((item) => {
                return (
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    key={item._id}
                    onClick={() => navigate(`/quizzes/${item._id}`)}
                  >
                    <QuizCard quizInfo={item} key={item._id} />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Flex vertical align="center" gap="large">
              <h2>Không có học phần nào!</h2>
              {userInfo && userInfo?._id === folderInfo?.creator?._id && (
                <Button
                  type="primary"
                  size="large"
                  icon={<PlusOutlined />}
                  onClick={() => setOpenAddQuizzesModal(true)}
                >
                  Thêm học phần
                </Button>
              )}
            </Flex>
          )}
        </Flex>
      </Spin>
      <AddQuizModal
        isOpen={openAddQuizzesModal}
        handleCancel={() => setOpenAddQuizzesModal(false)}
        reloadQuizData={getQuizList}
        quizOfFolder={quizOfFolder}
      />
      <EditFolderModal
        isOpen={openEditQuizzesModal}
        handleCancel={() => setOpenEditQuizzesModal(false)}
        reloadFolderData={getFolderInfo}
      />
      <ShareModal
        isOpen={isOpenShare}
        handleCancel={() => setIsUpdateOpenShare(false)}
        title="Chia sẻ thư mục này"
      />
    </div>
  );
};

export default Folder;

import { CloseOutlined } from "@ant-design/icons";
import { Button, Flex, Spin } from "antd";
import FlashcardModeComponent from "components/FlashcardModeComponent";
import SelectMode from "components/SelectMode";
import { DisplayQuizTypeEnum } from "constant/quiz";
import { useQuestion } from "hook/useQuestion";
import { useQuiz } from "hook/useQuiz";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";

const FlashcardMode = () => {
  const { id } = useParams();
  const question = useQuestion();
  const quiz = useQuiz();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [listQuestion, setListQuestion] = useState<Question.Value[]>([]);
  const [quizInfo, setQuizInfo] = useState<Quiz.FullQuizInfo>();
  const { openNotification } = useContext(NotificationContext);

  useEffect(() => {
    getQuizInfo();
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getQuizInfo = () => {
    if (!id) return;

    quiz.getQuizInfoById({
      id: id,
      callback: {
        onSuccess: (res: Quiz.FullQuizInfo) => {
          setQuizInfo(res);
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
      },
    });
  };

  const getData = () => {
    if (getUserInfo()?._id) {
      getQuestionsAnswerStarOfQuiz();
    } else {
      getQuestionsAnswerOfQuiz();
    }
  };

  const getQuestionsAnswerOfQuiz = () => {
    if (!id) return;
    setLoading(true);
    question.getQuestionsAnswerOfQuiz({
      id,
      callback: {
        onSuccess: (res: Question.Value[]) => {
          setListQuestion(res);
        },
        onFailure: (err: any) => {},
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getQuestionsAnswerStarOfQuiz = () => {
    if (!id) return;
    setLoading(true);
    question.getQuestionsAnswerStarOfQuiz({
      id,
      callback: {
        onSuccess: (res: Question.Value[]) => {
          setListQuestion(res);
        },
        onFailure: (err: any) => {},
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <div className="no-layout-mode no-layout">
      <Flex vertical className="header" gap="small">
        <Flex justify="space-between" align="center">
          <SelectMode mode={DisplayQuizTypeEnum.FlashCard} />
          <div className="title">{quizInfo?.title}</div>
          <Button
            size="large"
            className="btn-border-gray"
            icon={<CloseOutlined />}
            onClick={() => {
              navigate(`/quizzes/${id}`);
            }}
          />
        </Flex>
        <div className="mobile-title">{quizInfo?.title}</div>
      </Flex>
      <Spin spinning={loading}>
        {id && (
          <FlashcardModeComponent quizId={id} questionList={listQuestion} />
        )}
      </Spin>
    </div>
  );
};

export default FlashcardMode;

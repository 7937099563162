import { useHttpRequest } from "./useHttpRequest";

export const useQuestionAPI = () => {
  const httpRequest = useHttpRequest();

  const createQuestions = async (
    params: Question.CreateQuestionsParams
  ): Promise<void> => httpRequest.POST("/questions/batch-create", params);

  const getQuestionsAnswerOfQuiz = async (
    id: string
  ): Promise<Question.Value[]> => httpRequest.GET(`/questions/by-quiz/${id}`);

  const getQuestionsAnswerStarOfQuiz = async (
    id: string
  ): Promise<Question.Value[]> =>
    httpRequest.GET(`/questions/by-star-quiz/${id}`);

  const getQuestionById = async (id: string): Promise<Question.Value> =>
    httpRequest.GET(`/questions/${id}`);

  const getQuestionsNoAnswerOfQuiz = async (
    id: string
  ): Promise<Question.NoAnswerValue[]> =>
    httpRequest.GET(`/questions/test-by-quiz/${id}`);

  const updateListQuestionsOfQuiz = async (
    params: Question.UpdateQuestion
  ): Promise<void> => httpRequest.POST("/questions/update", params);

  const updateAQuestion = async (
    params: Question.UpdateSingleQuestion
  ): Promise<void> => httpRequest.POST("/questions/update-single", params);

  const addQuestionsToQuizzes = async (
    params: Question.AddQuestionsToQuizzes
  ): Promise<void> => httpRequest.POST("/questions/add-to-quizzes", params);

  const getCreatedQuizFullDetails = async (): Promise<Quiz.FullDetails[]> =>
    httpRequest.GET("/questions/test-all");

  return {
    createQuestions,
    getQuestionsAnswerOfQuiz,
    getQuestionsNoAnswerOfQuiz,
    updateListQuestionsOfQuiz,
    addQuestionsToQuizzes,
    updateAQuestion,
    getQuestionById,
    getQuestionsAnswerStarOfQuiz,
    getCreatedQuizFullDetails,
  };
};

import { RightOutlined } from "@ant-design/icons";
import { Col, Flex, Row } from "antd";
import firework from "assets/json/firework.json";
import BaseImage from "components/BaseImage";
import Icon from "components/Icon";
import { memo } from "react";
import Lottie from "react-lottie";
import { useNavigate, useParams } from "react-router-dom";
import "./style.scss";

interface Props {
  listFlashcard: Question.Value[];
  resetCard: () => void;
}

const FinishReviewCards = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { listFlashcard, resetCard } = props;

  return (
    <div className="finish-review-cards">
      <div className="firework">
        <Lottie
          options={{
            loop: 1,
            autoplay: true,
            animationData: firework,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          speed={0.5}
          isPaused={false}
        />
      </div>

      <Flex vertical gap="1.5rem">
        <Flex gap={16} align="center">
          <h1>Chúc mừng! Bạn đã ôn tập tất cả các thẻ.</h1>
          <div className="congratulation-image">
            <BaseImage src="/images/congratulation.svg" />
          </div>
        </Flex>
        <Flex vertical gap={16}>
          <h2>Bước tiếp theo</h2>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Flex
                justify="center"
                vertical
                className="navigate-tab"
                onClick={() => navigate(`/quizzes/${id}/learn`)}
              >
                <Flex gap={8} align="center">
                  <div className="icon">
                    <Icon type="learn" />
                  </div>
                  <div className="content">
                    <p>Học các thuật ngữ này</p>
                    <p>
                      {`Trả lời các câu hỏi về ${listFlashcard.length} thuật ngữ này để xây dựng kiến
                thức.`}
                    </p>
                  </div>
                  <RightOutlined />
                </Flex>
              </Flex>
            </Col>
            <Col xs={24} md={12}>
              <Flex
                justify="center"
                vertical
                className="navigate-tab"
                onClick={() => resetCard()}
              >
                <Flex gap={8} align="center">
                  <div className="icon">
                    <Icon type="flashcard" />
                  </div>
                  <div className="content">
                    <p>Đặt lại Thẻ ghi nhớ</p>
                    <p>
                      {`Học lại tất cả ${listFlashcard.length} thuật ngữ từ đầu.`}
                    </p>
                  </div>
                  <RightOutlined />
                </Flex>
              </Flex>
            </Col>
          </Row>
          {/* <Flex
            align="center"
            gap={8}
            className="return"
            onClick={() => returnToLastCard()}
          >
            <ArrowLeftOutlined />
            <p>Quay lại câu hỏi cuối cùng</p>
          </Flex> */}
        </Flex>
      </Flex>
    </div>
  );
};

export default memo(FinishReviewCards);

import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Empty,
  Flex,
  Input,
  Pagination,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import BaseImage from "components/BaseImage";
import ClassCard from "components/ClassCard";
import FolderCard from "components/FolderCard";
import QuizCard from "components/QuizCard";
import { LevelEnum } from "constant/quiz";
import { useAccount } from "hook/useAccount";
import { useClass } from "hook/useClass";
import { useFolder } from "hook/useFolder";
import { useQuiz } from "hook/useQuiz";
import { useChat } from "hook/useReview copy";
import { useSubject } from "hook/useSubject";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import { getUserInfo } from "utils/localStorage";

const { Option } = Select;

const allSubject = { value: "", label: "Tất cả chủ đề" };
const allLevel = { value: "", label: "Tất cả cấp độ" };

enum Tab {
  Quiz = "2",
  Folder = "3",
  Class = "4",
}

const User = () => {
  const { id } = useParams();
  const account = useAccount();
  const chat = useChat();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState<User.UpdateInfoResponse>();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(Tab.Quiz);

  const tabItems: TabsProps["items"] = [
    { key: Tab.Quiz, label: "Học phần", children: <Quiz /> },
    { key: Tab.Folder, label: "Thư mục", children: <Folder /> },
    { key: Tab.Class, label: "Lớp học", children: <Class /> },
  ];

  useEffect(() => {
    if (id) {
      getUserById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getUserById = async (id: string): Promise<void> => {
    if (!id) return;
    setLoading(true);

    account.getProfileById({
      id,
      callback: {
        onSuccess: (res: User.UpdateInfoResponse) => {
          setUserInfo(res);
        },
        onFailure: (err: any) => {
          ///
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const createContact = async (): Promise<void> => {
    if (!id) return;
    setSubmitting(true);
    chat.createContact({
      id,
      callback: {
        onSuccess: () => {
          navigate("/chat");
        },
        onFailure: (err: any) => {
          ///
        },
        onFinish: () => {
          setSubmitting(false);
        },
      },
    });
  };

  return (
    <Flex vertical gap={24} className="users">
      <Spin spinning={loading}>
        <Flex align="center" gap={24} wrap>
          <BaseImage
            className="user-avatar"
            src={userInfo?.avatar}
            alt="avatar"
          />
          <div>
            <span className="username">{userInfo?.name}</span>
            {getUserInfo()?._id && getUserInfo()?._id !== id && (
              <Button
                loading={submitting}
                type="primary"
                style={{ marginTop: 10 }}
                onClick={createContact}
              >
                Nhắn tin
              </Button>
            )}
          </div>
        </Flex>
      </Spin>

      <Tabs
        size="large"
        activeKey={activeTab}
        items={tabItems}
        onChange={(key: string) => setActiveTab(key)}
      />
    </Flex>
  );
};

export default User;

const Quiz = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const subject = useSubject();
  const quiz = useQuiz();
  const [loading, setLoading] = useState(false);
  const [loadingSubject, setLoadingSubject] = useState(false);
  const [subjectList, setSubjectList] = useState<SelectProps["options"]>([]);
  const [selectedSubject, setSelectedSubject] = useState<string>("");
  const [selectedLevel, setSelectedLevel] = useState<LevelEnum | "">("");
  const [searchValue, setSearchValue] = useState("");
  const [quizResult, setQuizResult] =
    useState<Quiz.GetMyCreatedQuizPaginationResponse>();

  useEffect(() => {
    if (id) {
      getSubjectList();
      getQuizResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject, selectedLevel, id]);

  const getSubjectList = async (): Promise<void> => {
    setLoadingSubject(true);

    subject.getSubjectList({
      callback: {
        onSuccess: (res: Subject.Value[]) => {
          const options = res.map((item) => {
            return {
              label: item.subject_name,
              value: item._id,
            };
          });
          options.push(allSubject);
          setSubjectList(options);
        },
        onFailure: (err: any) => {
          setSubjectList([]);
        },
        onFinish: () => {
          setLoadingSubject(false);
        },
      },
    });
  };

  const getQuizResult = async (): Promise<void> => {
    if (!id) return;
    setLoading(true);

    quiz.getQuizInfoByAccountId({
      id: id,
      params: {
        page: 1,
        limit: 10,
        title: searchValue,
        subject: selectedSubject,
        level: selectedLevel,
      },
      callback: {
        onSuccess: (res: Quiz.GetMyCreatedQuizPaginationResponse) => {
          setQuizResult(res);
        },
        onFailure: (err: any) => {
          ///
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <Flex vertical gap="large">
      <Flex vertical gap="large">
        <Typography.Title level={4}>Bộ lọc</Typography.Title>
        <Flex gap="large" wrap align="center">
          <Space.Compact className="search-bar" style={{ width: "100%" }}>
            <Input
              prefix={<SearchOutlined />}
              className="search-input"
              placeholder="Tìm kiếm ..."
              value={searchValue}
              onChange={(e: any) => setSearchValue(e.target.value)}
            />
            <Button type="primary" className="btn-find" onClick={getQuizResult}>
              Tìm kiếm
            </Button>
          </Space.Compact>
        </Flex>
        <Flex gap="large" wrap>
          <div>
            <p>Chủ đề</p>
            <Select
              loading={loadingSubject}
              className="select-subject"
              options={subjectList}
              value={selectedSubject}
              style={{ width: "200px" }}
              onChange={(value: string) => setSelectedSubject(value)}
            />
          </div>

          <div>
            <p>Cấp độ</p>
            <Select
              loading={loadingSubject}
              className="select-subject"
              value={selectedLevel}
              style={{ width: "200px" }}
              onChange={(value: LevelEnum | "") => setSelectedLevel(value)}
            >
              <Option value={LevelEnum.Beginner}>Dễ</Option>
              <Option value={LevelEnum.Intermediate}>Trung bình</Option>
              <Option value={LevelEnum.Advanced}>Khó</Option>
              <Option value={allLevel.value}>Tất cả cấp độ</Option>
            </Select>
          </div>
        </Flex>
      </Flex>
      <Spin spinning={loading}>
        {quizResult && (
          <div>
            <Typography.Title level={4}>Học phần</Typography.Title>
            {quizResult?.quizzes.length > 0 ? (
              <>
                <Row style={{ paddingTop: "1rem" }} gutter={[24, 24]}>
                  {quizResult.quizzes.map((item) => {
                    return (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        key={item._id}
                        onClick={() => navigate(`/quizzes/${item._id}`)}
                      >
                        <QuizCard quizInfo={item} key={item._id} />
                      </Col>
                    );
                  })}
                </Row>
                <Flex justify="end" className="pagination">
                  <Pagination total={quizResult?.count || 0} showQuickJumper />
                </Flex>
              </>
            ) : (
              <Empty description="Không có kết quả phù hợp" />
            )}
          </div>
        )}
      </Spin>
    </Flex>
  );
};

const Class = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const classroom = useClass();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [classResult, setClassResult] =
    useState<Class.ClassesPaginationResponse>();

  useEffect(() => {
    if (id) {
      getClassResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getClassResult = async (): Promise<void> => {
    if (!id) return;
    setLoading(true);

    classroom.getClassByAccountId({
      id,
      params: {
        page: 1,
        limit: 10,
        classroom_name: searchValue,
      },
      callback: {
        onSuccess: (res: Class.ClassesPaginationResponse) => {
          setClassResult(res);
        },
        onFailure: (err: any) => {
          ///
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <Flex vertical gap="large">
      <Typography.Title level={4}>Bộ lọc</Typography.Title>
      <Flex gap="large" wrap align="center">
        <Space.Compact className="search-bar" style={{ width: "100%" }}>
          <Input
            prefix={<SearchOutlined />}
            className="search-input"
            placeholder="Tìm kiếm ..."
            value={searchValue}
            onChange={(e: any) => setSearchValue(e.target.value)}
          />
          <Button type="primary" className="btn-find" onClick={getClassResult}>
            Tìm kiếm
          </Button>
        </Space.Compact>
      </Flex>
      <Spin spinning={loading}>
        {classResult && (
          <div>
            <Typography.Title level={4}>Lớp học</Typography.Title>
            {classResult?.classrooms?.length > 0 ? (
              <>
                <Row style={{ paddingTop: "1rem" }} gutter={[24, 24]}>
                  {classResult?.classrooms.map((item) => {
                    return (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        key={item._id}
                        onClick={() => navigate(`/classes/${item._id}`)}
                      >
                        <ClassCard classInfo={item} key={item._id} />
                      </Col>
                    );
                  })}
                </Row>
                <Flex justify="end" className="pagination">
                  <Pagination total={classResult?.count || 0} showQuickJumper />
                </Flex>
              </>
            ) : (
              <Empty description="Không có kết quả phù hợp" />
            )}
          </div>
        )}
      </Spin>
    </Flex>
  );
};

const Folder = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const folder = useFolder();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [folderResult, setFolderResult] =
    useState<Folder.MyFoldersPaginationResponse>();

  useEffect(() => {
    if (id) {
      getFolderResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getFolderResult = async (): Promise<void> => {
    if (!id) return;
    setLoading(true);

    folder.getFolderByAccountId({
      id,
      params: {
        page: 1,
        limit: 10,
        folder_name: searchValue,
      },
      callback: {
        onSuccess: (res: Folder.MyFoldersPaginationResponse) => {
          setFolderResult(res);
        },
        onFailure: (err: any) => {
          ///
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <Flex vertical gap="large">
      <Typography.Title level={4}>Bộ lọc</Typography.Title>
      <Flex gap="large" wrap align="center">
        <Space.Compact className="search-bar" style={{ width: "100%" }}>
          <Input
            prefix={<SearchOutlined />}
            className="search-input"
            placeholder="Tìm kiếm ..."
            value={searchValue}
            onChange={(e: any) => setSearchValue(e.target.value)}
          />
          <Button type="primary" className="btn-find" onClick={getFolderResult}>
            Tìm kiếm
          </Button>
        </Space.Compact>
      </Flex>
      <Spin spinning={loading}>
        {folderResult && (
          <div>
            <Typography.Title level={4}>Thư mục</Typography.Title>
            {folderResult?.folders?.length > 0 ? (
              <>
                <Row style={{ paddingTop: "1rem" }} gutter={[24, 24]}>
                  {folderResult?.folders.map((item) => {
                    return (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        key={item._id}
                        onClick={() => navigate(`/folders/${item._id}`)}
                      >
                        <FolderCard folderInfo={item} key={item._id} />
                      </Col>
                    );
                  })}
                </Row>
                <Flex justify="end" className="pagination">
                  <Pagination
                    total={folderResult?.count || 0}
                    showQuickJumper
                  />
                </Flex>
              </>
            ) : (
              <Empty description="Không có kết quả phù hợp" />
            )}
          </div>
        )}
      </Spin>
    </Flex>
  );
};

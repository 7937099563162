import { Flex, Tag } from "antd";
import BaseImage from "components/BaseImage";
import { listLevel } from "constant/quiz";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const getLevel = (level: number) => {
  return listLevel.find((item) => item.value === level);
};

const QuizCard = ({
  quizInfo,
  children,
}: {
  quizInfo: Quiz.FullQuizInfo;
  children?: React.ReactNode;
}) => {
  const level = getLevel(quizInfo?.level);
  const navigate = useNavigate();

  return (
    <Flex vertical justify="space-between" className="quiz-card" gap="small">
      <Flex vertical gap="small">
        <span className="name">{quizInfo?.title}</span>
        <Flex gap="small">
          <Tag color="#55acee" style={{ width: "fit-content" }}>
            {`${quizInfo.question_count} thuật ngữ`}
          </Tag>
          {level && (
            <Tag color={level.color} style={{ width: "fit-content" }}>
              {level.label}
            </Tag>
          )}
        </Flex>
        <Tag color="blue" style={{ width: "fit-content" }}>
          {quizInfo.subject.subject_name}
        </Tag>
      </Flex>
      {children && (
        <Flex gap="small" justify="space-between" align="center" wrap>
          <Flex
            gap="small"
            align="center"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/users/${quizInfo?.creator?._id}`);
            }}
          >
            <BaseImage
              className="avatar"
              src={quizInfo?.creator?.avatar}
              width={32}
              height={32}
            />
            <span>{quizInfo?.creator?.name || "user"}</span>
          </Flex>
          {children}
        </Flex>
      )}
    </Flex>
  );
};

export default QuizCard;

import { useCertificateAPI } from "api/useCertificateAPI";
import { get, noop } from "lodash";

export const useCertificate = () => {
  const certificateAPI = useCertificateAPI();

  const createCertificate = async ({
    params,
    callback,
  }: {
    params: Certificate.Create;
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await certificateAPI.createCertificate(params);

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  const getMyCertificateList = async ({
    callback,
  }: {
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await certificateAPI.getMyCertificateList();

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  const updateCertificate = async ({
    params,
    callback,
  }: {
    params: Certificate.Create;
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await certificateAPI.updateCertificate(params);

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  return { createCertificate, getMyCertificateList, updateCertificate };
};

import { Button, Flex, Modal, message } from "antd";
import { FacebookIcon, FacebookShareButton } from "react-share";
import "./style.scss";

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  title?: string;
}

const ShareModal = (props: Props) => {
  const { isOpen, handleCancel, title = "Chia sẻ" } = props;
  const [messageApi, contextHolder] = message.useMessage();

  const onClickCopy = () => {
    messageApi.open({
      type: "success",
      content: "Đã sao chép",
    });

    navigator.clipboard.writeText(window.location.href);
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={<h2>{title}</h2>}
        open={isOpen}
        className="share-modal"
        width={700}
        footer={false}
        onCancel={handleCancel}
        style={{ top: 20 }}
      >
        <Flex vertical gap="large">
          <Flex gap="large" wrap justify="space-between" align="center">
            <div className="content" onClick={onClickCopy}>
              <p>URL</p>
              <span>{window.location.href}</span>
            </div>
            <Button size="large" type="primary" onClick={onClickCopy}>
              Chép liên kết
            </Button>
          </Flex>
          <FacebookShareButton url={window.location.href}>
            <Button
              icon={<FacebookIcon size={32} round />}
              className="btn-border-gray btn-fb-share"
              block
            >
              Chia sẻ trên Facebook
            </Button>
          </FacebookShareButton>
        </Flex>
      </Modal>
    </>
  );
};

export default ShareModal;

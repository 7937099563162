import { CheckCircleFilled, StarFilled } from "@ant-design/icons";
import { Button, Flex, Spin, Tooltip } from "antd";
import FlashcardModeComponent from "components/FlashcardModeComponent";
import Icon from "components/Icon";
import { useQuestion } from "hook/useQuestion";
import { useQuiz } from "hook/useQuiz";
import { QuizInfoPageContext } from "provider/QuizInfoPageProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";
import InfoBar from "./InfoBar";
import QuestionsOfQuiz from "./QuestionsOfQuiz";
import "./style.scss";

const QuizInfo = () => {
  const navigate = useNavigate();
  const question = useQuestion();
  const quiz = useQuiz();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [quizInfo, setQuizInfo] = useState<Quiz.FullQuizInfo>();
  const { listQuestion, setListQuestion } = useContext(QuizInfoPageContext);

  useEffect(() => {
    getQuizInfo();
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getQuizInfo = () => {
    if (!id) return;
    setLoading(true);

    quiz.getQuizInfoById({
      id: id,
      callback: {
        onSuccess: (res: Quiz.FullQuizInfo) => {
          setQuizInfo(res);
        },
        onFailure: (err: any) => {},
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getData = () => {
    if (getUserInfo()?._id) {
      getQuestionsAnswerStarOfQuiz();
    } else {
      getQuestionsAnswerOfQuiz();
    }
  };

  const getQuestionsAnswerOfQuiz = () => {
    if (!id) return;
    setLoading(true);
    question.getQuestionsAnswerOfQuiz({
      id,
      callback: {
        onSuccess: (res: Question.Value[]) => {
          setListQuestion(res);
        },
        onFailure: (err: any) => {},
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getQuestionsAnswerStarOfQuiz = () => {
    if (!id) return;
    setLoading(true);
    question.getQuestionsAnswerStarOfQuiz({
      id,
      callback: {
        onSuccess: (res: Question.Value[]) => {
          setListQuestion(res);
        },
        onFailure: (err: any) => {},
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <div className="quiz-info-page">
      <Spin spinning={loading}>
        <Flex vertical gap="large">
          <h1>
            <Flex align="center">
              {quizInfo?.title}
              {quizInfo?.verified && quizInfo?.is_public ? (
                <Tooltip title={"Học phần public"}>
                  <CheckCircleFilled
                    style={{
                      color: "#52c41a",
                      paddingLeft: "8px",
                      fontSize: 14,
                    }}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </Flex>
            {quizInfo?.review_count ? (
              <Flex align="small" className="review-star">
                <StarFilled
                  style={{
                    color: "#ffcd1f",
                    paddingRight: 8,
                    fontSize: 14,
                  }}
                />
                <span>{`${quizInfo?.review_average} (${quizInfo?.review_count}) đánh giá`}</span>
              </Flex>
            ) : (
              <></>
            )}
          </h1>
          <Flex align="center" gap="large" wrap="wrap" className="btn-type">
            <Button
              icon={<Icon type="flashcard" />}
              onClick={() => navigate(`/quizzes/${id}/flashcard`)}
            >
              Thẻ ghi nhớ
            </Button>
            <Button
              icon={<Icon type="learn" />}
              onClick={() => navigate(`/quizzes/${id}/learn`)}
            >
              Học
            </Button>
            <Button
              icon={<Icon type="test" />}
              onClick={() => navigate(`/quizzes/${id}/test`)}
            >
              Kiểm tra
            </Button>
          </Flex>
          <FlashcardModeComponent
            quizId={id || ""}
            questionList={listQuestion}
          />
          {quizInfo && (
            <InfoBar
              user={quizInfo?.creator}
              createAt={quizInfo?.createdAt || ""}
            />
          )}
          <QuestionsOfQuiz
            quizId={id || ""}
            creatorId={quizInfo?.creator?._id || ""}
            quizName={quizInfo?.title || ""}
          />
        </Flex>
      </Spin>
    </div>
  );
};

export default QuizInfo;

import { useHttpRequest } from "./useHttpRequest";

export const useStarAPI = () => {
  const httpRequest = useHttpRequest();

  const starQuestion = async (
    question: string
  ): Promise<Star.StarQuestionResponse> =>
    httpRequest.POST("/stars", { question });

  const toggleStarQuestion = async (
    question: string
  ): Promise<Star.StarQuestionResponse> =>
    httpRequest.POST("/stars/toggle", { question });

  const getStarQuestionOfQuiz = async (
    id: string
  ): Promise<Quiz.GetMyCreatedQuizPaginationResponse> =>
    httpRequest.GET(`/stars/by-quiz/${id}`);

  return { starQuestion, getStarQuestionOfQuiz, toggleStarQuestion };
};

import { Flex, Upload, UploadFile, UploadProps, message } from "antd";
import { useAccount } from "hook/useAccount";
import { NotificationContext } from "provider/NotificationProvider";
import { UserInfoContext } from "provider/UserInfoProvider";
import { memo, useContext, useEffect, useState } from "react";
import { setUserInfo as setUserLocalStorageInfo } from "utils/localStorage";
import "./style.scss";

const AvatarContainer = () => {
  const account = useAccount();
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const { openNotification } = useContext(NotificationContext);
  const [firstLoading, setFirstLoading] = useState(true);

  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: userInfo?.avatar || "/images/default-avatar.svg",
    },
  ]);

  useEffect(() => {
    if (firstLoading === false && fileList[0]?.response?.url) {
      updateUserInfo(fileList[0].url || fileList[0]?.response?.url);
    }

    setFirstLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    setFileList(newFileList);
    setUserLocalStorageInfo({
      ...userInfo!,
      avatar: newFileList[0]?.response?.url,
    });
    setUserInfo({ ...userInfo!, avatar: newFileList[0]?.response?.url });
  };

  const updateUserInfo = async (avatar: string) => {
    await account.updateUserInfo({
      params: {
        avatar: avatar,
      },
      callback: {
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
      },
    });
  };

  return (
    <Flex vertical gap="1rem" className="image">
      <span className="title">Ảnh hồ sơ</span>
      <Flex align="center" gap="1rem">
        <Upload
          action={`${process.env.REACT_APP_API_URL}/medias/upload`}
          listType="picture-card"
          maxCount={1}
          beforeUpload={(file) => {
            const isPicture =
              file.type === "image/png" ||
              file.type === "image/jpeg" ||
              file.type === "image/svg+xml";
            if (!isPicture) {
              message.error(
                `Tệp ${file.name} ${file.type} không phải tệp png/jpg.`
              );
            }
            return isPicture || Upload.LIST_IGNORE;
          }}
          fileList={fileList}
          onChange={handleChange}
        >
          + Đổi ảnh
        </Upload>
      </Flex>
    </Flex>
  );
};

export default memo(AvatarContainer);

import { useHttpRequest } from "./useHttpRequest";

export const useRequestAPI = () => {
  const httpRequest = useHttpRequest();

  const getAllReceivedRequest = async (): Promise<Request.FullDetails[]> =>
    httpRequest.GET("/requests/received");

  const getAllSentRequest = async (): Promise<Request.FullDetails[]> =>
    httpRequest.GET("/requests/sent");

  const updateRequest = async (id: string, status: number): Promise<any> =>
    httpRequest.PATCH(`/requests/${id}`, { status });

  return {
    getAllReceivedRequest,
    updateRequest,
    getAllSentRequest,
  };
};

import { get, noop } from "lodash";
import { useRequestAPI } from "api/useRequestAPI";

export const useRequest = () => {
  const requestAPI = useRequestAPI();

  const getAllReceivedRequest = async ({
    callback,
  }: {
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await requestAPI.getAllReceivedRequest();

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  const getAllSentRequest = async ({
    callback,
  }: {
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await requestAPI.getAllSentRequest();

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  const updateRequest = async ({
    id,
    status,
    callback,
  }: {
    id: string;
    status: number;
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await requestAPI.updateRequest(id, status);

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  return {
    getAllReceivedRequest,
    getAllSentRequest,
    updateRequest,
  };
};

const SYSTEM_NAME = "PIKI";

class CacheKey {
  static TOKEN = `${SYSTEM_NAME}_token`;
  // static REFRESH_TOKEN = `${SYSTEM_NAME}_refreshToken`;
  static USER_INFO = `${SYSTEM_NAME}_userInfo`;
  static CURRENT_QUESTION = `${SYSTEM_NAME}_current_question`;
  static NEARLY_QUIZ = `${SYSTEM_NAME}_nearly_quiz`;
  static NEARLY_USER = `${SYSTEM_NAME}_nearly_user`;
  // static REFRESH_TIME = `${SYSTEM_NAME}_refreshTime`;
}

export default CacheKey;

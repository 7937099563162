import { Empty, Flex, Rate, Table, TableProps } from "antd";
import BaseImage from "components/BaseImage";
import moment from "moment";
import { Link } from "react-router-dom";
import "./style.scss";

interface Props {
  loading: boolean;
  listReview: Review.Value[];
}

const Review = (props: Props) => {
  const { loading, listReview } = props;

  const columns: TableProps<Review.Value>["columns"] = [
    {
      key: "classroom",
      render: (_: any, item: Review.Value) => (
        <div className="review-container">
          <Flex gap="small" align="center" wrap>
            <BaseImage
              src={item?.account?.avatar}
              className="avatar"
              width={50}
              height={50}
            />
            <Flex vertical>
              <Link to={`/users/${item?.account?._id}`} className="user-name">
                {item?.account?.name}
              </Link>
              <span className="time">
                {moment(item?.createdAt).format("DD/MM/YYY hh:mm")}
              </span>
              <span>
                <Rate disabled value={item?.rating} style={{ fontSize: 12 }} />
              </span>
            </Flex>
          </Flex>
          <p style={{ marginBottom: 0 }}>{item?.text}</p>
        </div>
      ),
    },
  ];

  return (
    <div className="review-page">
      <Table
        dataSource={listReview}
        columns={columns}
        loading={loading}
        rowKey={(record) => record._id}
        showHeader={false}
        rowHoverable={false}
        locale={{ emptyText: <Empty description="Dữ liệu rỗng" /> }}
      />
    </div>
  );
};

export default Review;

import {
  IssuesCloseOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Flex, MenuProps } from "antd";
import { useAuth } from "hook/useAuth";
import { UserInfoContext } from "provider/UserInfoProvider";
import { useContext } from "react";
import { Link } from "react-router-dom";

const AvatarDropdown = () => {
  const auth = useAuth();
  const { userInfo } = useContext(UserInfoContext);

  const getAvatar = () => {
    return (
      <div>
        <Avatar
          src={userInfo?.avatar || "/images/default-avatar.svg"}
          size="large"
          className="avatar-circle"
        />
      </div>
    );
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <div className="user-dropdown-item">
          <Flex align="center" gap={16}>
            {getAvatar()}
            <Flex vertical gap={3}>
              <div className="user-name">{userInfo?.name}</div>
              <div className="user-email">{userInfo?.email}</div>
            </Flex>
          </Flex>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <Link to="/setting" className="add-dropdown-item">
          <Flex align="center" gap={16}>
            <SettingOutlined />
            <span>Cài đặt</span>
          </Flex>
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link to="/my-request" className="add-dropdown-item">
          <Flex align="center" gap={16}>
            <IssuesCloseOutlined />
            <span>Yêu cầu tham gia lớp học</span>
          </Flex>
        </Link>
      ),
      key: "2",
    },
    // {
    //   label: (
    //     <Link to="#" className="add-dropdown-item">
    //       <Flex align="center" gap={16}>
    //         <FireOutlined />
    //         <span>Thành tựu</span>
    //       </Flex>
    //     </Link>
    //   ),
    //   key: "1",
    // },
    {
      label: (
        <div className="add-dropdown-item" onClick={() => auth.logout()}>
          <Flex align="center" gap={16}>
            <LogoutOutlined />
            <span>Đăng xuất</span>
          </Flex>
        </div>
      ),
      key: "3",
    },
  ];
  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement="bottomRight"
      arrow
    >
      {getAvatar()}
    </Dropdown>
  );
};

export default AvatarDropdown;

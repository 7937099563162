import { RightOutlined } from "@ant-design/icons";
import {
  Col,
  Empty,
  Flex,
  Progress,
  Row,
  Table,
  TableProps,
  Tabs,
  Tag,
} from "antd";
import firework from "assets/json/firework.json";
import Icon from "components/Icon";
import { useQuiz } from "hook/useQuiz";
import moment from "moment";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useNavigate, useParams } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";
import ResultCard from "./ResultCard";
import "./style.scss";

const TestResult = (props: { testResult?: Test.Result }) => {
  const { id } = useParams();
  const quiz = useQuiz();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [testHistory, setTestHistory] = useState<Quiz.History[]>([]);
  const testResult = props?.testResult;

  const columns: TableProps<Quiz.History>["columns"] = [
    {
      title: "STT",
      dataIndex: "_id",
      key: "index",
      align: "center",
      render: (_: any, record: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Học phần",
      dataIndex: "quiz",
      key: "1",
      render: () => <>{testResult?.quizName}</>,
    },
    {
      title: "Điểm",
      dataIndex: "score",
      key: "2",
      align: "center",
    },
    // {
    //   title: "Thời gian làm bài",
    //   dataIndex: "time",
    //   key: "3",
    //   align: "center",
    // },
    {
      title: "Ngày kiểm tra",
      dataIndex: "date",
      key: "4",
      align: "center",
      render: (date: string) => <>{moment(date).format("DD/MM/YYYY HH:mm")}</>,
    },
  ];

  const getItem = () => {
    const items = [
      {
        key: "1",
        label: "Đáp án của bạn",
        children: (
          <Flex vertical gap="small">
            {testResult?.details?.map((item, index) => {
              return <ResultCard result={item} key={index} />;
            })}
          </Flex>
        ),
      },
    ];

    const historyItem = {
      key: "2",
      label: "Lịch sử làm bài",
      children: (
        <Table
          loading={loading}
          columns={columns}
          dataSource={testHistory}
          rowKey={(record) => record._id}
          showSorterTooltip={false}
          locale={{ emptyText: <Empty description="Dữ liệu rỗng" /> }}
        />
      ),
    };

    if (getUserInfo()?._id) {
      items.push(historyItem);
    }

    return items;
  };

  useEffect(() => {
    if (getUserInfo()?._id) {
      getQuizHistory();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuizHistory = () => {
    if (!id) return;
    setLoading(true);
    quiz.getQuizHistory({
      id,
      callback: {
        onSuccess: (res: any) => {
          setTestHistory(res);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <>
      {testResult && (
        <div className="test-result">
          <div className="firework">
            <Lottie
              options={{
                loop: 1,
                autoplay: true,
                animationData: firework,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              speed={0.5}
              isPaused={false}
            />
          </div>

          <Flex vertical gap="1.5rem">
            <h1>Chúc mừng! Bạn đã hoàn thành bài kiểm tra.</h1>
            <Row gutter={[24, 24]}>
              <Col xs={24} md={12}>
                <Flex gap="large" vertical>
                  <h2> </h2>
                  <Flex gap={36}>
                    <Progress
                      type="circle"
                      percent={Number(
                        (
                          (testResult.rightNumber /
                            testResult.numberOfQuestion) *
                          100
                        ).toFixed(2)
                      )}
                      strokeColor="#59e8b5"
                      trailColor="#ff983a"
                      strokeWidth={10}
                    />
                    <div className="count-answer">
                      <Flex gap="large">
                        <span>Đúng</span>
                        <span>{testResult.rightNumber}</span>
                      </Flex>
                      <Flex gap="large">
                        <span>Sai</span>
                        <span>{testResult.wrongNumber}</span>
                      </Flex>
                    </div>
                  </Flex>
                </Flex>
              </Col>
              <Col xs={24} md={12}>
                <Flex gap="large" vertical>
                  <h2>Bước tiếp theo</h2>
                  <Flex
                    justify="center"
                    vertical
                    className="navigate-tab"
                    onClick={() => navigate(`/quizzes/${id}/learn`)}
                  >
                    <Flex gap={8} align="center">
                      <div className="icon">
                        <Icon type="learn" />
                      </div>
                      <div className="content">
                        {testResult.wrongNumber > 0 && (
                          <Tag color="red">{`${testResult.wrongNumber} thuật ngữ sai`}</Tag>
                        )}
                        <p>Ôn luyện thuật ngữ trong chế độ Học</p>
                        <p>
                          {testResult.wrongNumber > 0
                            ? "Ôn luyện các thuật ngữ bạn bỏ lỡ cho đến khi bạn nắm chắc"
                            : "NÔn luyện các thuật ngữ để cải thiện khả năng ghi nhớ."}
                        </p>
                      </div>
                      <RightOutlined />
                    </Flex>
                  </Flex>
                  <Flex
                    justify="center"
                    vertical
                    className="navigate-tab"
                    onClick={() => {
                      testResult.wrongNumber > 0
                        ? window.location.reload()
                        : navigate(`/quizzes/${id}/flashcard`);
                    }}
                  >
                    <Flex gap={8} align="center">
                      <div className="icon">
                        <Icon type="test" />
                      </div>
                      <div className="content">
                        {testResult.wrongNumber > 0 ? (
                          <>
                            <p>Làm bài kiểm tra mới</p>
                            <p>Tự kiểm tra lại những thuật ngữ bạn nhớ sai.</p>
                          </>
                        ) : (
                          <>
                            {" "}
                            <p>Ôn tập bằng Thẻ ghi nhớ</p>
                            <p>
                              Nghiên cứu thuật ngữ dưới dạng thẻ để cải thiện
                              khả năng ghi nhớ.
                            </p>
                          </>
                        )}
                      </div>
                      <RightOutlined />
                    </Flex>
                  </Flex>
                </Flex>
              </Col>
            </Row>
          </Flex>

          <Flex vertical gap="1.5rem">
            <Tabs size="large" defaultActiveKey="1" items={getItem()} />
          </Flex>
        </div>
      )}
    </>
  );
};

export default TestResult;

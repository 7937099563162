import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Select,
  SelectProps,
  Spin,
  Switch,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import AddFromCreatedQuizzesModal from "components/AddFromCreatedQuizzesModal";
import { LevelEnum } from "constant/quiz";
import { useQuestion } from "hook/useQuestion";
import { useQuiz } from "hook/useQuiz";
import { useSubject } from "hook/useSubject";
import { NotificationContext } from "provider/NotificationProvider";
import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./style.scss";

const { Option } = Select;

const EditQuizInfo = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const subject = useSubject();
  const quiz = useQuiz();
  const question = useQuestion();
  const [loading, setLoading] = useState(false);
  const [loadingSubject, setLoadingSubject] = useState(false);
  const [quizInfoForm] = Form.useForm();
  const [questionForm] = Form.useForm();
  const [subjectList, setSubjectList] = useState<SelectProps["options"]>([]);
  const { openNotification } = useContext(NotificationContext);

  const [openAddForm, setOpenAddForm] = useState(false);
  const [selectedQuestionList, setSelectedQuestionList] = useState<
    Question.Value[]
  >([]);

  useEffect(() => {
    if (selectedQuestionList?.length !== 0) {
      const selectedList = selectedQuestionList.map((item) => {
        return {
          text: item.text,
          answers: item.answers.map((answer) => {
            return {
              text: answer.text,
              is_correct: answer.is_correct,
            };
          }),
        };
      });
      const list = questionForm.getFieldValue("questions");
      questionForm.setFieldValue("questions", [...selectedList, ...list]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestionList]);

  useEffect(() => {
    getSubjectList();
    getQuizInfo();
    getListQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getQuizInfo = () => {
    if (!id) return;
    setLoading(true);

    quiz.getQuizInfoById({
      id: id,
      callback: {
        onSuccess: (res: Quiz.FullQuizInfo) => {
          quizInfoForm.setFieldsValue({
            title: res.title,
            description: res.description,
            duration: res.duration,
            level: res.level,
            is_public: res.is_public,
            subject: res.subject._id,
          });
        },
        onFailure: (err: any) => {},
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getListQuestion = () => {
    if (!id) return;
    setLoading(true);

    question.getQuestionsAnswerOfQuiz({
      id: id,
      callback: {
        onSuccess: (res: Question.Value[]) => {
          const questions = res.map((item: Question.Value) => {
            return {
              _id: item._id,
              answers: item.answers,
              text: item.text,
            };
          });
          questionForm.setFieldsValue({
            quiz: id,
            questions,
          });
        },
        onFailure: (err: any) => {},
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getSubjectList = async (): Promise<void> => {
    setLoadingSubject(true);

    subject.getSubjectList({
      callback: {
        onSuccess: (res: Subject.Value[]) => {
          setSubjectList(
            res.map((item) => {
              return {
                label: item.subject_name,
                value: item._id,
              };
            })
          );
        },
        onFailure: (err: any) => {
          setSubjectList([]);
        },
        onFinish: () => {
          setLoadingSubject(false);
        },
      },
    });
  };

  const submit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    Promise.all([quizInfoForm.validateFields(), questionForm.validateFields()])
      .then(() => {
        editQuiz();
        updateListQuestionsOfQuiz();
      })
      .catch(() => {});
  };

  const editQuiz = () => {
    if (!id) return;
    setLoading(true);

    quiz.updateQuiz({
      id,
      params: { ...quizInfoForm.getFieldsValue(true), quiz: id },
      callback: {
        onSuccess: (res: Quiz.NewQuizSetResponse) => {
          openNotification("Chỉnh sửa thông tin thành công !", "");
          if (res.is_public === true && res?.verified === false) {
            openNotification(
              "Tải chứng chỉ",
              `Bạn chưa tải chứng chỉ cho chủ đề
                ${
                  subjectList?.find(
                    (item) =>
                      item?.value === quizInfoForm.getFieldValue("subject")
                  )?.label || ""
                }.\nHãy tải chứng chỉ để công khai học phần này!`,
              "warning",
              10,
              (api) => (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    api.destroy();
                    navigate("/setting");
                  }}
                >
                  Thêm chứng chỉ
                </Button>
              )
            );
          }
        },
        onFailure: (err: any) => {
          setSubjectList([]);
          setLoading(false);
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
      },
    });
  };

  const updateListQuestionsOfQuiz = () => {
    if (!id) return;
    setLoading(true);

    question.updateListQuestionsOfQuiz({
      params: { ...questionForm.getFieldsValue(true), quiz: id },
      callback: {
        onSuccess: () => {
          navigate(`/quizzes/${id}`);
        },
        onFailure: (err: any) => {
          setSubjectList([]);
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const onDeleteAnswer = (questionIndex: number) => {
    const questionList: Question.Value[] =
      questionForm.getFieldValue("questions");

    if (questionList[questionIndex]?.answers.length === 1) {
      questionList[questionIndex].answers[0].is_correct = true;

      questionForm.setFieldValue("questions", questionList);
    }
  };

  const onChooseAnswer = (
    questionIndex: number,
    answerIndex: number,
    value: boolean
  ) => {
    let questionList: Question.Value[] =
      questionForm.getFieldValue("questions");

    if (
      value &&
      questionList[questionIndex].answers.find(
        (item, index) => item.is_correct === true && answerIndex !== index
      )
    ) {
      questionList[questionIndex].answers = questionList[
        questionIndex
      ].answers.map((item, index) => {
        if (answerIndex === index) return { ...item };
        return {
          ...item,
          is_correct: false,
        };
      });

      questionForm.setFieldValue("questions", questionList);
    }

    questionForm.validateFields([["questions", questionIndex, "answers"]]);
  };

  const validateQuestionList = (questionList: any[]) => {
    const validQuestions = questionList.filter(
      (item) => item?.text !== undefined && item?.answers !== undefined
    );

    return validQuestions.length < 2;
  };

  const deleteAnswer = (
    questionIndex: number,
    shouldDeleteAnswerIndex: number
  ) => {
    const newQuestionList = questionForm.getFieldValue("questions");
    const newQuestion = { ...newQuestionList[questionIndex] };
    const newAnswerList = newQuestion?.answers;
    const answerData = { ...newAnswerList[shouldDeleteAnswerIndex] };
    newAnswerList[shouldDeleteAnswerIndex] = { _id: answerData?._id };
    newQuestion.answers = newAnswerList;
    newQuestionList[questionIndex] = newQuestion;
    questionForm.setFieldValue("questions", newQuestionList);
  };

  return (
    <Flex vertical gap="large" className="create-quiz-set">
      <h1>Chỉnh sửa học phần</h1>
      <Spin spinning={loading}>
        <Flex vertical gap="large">
          <div className="create-quiz-set__base-info">
            <Form
              className="base-form bg-white"
              form={quizInfoForm}
              layout="vertical"
              scrollToFirstError
              autoComplete="off"
            >
              <Form.Item<Quiz.NewQuizSetParams>
                label="Tiêu đề"
                name="title"
                hasFeedback
                rules={[{ required: true, message: "Vui lòng nhập tiêu đề!" }]}
              >
                <Input allowClear placeholder="Nhập tiêu đề" />
              </Form.Item>
              <Form.Item<Quiz.NewQuizSetParams>
                label="Mô tả"
                name="description"
                hasFeedback
              >
                <TextArea
                  placeholder="Thêm tiêu đề..."
                  style={{ height: 120, resize: "none" }}
                />
              </Form.Item>
              <Row gutter={8}>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item<Quiz.NewQuizSetParams>
                    name="is_public"
                    label="Công khai"
                    tooltip="Mọi người có thể tìm kiếm và học học phần này."
                    hasFeedback
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={12} md={6}>
                  <Form.Item<Quiz.NewQuizSetParams>
                    label="Thời gian bài test"
                    name="duration"
                    tooltip="Thời gian làm bài của học phần ở chế độ test."
                    hasFeedback
                  >
                    <InputNumber min={1} suffix="phút" step="1" />
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={12} md={6}>
                  <Form.Item<Quiz.NewQuizSetParams>
                    label="Cấp độ"
                    name="level"
                    hasFeedback
                  >
                    <Select placeholder="Chọn cấp độ">
                      <Option value={LevelEnum.Beginner}>Dễ</Option>
                      <Option value={LevelEnum.Intermediate}>Trung bình</Option>
                      <Option value={LevelEnum.Advanced}>Khó</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item<Quiz.NewQuizSetParams>
                    name="subject"
                    label="Chủ đề"
                    hasFeedback
                    rules={[
                      { required: true, message: "Vui lòng chọn chủ đề!" },
                    ]}
                  >
                    <Select
                      placeholder="Chọn chủ đề"
                      loading={loadingSubject}
                      options={subjectList}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            style={{ margin: "16px 0px", width: "fit-content" }}
            onClick={() => setOpenAddForm(true)}
          >
            Thêm từ học phần đã tạo
          </Button>
          <div className="create-quiz-set__questions">
            <Form
              className="create-quiz-form"
              form={questionForm}
              autoComplete="off"
              layout="vertical"
            >
              <Form.List
                name="questions"
                rules={[
                  {
                    validator: async (_, questions) => {
                      if (!questions || validateQuestionList(questions)) {
                        return Promise.reject(
                          new Error("BẠN CẦN ÍT NHẤT 2 THẺ ĐỂ TẠO HỌC PHẦN.")
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <Flex vertical gap={16}>
                    {fields.map((field) => {
                      const data =
                        questionForm.getFieldValue("questions")[field.name];

                      return (
                        <Fragment key={field.key}>
                          {data?.answers !== undefined ? (
                            <Card
                              size="small"
                              title={`Thẻ ${field.key + 1}`}
                              extra={
                                fields.length > 1 ? (
                                  <CloseOutlined
                                    onClick={() => {
                                      if (data?._id) {
                                        const newList =
                                          questionForm.getFieldValue(
                                            "questions"
                                          );
                                        newList[field.name] = {
                                          _id: data?._id,
                                        };
                                        questionForm.setFieldValue(
                                          "questions",
                                          newList
                                        );
                                      } else {
                                        remove(field.name);
                                      }
                                    }}
                                  />
                                ) : null
                              }
                            >
                              <Flex vertical gap={20}>
                                <Form.Item
                                  noStyle
                                  name={[field.name, "text"]}
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Vui lòng nhập câu hỏi/ thuật ngữ!",
                                    },
                                  ]}
                                  validateTrigger={["onChange", "onBlur"]}
                                >
                                  <TextArea
                                    autoSize
                                    placeholder="Nhập câu hỏi/ thuật ngữ"
                                  />
                                </Form.Item>
                                <Form.Item>
                                  <Form.List
                                    name={[field.name, "answers"]}
                                    rules={[
                                      {
                                        validator: async (
                                          _,
                                          answers: Question.Answer[]
                                        ) => {
                                          if (
                                            !answers.find(
                                              (item) => item.is_correct === true
                                            )
                                          ) {
                                            return Promise.reject(
                                              new Error(
                                                "Vui lòng chọn 1 đáp án đúng!"
                                              )
                                            );
                                          }
                                        },
                                      },
                                    ]}
                                  >
                                    {(subFields, subOpt, answerErrors) => (
                                      <div style={{ paddingLeft: "16px" }}>
                                        {subFields.map((subField) => {
                                          const answerData =
                                            questionForm.getFieldValue(
                                              "questions"
                                            )[field.name]?.answers[
                                              subField.name
                                            ];

                                          return (
                                            <Fragment key={subField.key}>
                                              {answerData?.text !== undefined &&
                                              answerData?.is_correct !==
                                                undefined ? (
                                                <Form.Item
                                                  style={{
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  <Flex
                                                    align="center"
                                                    gap="small"
                                                  >
                                                    <Form.Item
                                                      noStyle
                                                      name={[
                                                        subField.name,
                                                        "text",
                                                      ]}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            "Vui lòng nhập đáp án/ định nghĩa!",
                                                        },
                                                      ]}
                                                      validateTrigger={[
                                                        "onChange",
                                                        "onBlur",
                                                      ]}
                                                    >
                                                      <TextArea
                                                        placeholder="Nhập đáp án/ định nghĩa"
                                                        autoSize
                                                      />
                                                    </Form.Item>

                                                    <Form.Item
                                                      noStyle
                                                      name={[
                                                        subField.name,
                                                        "is_correct",
                                                      ]}
                                                    >
                                                      <Switch
                                                        title="Chọn câu trả lời đúng"
                                                        onChange={(
                                                          checked: boolean
                                                        ) =>
                                                          onChooseAnswer(
                                                            field.name,
                                                            subField.name,
                                                            checked
                                                          )
                                                        }
                                                      />
                                                    </Form.Item>
                                                    {subFields.length > 1 && (
                                                      <CloseOutlined
                                                        onClick={() => {
                                                          if (answerData?._id) {
                                                            deleteAnswer(
                                                              field.name,
                                                              subField.name
                                                            );
                                                          } else {
                                                            subOpt.remove(
                                                              subField.name
                                                            );
                                                          }

                                                          onDeleteAnswer(
                                                            field.name
                                                          );
                                                        }}
                                                      />
                                                    )}
                                                  </Flex>
                                                </Form.Item>
                                              ) : (
                                                <></>
                                              )}
                                            </Fragment>
                                          );
                                        })}
                                        <Form.ErrorList
                                          errors={answerErrors?.errors}
                                        />
                                        <Button
                                          onClick={() => {
                                            console.log(
                                              questionForm.getFieldsValue()
                                            );
                                            subOpt.add({
                                              is_correct: false,
                                              text: "",
                                            });
                                          }}
                                        >
                                          + Thêm đáp án/ định nghĩa
                                        </Button>
                                      </div>
                                    )}
                                  </Form.List>
                                </Form.Item>
                              </Flex>
                            </Card>
                          ) : (
                            <></>
                          )}
                        </Fragment>
                      );
                    })}

                    <Form.ErrorList errors={errors} />

                    <Button
                      onClick={() =>
                        add({
                          text: "",
                          answers: [
                            {
                              is_correct: false,
                              text: "",
                            },
                          ],
                        })
                      }
                    >
                      + Thêm thẻ
                    </Button>
                  </Flex>
                )}
              </Form.List>
            </Form>
          </div>
          <Flex align="center" justify="end">
            <Button
              size="large"
              type="primary"
              onClick={(e) => submit(e)}
              loading={loading}
            >
              Hoàn tất
            </Button>
          </Flex>
          <AddFromCreatedQuizzesModal
            isOpen={openAddForm}
            handleCancel={() => {
              setOpenAddForm(false);
            }}
            handleSave={(listQuestions: Question.Value[]) =>
              setSelectedQuestionList(listQuestions)
            }
          />
        </Flex>
      </Spin>
    </Flex>
  );
};

export default EditQuizInfo;

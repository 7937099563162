import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Flex, Tooltip } from "antd";
import BaseImage from "components/BaseImage";
import ShareModal from "components/ShareModal";
import moment from "moment";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";
import MoreDropdown from "./MoreDropdown";
import "./style.scss";
import { useQuiz } from "hook/useQuiz";
import { NotificationContext } from "provider/NotificationProvider";

interface Props {
  user: User.Info;
  createAt: string;
}

const InfoBar = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const quiz = useQuiz();
  const { user, createAt } = props;
  const [isOpenShare, setIsUpdateOpenShare] = useState(false);
  const [loading, setLoading] = useState(false);
  const { openNotification } = useContext(NotificationContext);

  const editQuiz = () => {
    if (!id) return;
    setLoading(true);

    quiz.updateQuiz({
      id,
      params: { is_active: false },
      callback: {
        onSuccess: (res: Quiz.NewQuizSetResponse) => {
          openNotification("Xóa quiz thành công !", "");
          navigate("/my-library");
        },
        onFailure: (err: any) => {
          setLoading(false);
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
      },
    });
  };

  return (
    <Flex justify="space-between" align="center" gap={16} className="info-bar">
      <Flex
        align="center"
        gap={8}
        wrap
        onClick={() => navigate(`/users/${user?._id}`)}
        className="creator"
      >
        <BaseImage src={user?.avatar} alt="avatar" className="avatar" />
        <Flex vertical gap={8}>
          <span className="username">{user.name}</span>
          <span className="time">{`Ngày tạo: ${moment(createAt).format(
            "DD/MM/YYYY"
          )}`}</span>
        </Flex>
      </Flex>
      <Flex gap={8} align="center">
        <Button
          size="large"
          icon={<UploadOutlined />}
          className="btn-border-gray"
          onClick={() => setIsUpdateOpenShare(true)}
        >
          Chia sẻ
        </Button>
        {user._id === getUserInfo()?._id ? (
          <>
            <Button
              size="large"
              icon={<EditOutlined />}
              className="btn-border-gray"
              onClick={() => {
                navigate(`/quizzes/${id}/edit`);
              }}
            >
              Chỉnh sửa
            </Button>
            <Button
              size="large"
              icon={<DeleteOutlined />}
              className="btn-border-gray"
              danger
              loading={loading}
              onClick={() => {
                editQuiz();
              }}
            >
              Xóa
            </Button>
            <MoreDropdown />
          </>
        ) : (
          <Tooltip placement="bottom" title="Lưu và chỉnh sửa">
            <Button
              size="large"
              icon={<CopyOutlined />}
              className="btn-border-gray"
              onClick={() => {
                navigate(`/new-quiz-set?id=${id}`);
              }}
            />
          </Tooltip>
        )}
      </Flex>
      <ShareModal
        isOpen={isOpenShare}
        handleCancel={() => setIsUpdateOpenShare(false)}
        title="Chia sẻ học phần này"
      />
    </Flex>
  );
};

export default InfoBar;

import { Layout } from "antd";
import Header from "components/Header";
import { Outlet } from "react-router-dom";
import SocketLayout from "./SocketLayout";

const { Content } = Layout;

const MainLayout = () => {
  return (
    <SocketLayout>
      <div>
        <Layout
          style={{
            background: "#f6f7fb",
            overflow: "hidden",
          }}
        >
          <Header />
          <Content className="main-content">
            <Outlet />
          </Content>
        </Layout>
      </div>
    </SocketLayout>
  );
};

export default MainLayout;

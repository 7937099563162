import { Flex } from "antd";
import FeatureCards from "components/FeatureCards";
import Features from "./Features";
import "./style.scss";

const Dashboard = () => {
  return (
    <Flex vertical gap="3rem" className="dashboard">
      <Features />
      <FeatureCards />
    </Flex>
  );
};

export default Dashboard;

export enum RoleEnum {
  User = 1,
  Admin = 2,
}

export enum LibraryMode {
  Quiz = "1",
  Folder = "2",
  Class = "3",
}

export const libraryMode = [
  { key: LibraryMode.Quiz, label: "Học phần" },
  { key: LibraryMode.Folder, label: "Thư mục" },
  { key: LibraryMode.Class, label: "Lớp học" },
];

export const sampleAvatarList = [
  "/images/sample-avatar/owl.jpg",
  "/images/sample-avatar/giraffe.jpg",
  "/images/sample-avatar/penguins.jpg",
];

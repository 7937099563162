import { io } from "socket.io-client";
import { getAccessToken } from "./localStorage";

const URL = process.env.REACT_APP_API_URL?.replace("/api", "");

export const socket = io(URL || "", {
  autoConnect: false,
  extraHeaders: {
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

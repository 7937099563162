import { useAuth } from "hook/useAuth";
import MainLayout from "layouts";
import { Navigate } from "react-router";

function PrivateRoute(props: any) {
  const auth = useAuth();
  const logged = auth.isLoggedIn();

  return logged ? <MainLayout /> : <Navigate to="/login" />;
}

export default PrivateRoute;

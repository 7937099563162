const Icon = ({ type }: { type: string }) => {
  return (
    <>
      {type === "flashcard" ? (
        <svg
          className="anticon"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.78809 13.2191C7.78809 11.6591 9.03455 10.3945 10.5721 10.3945H27.2767C28.8143 10.3945 30.0608 11.6591 30.0608 13.2191V25.5699C30.0608 27.1299 28.8143 28.3945 27.2767 28.3945H10.5721C9.03455 28.3945 7.78809 27.1299 7.78809 25.5699V13.2191Z"
            fill="#7583FF"
          />
          <path
            d="M2.06152 7.21909C2.06152 5.65913 3.3151 4.39453 4.86148 4.39453H21.6616C23.208 4.39453 24.4616 5.65913 24.4616 7.21909V19.5699C24.4616 21.1299 23.208 22.3945 21.6616 22.3945H4.86148C3.3151 22.3945 2.06152 21.1299 2.06152 19.5699V7.21909Z"
            fill="#4255FF"
          />
        </svg>
      ) : type === "learn" ? (
        <svg
          className="anticon"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9997 3.98586C12.6603 2.25518 10.096 1.88419 8.27003 3.12961C6.9179 4.05183 6.32626 5.61699 6.64704 7.08128C2.29064 11.1966 1.19873 17.7127 4.44037 22.9836C7.64255 28.1902 14.0677 30.5486 19.9641 29.1642C21.3622 30.5795 23.6862 30.8131 25.3776 29.6595C27.2208 28.4024 27.6466 25.9521 26.293 24.203C24.9535 22.4724 22.3893 22.1014 20.5633 23.3468C19.595 24.0072 19.0158 24.9979 18.8839 26.0468C14.4685 26.9712 9.74764 25.1591 7.39055 21.3265C5.048 17.5176 5.72389 12.8272 8.73666 9.71969C10.0788 10.3952 11.7687 10.3396 13.0844 9.44232C14.9275 8.18518 15.3534 5.73488 13.9997 3.98586ZM10.3746 5.9412C10.6075 5.78233 10.9342 5.84049 11.0883 6.03954C11.2281 6.22018 11.1954 6.48363 10.9796 6.63083C10.747 6.78951 10.4202 6.73146 10.2662 6.53236C10.1263 6.35164 10.1591 6.08821 10.3746 5.9412ZM22.6678 26.1583C22.9007 25.9995 23.2274 26.0576 23.3815 26.2567C23.5213 26.4373 23.4886 26.7008 23.2728 26.848C23.0401 27.0067 22.7134 26.9486 22.5593 26.7495C22.4194 26.5688 22.4522 26.3053 22.6678 26.1583Z"
            fill="#4255FF"
          />
          <path
            d="M17.8067 3.18826C16.9209 3.12319 16.1102 3.74609 16.0366 4.6281C15.9629 5.51319 16.6649 6.25092 17.5529 6.31615C18.1245 6.35815 18.6921 6.44309 19.2495 6.56994C20.1134 6.76651 21.0131 6.27322 21.2337 5.41881C21.4565 4.5557 20.8868 3.71682 20.0165 3.51877C19.2904 3.35357 18.5513 3.24296 17.8067 3.18826Z"
            fill="#7583FF"
          />
          <path
            d="M25.4227 5.99544C24.7166 5.46848 23.6905 5.5739 23.1219 6.24527C22.5422 6.92985 22.6691 7.92746 23.388 8.46402C23.8341 8.79698 24.251 9.16351 24.6349 9.56101C25.2462 10.194 26.2769 10.2394 26.9474 9.6687C27.6307 9.08713 27.6855 8.08335 27.0621 7.43791C26.5568 6.91471 26.0086 6.43274 25.4227 5.99544Z"
            fill="#7583FF"
          />
          <path
            d="M30.0571 12.3708C29.7813 11.5334 28.8533 11.093 28.0032 11.3397C27.1452 11.5887 26.6322 12.4616 26.9114 13.3094C27.0797 13.8205 27.2029 14.3423 27.2806 14.8702C27.4093 15.7448 28.2557 16.3209 29.1357 16.2067C30.0195 16.0921 30.6748 15.3147 30.5453 14.4348C30.4426 13.7366 30.2796 13.0466 30.0571 12.3708Z"
            fill="#7583FF"
          />
          <path
            d="M28.9685 18.0849C28.117 17.8433 27.1917 18.2891 26.9214 19.1284C26.7564 19.6409 26.548 20.1406 26.2982 20.622C25.886 21.4165 26.2479 22.3582 27.0621 22.7305C27.8653 23.0978 28.8487 22.7927 29.254 22.0113C29.5823 21.3786 29.8563 20.7218 30.0733 20.0478C30.3469 19.1983 29.828 18.3288 28.9685 18.0849Z"
            fill="#7583FF"
          />
        </svg>
      ) : type === "test" ? (
        <svg
          className="anticon"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.7373 6.7649C8.7373 5.45578 9.85161 4.39453 11.2262 4.39453H19.7051C20.3834 4.39453 21.0324 4.65821 21.5018 5.12458L26.7118 10.3008C27.156 10.7421 27.404 11.3298 27.404 11.9411V23.3575C27.404 24.6666 26.2897 25.7279 24.9151 25.7279H11.2262C9.85161 25.7279 8.7373 24.6666 8.7373 23.3575V6.7649Z"
            fill="#7583FF"
          />
          <path
            d="M4.78613 9.43482C4.78613 8.1257 5.90044 7.06445 7.27501 7.06445H15.7539C16.4323 7.06445 17.0812 7.32814 17.5506 7.79451L22.7606 12.9707C23.2048 13.412 23.4528 13.9997 23.4528 14.611V26.0274C23.4528 27.3365 22.3385 28.3978 20.9639 28.3978H7.27501C5.90044 28.3978 4.78613 27.3365 4.78613 26.0274V9.43482Z"
            fill="#4255FF"
          />
          <path
            d="M16.0448 16.5459H8.4596C7.80504 16.5459 7.27441 17.0765 7.27441 17.7311C7.27441 18.3856 7.80504 18.9163 8.4596 18.9163H16.0448C16.6993 18.9163 17.23 18.3856 17.23 17.7311C17.23 17.0765 16.6993 16.5459 16.0448 16.5459Z"
            fill="white"
          />
          <path
            d="M13.5559 21.2871H8.4596C7.80504 21.2871 7.27441 21.8177 7.27441 22.4723C7.27441 23.1269 7.80504 23.6575 8.4596 23.6575H13.5559C14.2105 23.6575 14.7411 23.1269 14.7411 22.4723C14.7411 21.8177 14.2105 21.2871 13.5559 21.2871Z"
            fill="white"
          />
        </svg>
      ) : type === "pause" ? (
        <svg
          className="anticon"
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 1.13402V12.866C0 13.7606 1.02908 14.3042 1.82159 13.8172L11.45 7.9512C12.1833 7.5096 12.1833 6.4904 11.45 6.0374L1.82159 0.182782C1.02908 -0.304168 0 0.239402 0 1.13402Z"
            fill="currentColor"
          />
        </svg>
      ) : type === "shuffle" ? (
        <svg
          className="anticon"
          width="16"
          height="16"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.1371 5.81625L1.97691 0.7875C1.5267 0.34875 0.799417 0.34875 0.349207 0.7875C-0.101013 1.22625 -0.101013 1.935 0.349207 2.37375L5.49784 7.3912L7.1371 5.81625ZM12.632 0.95625L14.0058 2.295L0.337658 15.615C-0.112552 16.0537 -0.112552 16.7625 0.337658 17.2013C0.787878 17.64 1.51515 17.64 1.96537 17.2013L15.645 3.8925L17.0188 5.23125C17.3766 5.58 18 5.3325 18 4.82625V0.5625C18 0.2475 17.746 0 17.4228 0H13.0476C12.5281 0 12.2742 0.6075 12.632 0.95625ZM12.0317 10.5863L10.404 12.1725L14.0173 15.6937L12.632 17.0437C12.2742 17.3925 12.5281 18 13.0476 18H17.4228C17.746 18 18 17.7525 18 17.4375V13.1737C18 12.6675 17.3766 12.42 17.0188 12.78L15.645 14.1188L12.0317 10.5863Z"
            fill="currentColor"
          />
        </svg>
      ) : (
        <></>
      )}
    </>
  );
};

export default Icon;

import { UploadOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Modal, Select, Upload, message } from "antd";
import { useCertificate } from "hook/useCertificate";
import { useSubject } from "hook/useSubject";
import { NotificationContext } from "provider/NotificationProvider";
import { memo, useContext, useEffect, useState } from "react";

const { Option } = Select;

interface Props {
  isOpen: boolean;
  handleSave: () => void;
  handleCancel: () => void;
}

const AddCertificateModal = (props: Props) => {
  const certificate = useCertificate();
  const subject = useSubject();
  const { isOpen, handleCancel, handleSave } = props;
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subjectList, setSubjectList] = useState<Subject.Value[]>([]);
  const [form] = Form.useForm<Certificate.Create>();
  const { openNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (isOpen) {
      getSubjectList();
      form.setFieldsValue({
        link: "",
        subject: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getSubjectList = () => {
    setLoading(true);
    subject.getSubjectList({
      callback: {
        onSuccess: (response: Subject.Value[]) => {
          setSubjectList(response);
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const createCertificate = (value: Certificate.Create) => {
    setSubmitting(true);
    certificate.createCertificate({
      params: value,
      callback: {
        onSuccess: () => {
          openNotification(
            "Thêm chứng chỉ thành công!",
            "Vui lòng chờ hệ thống xét duyệt!"
          );
          handleSave();
          handleCancel();
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setSubmitting(false);
        },
      },
    });
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = (values: any) => {
    createCertificate({
      subject: values?.subject,
      link: values?.link[0]?.response?.url,
    });
  };

  return (
    <>
      <Modal
        title="Thêm chứng chỉ"
        open={isOpen}
        style={{ top: 20 }}
        onCancel={() => handleCancel()}
        footer={
          <Flex align="center" gap="middle">
            <Button
              style={{ marginLeft: "auto" }}
              className="btn-outline"
              type="text"
              size="large"
              disabled={submitting}
              onClick={() => handleCancel()}
            >
              Hủy
            </Button>
            <Button
              style={{ height: "46px" }}
              className="btn-submit"
              type="primary"
              size="large"
              loading={submitting}
              onClick={() => form.submit()}
            >
              Lưu
            </Button>
          </Flex>
        }
      >
        <Form
          className="base-form"
          form={form}
          layout="vertical"
          scrollToFirstError
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item<Certificate.Create>
            label="Chủ đề"
            name="subject"
            hasFeedback
            rules={[{ required: true, message: "Vui lòng chọn chủ đề" }]}
          >
            <Select placeholder="Chủ đề" loading={loading}>
              {subjectList.map((item) => (
                <Option key={item._id} value={item._id}>
                  {item.subject_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item<Certificate.Create>
            label="Tải tệp chứng chỉ (pdf)"
            name="link"
            hasFeedback
            rules={[
              { required: true, message: "Vui lòng tải tệp chứng chỉ (pdf)!" },
            ]}
            getValueFromEvent={normFile}
            valuePropName="link"
          >
            <Upload
              name="file"
              action={`${process.env.REACT_APP_API_URL}/medias/upload`}
              listType="picture"
              maxCount={1}
              beforeUpload={(file) => {
                const isPdf = file.type.includes("pdf");
                if (!isPdf) {
                  message.error(
                    `Tệp ${file.name} ${file.type} không phải tệp pdf.`
                  );
                }
                return isPdf || Upload.LIST_IGNORE;
              }}
            >
              <Button icon={<UploadOutlined />} type="primary">
                Tải tệp
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default memo(AddCertificateModal);

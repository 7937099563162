import { Button, Checkbox, Flex, Form, Input, Modal, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useClass } from "hook/useClass";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.scss";

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  reloadClassData: () => void;
}

const EditClassModal = (props: Props) => {
  const { id } = useParams();
  const classroom = useClass();
  const { isOpen, handleCancel, reloadClassData } = props;
  const [form] = Form.useForm<Class.UpdateClass>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getClassInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getClassInfo = () => {
    if (!id) return;
    setLoading(true);

    classroom.getClassById({
      id,
      callback: {
        onSuccess: (res: Class.UpdateClass) => {
          form.setFieldsValue({
            classroom_name: res.classroom_name,
            description: res.description,
            allow_edit: res.allow_edit,
          });
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const updateClass = () => {
    if (!id) return;
    setSubmitting(true);

    classroom.updateClass({
      id,
      params: form.getFieldsValue(),
      callback: {
        onSuccess: () => {
          reloadClassData();
          handleCancel();
        },
        onFinish: () => {
          setSubmitting(false);
        },
      },
    });
  };

  return (
    <Modal
      className="edit-class"
      title={<h2>Chỉnh sửa lớp </h2>}
      open={isOpen}
      centered
      onCancel={() => handleCancel()}
      footer={
        <Flex justify="end">
          <Button
            style={{ height: "46px" }}
            className="btn-submit"
            type="primary"
            size="large"
            loading={submitting}
            onClick={() => form.submit()}
          >
            Lưu
          </Button>
        </Flex>
      }
      width={800}
      style={{ top: 20 }}
    >
      <Spin spinning={loading}>
        <Form
          className="base-form"
          form={form}
          layout="vertical"
          scrollToFirstError
          autoComplete="off"
          onFinish={updateClass}
        >
          <p>
            Sắp xếp tài liệu học của bạn và chia sẻ chúng với bạn học của bạn.
          </p>
          <Form.Item<Class.CreateClass>
            label="Tên lớp"
            name="classroom_name"
            hasFeedback
            rules={[{ required: true, message: "Vui lòng nhập tên lớp!" }]}
          >
            <Input allowClear placeholder="Nhập tên lớp" />
          </Form.Item>

          <Form.Item<Class.CreateClass> label="Mô tả" name="description">
            <TextArea allowClear placeholder="Mô tả" />
          </Form.Item>

          <Form.Item<Class.CreateClass>
            name="allow_edit"
            valuePropName="checked"
          >
            <Checkbox style={{ fontSize: "1rem" }}>
              Cho phép các thành viên trong lớp thêm và bỏ học phần
            </Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditClassModal;

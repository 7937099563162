import {
  Avatar,
  ChatContainer,
  ConversationHeader,
  Message,
  MessageInput,
  MessageList,
  MessageModel,
} from "@chatscope/chat-ui-kit-react";
import { Spin } from "antd";
import { useChat } from "hook/useReview copy";
import moment from "moment";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { getUserInfo } from "utils/localStorage";
import { socket } from "utils/socket";

interface Props {
  contactId: string;
  userInfo?: User.Info;
  reloadSidebar: () => void;
}

interface MessageProps extends MessageModel {
  key: string;
  avatar: string;
  avatarSpace: boolean;
}

const ChatContent = ({ contactId, userInfo, reloadSidebar }: Props) => {
  const chat = useChat();
  const [messageInputValue, setMessageInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageList, setMessageList] = useState<MessageProps[]>([]);
  const { openNotification } = useContext(NotificationContext);
  const myId: string = getUserInfo()?._id!;
  const contactIdRef = useRef(contactId);

  useEffect(() => {
    contactIdRef.current = contactId;
    getMessages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  useEffect(() => {
    const reload = (arg: Chat.Message) => {
      console.log(arg.contact, contactIdRef.current);
      if (arg.contact === contactIdRef.current) {
        reloadMyContact();
      }

      reloadSidebar();
    };
    socket.on("newMessage", reload);

    return () => {
      socket.off("newMessage", reload);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMessages = () => {
    setLoading(true);
    chat.getAllMessagesByContactId({
      id: contactId,
      callback: {
        onSuccess: (res: any) => {
          setConvertMessageList(res);
        },
        onFailure: (err: any) => {
          setMessageList([]);
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const reloadMyContact = () => {
    chat.getAllMessagesByContactId({
      id: contactIdRef.current,
      callback: {
        onSuccess: (res: any) => {
          setConvertMessageList(res);
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {},
      },
    });
  };

  const createMessage = () => {
    const params = {
      contact: contactId,
      text: messageInputValue,
    };

    setMessageInputValue("");

    chat.createMessage({
      params,
      callback: {
        onSuccess: () => {
          console.log("connected", socket.connected);
          if (!socket.connected) {
            socket.connect();
            reloadMyContact();
          }
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
      },
    });
  };

  const setConvertMessageList = (res?: any) => {
    const list: MessageProps[] =
      res?.messages.map((item: Chat.Message, index: number) => {
        let avatar = true;
        if (index !== 0) {
          if (
            res?.messages[index].sender?._id ===
            res?.messages[index - 1].sender?._id
          ) {
            avatar = false;
          }
        }
        return {
          key: item._id,
          message: item.text,
          sentTime: moment(item.createdAt).format("hh:mm"),
          sender: item.sender.name || "",
          direction: myId === item.sender._id ? "outgoing" : "incoming",
          position: "single",
          avatar:
            myId === item.sender._id
              ? getUserInfo()?.avatar || "/images/default-avatar.svg"
              : item.sender.avatar || "",
          avatarSpace: !avatar,
        };
      }) || [];
    setMessageList(list.reverse());
  };

  return (
    <Spin spinning={loading}>
      <ChatContainer className="chat-container">
        <ConversationHeader>
          <Avatar
            src={userInfo?.avatar || "/images/default-avatar.svg"}
            name={userInfo?.name || ""}
          />
          <ConversationHeader.Content userName={userInfo?.name || ""} />
        </ConversationHeader>
        <MessageList>
          {/* <MessageSeparator content="Saturday, 30 November 2019" /> */}

          {messageList.map((item) => (
            <Message
              key={item.key}
              model={{
                message: item.message,
                sender: item.sender,
                direction: item.direction,
                position: "single",
              }}
              avatarSpacer={item?.avatarSpace}
            >
              {!item.avatarSpace && (
                <Avatar
                  src={item?.avatar || "/images/default-avatar.svg"}
                  name="Zoe"
                />
              )}

              <Message.Header sentTime={item.sentTime} />
            </Message>
          ))}
        </MessageList>
        <MessageInput
          placeholder="Type message here"
          value={messageInputValue}
          onChange={(val) => setMessageInputValue(val)}
          onSend={createMessage}
          attachButton={false}
        />
      </ChatContainer>
    </Spin>
  );
};

export default ChatContent;

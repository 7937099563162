import { useHttpRequest } from "./useHttpRequest";

export const useFolderAPI = () => {
  const httpRequest = useHttpRequest();

  const createFolder = async (
    params: Folder.CreateFolder
  ): Promise<Folder.CreateFolderResponse> =>
    httpRequest.POST("/folders", params);

  const updateFolder = async (
    id: string,
    params: Folder.UpdateFolder
  ): Promise<void> => httpRequest.PATCH(`/folders/${id}`, params);

  const getMyFolders = async (
    params?: App.Pagination
  ): Promise<Folder.MyFoldersPaginationResponse | Folder.FullInfo[]> =>
    httpRequest.GET("/folders/created", { params });

  const getFolderById = async (id: string): Promise<Folder.FullInfo> =>
    httpRequest.GET(`/folders/${id}`);

  const getAllFolder = async (
    params: App.Pagination
  ): Promise<Folder.MyFoldersPaginationResponse | Folder.FullInfo[]> =>
    httpRequest.GET("/folders", { params });

  const getFolderListByQuizId = async (
    id: string
  ): Promise<Folder.FullInfo[]> => httpRequest.GET(`/folders/by-quiz/${id}`);

  const addQuizToManyFolders = async (
    params: Folder.AddQuizToManyFolders
  ): Promise<any> => httpRequest.POST("/folders/add-quiz", params);

  const getFolderByAccountId = async (
    id: string,
    params?: App.Pagination
  ): Promise<Folder.MyFoldersPaginationResponse> =>
    httpRequest.GET(`/folders/by-account/${id}`, { params });

  const deleteFolderById = async (id: string): Promise<Folder.FullInfo> =>
    httpRequest.DELETE(`/folders/${id}`);

  return {
    createFolder,
    getMyFolders,
    getFolderById,
    updateFolder,
    getAllFolder,
    getFolderListByQuizId,
    addQuizToManyFolders,
    getFolderByAccountId,
    deleteFolderById,
  };
};

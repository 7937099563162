import {
  Avatar,
  Conversation,
  ConversationList,
  MainContainer,
  Sidebar,
} from "@chatscope/chat-ui-kit-react";
import { Empty, Spin } from "antd";
import { useChat } from "hook/useReview copy";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useEffect, useState } from "react";
import { getUserInfo } from "utils/localStorage";
import ChatContent from "./ChatContent";
import "./style.scss";

const Chat = () => {
  const chat = useChat();
  const [contactList, setContactList] = useState<Chat.Contact[]>([]);
  const [selectedContact, setSelectedContact] = useState<Chat.Contact>();
  const [loading, setLoading] = useState(false);
  const { openNotification } = useContext(NotificationContext);
  const myId = getUserInfo()?._id;

  useEffect(() => {
    getMyContact();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedContact) {
      reloadMyContact();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact]);

  const getMyContact = () => {
    setLoading(true);
    chat.getMyContact({
      callback: {
        onSuccess: (res: Chat.Contact[]) => {
          setContactList(res);
          if (res.length) {
            setSelectedContact(res[0]);
          }
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const reloadMyContact = () => {
    chat.getMyContact({
      callback: {
        onSuccess: (res: Chat.Contact[]) => {
          setContactList(res);
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
      },
    });
  };

  const getOtherUserInfo = (participants: User.Info[]) => {
    return participants.find((item) => item._id !== myId);
  };

  return (
    <div className="chat-page">
      <Spin spinning={loading}>
        {contactList.length === 0 ? (
          <Empty description="Không có tin nhắn nào"></Empty>
        ) : (
          <MainContainer responsive className="chat-main-container">
            <Sidebar position="left">
              <ConversationList>
                {contactList.map((item) => (
                  <Conversation
                    key={item._id}
                    name={getOtherUserInfo(item.participants)?.name || ""}
                    info={item.latest_message}
                    style={{ justifyContent: "start" }}
                    onClick={() => setSelectedContact(item)}
                    className={
                      selectedContact?._id === item._id ? "contact-active" : ""
                    }
                  >
                    <Avatar
                      src={
                        getOtherUserInfo(item.participants)?.avatar ||
                        "/images/default-avatar.svg"
                      }
                      name={getOtherUserInfo(item.participants)?.name || ""}
                    />
                  </Conversation>
                ))}
              </ConversationList>
            </Sidebar>
            {selectedContact && (
              <ChatContent
                contactId={selectedContact._id}
                userInfo={getOtherUserInfo(selectedContact.participants)}
                reloadSidebar={reloadMyContact}
              />
            )}
          </MainContainer>
        )}
      </Spin>
    </div>
  );
};
export default Chat;

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Row } from "antd";
import { alphabet, getCorrectMessage, getWrongMessage } from "constant/quiz";
import { useEffect, useState } from "react";
import "./style.scss";

interface Props {
  question: Question.Value;
  setAnswered: () => void;
}

const LearnCard = (props: Props) => {
  const { question, setAnswered } = props;
  const [chosenAnswer, setChosenAnswer] = useState("");
  const [titleMessage, setTitleMessage] = useState(
    <p className="title">Chọn câu trả lời đúng</p>
  );

  useEffect(() => {
    setChosenAnswer("");
    setTitleMessage(<p className="title">Chọn câu trả lời đúng</p>);
  }, [question]);

  const onAnswer = (answer: Question.Answer | null) => {
    setAnswered();
    setChosenAnswer(answer?._id || "no-answer");
    if (answer?.is_correct) {
      setTitleMessage(
        <p className="title title-correct">{getCorrectMessage()}</p>
      );
    } else {
      setTitleMessage(<p className="title title-wrong">{getWrongMessage()}</p>);
    }
  };

  const getResultClass = (answer: Question.Answer): string => {
    if (chosenAnswer) {
      if (answer.is_correct) return "correct";
      if (chosenAnswer === answer._id && !answer.is_correct) return "wrong";
    }
    return "";
  };

  return (
    <div className="learn-card">
      <p className="question">{question?.text}</p>
      <div>
        {titleMessage}

        <div className="answers-container">
          <Row gutter={[12, 12]}>
            {question?.answers.map((item: Question.Answer, index) => {
              return (
                <Col xs={24} md={12} key={item._id}>
                  <Flex
                    className={`answer ${getResultClass(item)}`}
                    align="center"
                    gap="large"
                    onClick={() => onAnswer(item)}
                  >
                    {getResultClass(item) === "" ? (
                      <span className="count">{alphabet[index]}</span>
                    ) : getResultClass(item) === "correct" ? (
                      <CheckOutlined
                        style={{ fontSize: "24px", color: "#12815a" }}
                      />
                    ) : (
                      <CloseOutlined
                        style={{ fontSize: "24px", color: "#b00020" }}
                      />
                    )}
                    <span className="content">{item?.text}</span>
                  </Flex>
                </Col>
              );
            })}
          </Row>
        </div>
        {!chosenAnswer && (
          <Flex align="center" justify="center" className="dont-know">
            <Button onClick={() => onAnswer(null)}>Bạn không biết à?</Button>
          </Flex>
        )}
      </div>
    </div>
  );
};

export default LearnCard;

import { Button, Empty, Flex, Table, TableProps, Tabs } from "antd";
import { useQuiz } from "hook/useQuiz";
import { useReview } from "hook/useReview";
import moment from "moment";
import { QuizInfoPageContext } from "provider/QuizInfoPageProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";
import AddToQuiz from "./AddToQuiz";
import Question from "./Question";
import Review from "./Review";
import "./style.scss";
import { PlusOutlined } from "@ant-design/icons";
import AddReviewModal from "./AddReviewModal";

interface Props {
  quizId: string;
  creatorId: string;
  quizName: string;
}

const QuestionsOfQuiz = (props: Props) => {
  const quiz = useQuiz();
  const review = useReview();
  const navigate = useNavigate();
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [testHistory, setTestHistory] = useState<Quiz.History[]>([]);
  const { quizId, quizName, creatorId } = props;
  const [openAddToQuizModal, setOpenAddToQuizModal] = useState(false);
  const { listQuestion, setListQuestion, toggleStarQuestion } =
    useContext(QuizInfoPageContext);

  const [selectedQuestion, setSelectedQuestion] = useState("");

  const [listReview, setListReview] = useState<Review.Value[]>([]);
  const [loading, setLoading] = useState(true);
  const [openAddReviewModal, setOpenAddReviewModal] = useState(false);
  const [exist, setExist] = useState(true);

  const columns: TableProps<Quiz.History>["columns"] = [
    {
      title: "STT",
      dataIndex: "_id",
      key: "index",
      align: "center",
      render: (_: any, record: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Học phần",
      dataIndex: "quiz",
      key: "quiz",
      render: () => <>{quizName}</>,
    },
    {
      title: "Điểm",
      dataIndex: "score",
      key: "score",
      align: "center",
    },
    // {
    //   title: "Thời gian làm bài",
    //   dataIndex: "time",
    //   key: "time",
    //   align: "center",
    // },
    {
      title: "Ngày kiểm tra",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (date: string) => <>{moment(date).format("DD/MM/YYYY HH:mm")}</>,
    },
  ];

  useEffect(() => {
    if (getUserInfo()) {
      getQuizHistory();
      checkExist();
    }
    getReviewListByQuiz();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizId]);

  const getReviewListByQuiz = () => {
    if (!quizId) return;
    setLoading(true);
    review.getReviewListByQuiz({
      id: quizId,
      params: {
        page: 1,
        limit: 100,
      },
      callback: {
        onSuccess: (res: Review.Pagination) => {
          setListReview(res.reviews);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const checkExist = () => {
    if (!quizId) return;
    setLoading(true);
    review.checkExist({
      id: quizId,
      callback: {
        onSuccess: (res: boolean) => {
          setExist(res);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getQuizHistory = () => {
    if (!quizId) return;
    setLoadingHistory(true);
    quiz.getQuizHistory({
      id: quizId,
      callback: {
        onSuccess: (res: any) => {
          setTestHistory(res);
        },
        onFinish: () => {
          setLoadingHistory(false);
        },
      },
    });
  };

  const editSuccess = (newQuestion: Question.Value) => {
    const index = listQuestion.findIndex(
      (item) => item._id === newQuestion?._id
    );
    if (index !== -1) {
      const questionList = [...listQuestion];
      questionList[index] = newQuestion;
      setListQuestion(questionList);
    }
  };

  const getListStarQuestion = () => {
    return listQuestion.filter((item) => item?.is_star === true);
  };

  const getItems = () => {
    const all = {
      label: `Tất cả thuật ngữ (${listQuestion.length})`,
      key: "1",
      children: (
        <Flex vertical gap={8}>
          {listQuestion.map((item) => {
            return (
              <div key={item._id}>
                <Question
                  question={item}
                  creatorId={creatorId}
                  editSuccess={(newQuestion: Question.Value) =>
                    editSuccess(newQuestion)
                  }
                  toggleStar={toggleStarQuestion}
                  setSelectedQuestion={() => {
                    setSelectedQuestion(item?._id!);
                    setOpenAddToQuizModal(true);
                  }}
                />
              </div>
            );
          })}
          <Button
            size="large"
            className="btn-add-remove"
            onClick={() => {
              navigate(`/quizzes/${quizId}/edit`);
            }}
          >
            Thêm hoặc xóa thuật ngữ
          </Button>
        </Flex>
      ),
    };

    const rating = {
      label: `Đánh giá (${listReview.length})`,
      key: "4",
      children: (
        <>
          {!exist && (
            <Button
              type="primary"
              size="large"
              icon={<PlusOutlined />}
              style={{ marginBottom: 16 }}
              onClick={() => setOpenAddReviewModal(true)}
            >
              Đánh giá
            </Button>
          )}
          <Review loading={loading} listReview={listReview} />
        </>
      ),
    };

    const privateItems = [
      {
        label: `Thuật ngữ gắn dấu sao (${getListStarQuestion().length})`,
        key: "2",
        children: (
          <>
            {getListStarQuestion().length ? (
              <Flex vertical gap={8}>
                {getListStarQuestion().map((item) => {
                  return (
                    <div key={item._id}>
                      <Question
                        question={item}
                        creatorId={creatorId}
                        editSuccess={(newQuestion: Question.Value) =>
                          editSuccess(newQuestion)
                        }
                        toggleStar={toggleStarQuestion}
                        setSelectedQuestion={() => {
                          setSelectedQuestion(item?._id!);
                          setOpenAddToQuizModal(true);
                        }}
                      />
                    </div>
                  );
                })}
              </Flex>
            ) : (
              <Empty description="Dữ liệu rỗng" />
            )}
          </>
        ),
      },
      {
        label: `Lịch sử làm bài (${testHistory.length})`,
        key: "3",
        children: (
          <Table
            loading={loadingHistory}
            columns={columns}
            dataSource={testHistory}
            locale={{ emptyText: <Empty description="Dữ liệu rỗng" /> }}
            rowKey={(record) => record._id}
            showSorterTooltip={false}
          />
        ),
      },
    ];

    if (getUserInfo()) {
      return [all, ...privateItems, rating];
    } else {
      return [all, rating];
    }
  };

  return (
    <div className="questions-of-quiz">
      <Flex gap={16} vertical>
        <Tabs defaultActiveKey="1" items={getItems()} />
      </Flex>
      <AddToQuiz
        isOpen={openAddToQuizModal}
        handleCancel={() => setOpenAddToQuizModal(false)}
        questionId={selectedQuestion}
      />

      <AddReviewModal
        isOpen={openAddReviewModal}
        handleCancel={() => setOpenAddReviewModal(false)}
        handelSave={getReviewListByQuiz}
      />
    </div>
  );
};

export default QuestionsOfQuiz;

export enum LevelEnum {
  Beginner = 1,
  Intermediate = 2,
  Advanced = 3,
}

export enum DisplayQuizTypeEnum {
  Learn = 1,
  FlashCard = 2,
  Test = 3,
}

export const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const displayQuizOptions = [
  {
    label: "Thẻ ghi nhớ",
    value: DisplayQuizTypeEnum.FlashCard,
    icon: "flashcard",
  },
  { label: "Học", value: DisplayQuizTypeEnum.Learn, icon: "learn" },
  { label: "Kiểm tra", value: DisplayQuizTypeEnum.Test, icon: "test" },
];

export const wrongMessage = [
  "Chưa đúng, hãy cố gắng nhé!",
  "Đừng nản chí, học là một quá trình!",
  "Đừng lo, bạn vẫn đang học mà!",
];

export const correctMessage = [
  "Làm tốt lắm!",
  "Rất tốt, bạn đã trả lời đúng!",
  "Chăm chỉ sẽ có trái ngọt, tốt lắm!",
  "Bạn đang nắm được kiến thức đấy",
  "Tuyệt vời!",
];

export const getWrongMessage = () => {
  const randomIndex = Math.floor(Math.random() * wrongMessage.length);
  return wrongMessage[randomIndex];
};

export const getCorrectMessage = () => {
  const randomIndex = Math.floor(Math.random() * correctMessage.length);
  return correctMessage[randomIndex];
};

export const listLevel = [
  { value: LevelEnum.Advanced, label: "Khó", color: "red" },
  { value: LevelEnum.Beginner, label: "Dễ", color: "cyan" },
  { value: LevelEnum.Intermediate, label: "Trung bình", color: "orange" },
];

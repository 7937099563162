import { Button, Flex, Form, Modal, Rate } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useReview } from "hook/useReview";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  handelSave: () => void;
}

const AddReviewModal = (props: Props) => {
  const { id } = useParams();
  const review = useReview();
  const [form] = Form.useForm<Review.Create>();
  const { isOpen, handleCancel, handelSave } = props;
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        text: "",
        rating: 5,
        quiz: id,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const createReview = () => {
    if (!id) return;
    setSubmitting(true);

    review.createReview({
      params: form.getFieldsValue(true),
      callback: {
        onSuccess: () => {
          handelSave();
          handleCancel();
        },
        onFinish: () => {
          setSubmitting(false);
        },
      },
    });
  };

  const onFinish = () => {
    createReview();
  };

  return (
    <Modal
      className="add-review-model"
      title={<h2>Thêm đánh giá</h2>}
      open={isOpen}
      onCancel={() => handleCancel()}
      footer={
        <Flex justify="flex-end">
          <Button
            style={{ height: "46px" }}
            className="btn-submit"
            type="primary"
            size="large"
            loading={submitting}
            onClick={() => form.submit()}
          >
            Thêm đánh giá
          </Button>
        </Flex>
      }
      width={800}
      style={{ top: 20 }}
    >
      <div className="form-content">
        <Form
          className="base-form"
          form={form}
          layout="vertical"
          scrollToFirstError
          autoComplete="off"
          onFinish={onFinish}
        >
          <Flex vertical align="center" gap="large">
            <Form.Item<Review.Create> name="rating" noStyle>
              <Rate style={{ fontSize: 30 }} />
            </Form.Item>
            <Form.Item<Review.Create> name="text" noStyle>
              <TextArea
                allowClear
                placeholder="Bình luận..."
                autoSize={{ minRows: 5 }}
              />
            </Form.Item>
          </Flex>
        </Form>
      </div>
    </Modal>
  );
};

export default AddReviewModal;

import {
  Button,
  Checkbox,
  Collapse,
  CollapseProps,
  Flex,
  Form,
  Modal,
  Spin,
} from "antd";
import { alphabet } from "constant/quiz";
import { useQuestion } from "hook/useQuestion";
import { useEffect, useState } from "react";
import "./style.scss";

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  handleSave: (listQuestions: Question.Value[]) => void;
}

const AddFromCreatedQuizzesModal = (props: Props) => {
  const [form] = Form.useForm<{ questions: Question.Value[] }>();
  const question = useQuestion();
  const [loading, setLoading] = useState(false);
  const { isOpen, handleCancel, handleSave } = props;
  const [collapseItems, setCollapseItems] = useState<CollapseProps["items"]>(
    []
  );

  useEffect(() => {
    getCreatedQuizzesFullDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onFinish = (values: any) => {
    handleSave(values?.questions);
    handleCancel();
  };

  const getCreatedQuizzesFullDetails = () => {
    setLoading(true);

    question.getCreatedQuizFullDetails({
      callback: {
        onSuccess: (res: Quiz.FullDetails[]) => {
          setCollapseItems(
            res.map((item) => {
              return {
                key: item?._id,
                label: `Học phần: ${item?.title}`,
                children: (
                  <>
                    <Collapse
                      items={item.questions.map((question) => {
                        return {
                          key: question?._id,
                          label: (
                            <Checkbox
                              value={question}
                              style={{ lineHeight: "32px" }}
                            >
                              {question?.text}
                            </Checkbox>
                          ),
                          children: (
                            <>
                              {question?.answers?.map((answer, index) => (
                                <span
                                  style={{ display: "block" }}
                                  key={answer?._id}
                                >
                                  {question?.answers?.length === 1
                                    ? answer?.text
                                    : `${alphabet[index]}. ${answer?.text}`}
                                </span>
                              ))}
                            </>
                          ),
                        };
                      })}
                    />
                  </>
                ),
              };
            })
          );
        },
        onFailure: (err: any) => {},
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <Modal
      className="add-question-from-quiz-model"
      title={<h2>Thêm câu hỏi/ thuật ngữ từ học phần có sẵn</h2>}
      open={isOpen}
      onCancel={() => handleCancel()}
      footer={
        <Flex justify="flex-end">
          <Button
            style={{ height: "46px" }}
            className="btn-submit"
            type="primary"
            size="large"
            onClick={() => form.submit()}
          >
            Thêm học phần
          </Button>
        </Flex>
      }
      width={800}
      style={{ top: 20 }}
    >
      <Spin spinning={loading}>
        <Form
          className="base-form"
          form={form}
          layout="vertical"
          scrollToFirstError
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            name="questions"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn học phần!",
              },
            ]}
          >
            <Checkbox.Group>
              <Collapse items={collapseItems} />
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddFromCreatedQuizzesModal;

import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Flex, Form, Modal, Spin } from "antd";
import CreateFolderModal from "components/CreateFolderModal";
import { useFolder } from "hook/useFolder";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.scss";

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  foldersHaveQuiz: string[];
  reloadQuizData: () => void;
}

const AddToFolder = (props: Props) => {
  const { id } = useParams();
  const folder = useFolder();
  const [form] = Form.useForm();
  const { isOpen, handleCancel, foldersHaveQuiz, reloadQuizData } = props;
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
  const [listFolder, setListFolder] = useState<Folder.FullInfo[]>([]);
  const { openNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (isOpen) {
      getFolders();
      form.setFieldsValue({
        folders: foldersHaveQuiz,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getFolders = () => {
    setLoading(true);
    folder.getMyFolders({
      callback: {
        onSuccess: (res: Folder.FullInfo[]) => {
          setListFolder(res);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const addToFolder = () => {
    if (!id) return;
    setSubmitting(true);

    folder.addQuizToManyFolders({
      params: {
        quiz: id,
        folders: form.getFieldValue("folders"),
      },
      callback: {
        onSuccess: () => {
          openNotification("Thêm vào thư mục thành công!", "");
          onCancel();
        },
        onFinish: () => {
          setSubmitting(false);
        },
      },
    });
  };

  const onFinish = (values: any) => {
    addToFolder();
  };

  const onCancel = () => {
    handleCancel();
    reloadQuizData();
  };

  return (
    <>
      <Modal
        className="add-folder-model"
        title={<h2>Thêm vào thư mục</h2>}
        open={isOpen}
        onCancel={() => onCancel()}
        footer={
          <Flex justify="flex-end">
            <Button
              style={{ height: "46px" }}
              className="btn-submit"
              type="primary"
              size="large"
              loading={submitting}
              onClick={() => form.submit()}
            >
              Thêm
            </Button>
          </Flex>
        }
        width={800}
        style={{ top: 20 }}
      >
        <Spin spinning={loading} style={{ minHeight: 400 }}>
          <Flex vertical gap="large" className="form-content">
            <Button
              className="btn-border-gray btn-new-folder"
              icon={<PlusOutlined />}
              type="text"
              size="large"
              block
              onClick={() => setOpenAddFolderModal(true)}
            >
              Tạo thư mục mới
            </Button>
            <Form
              className="base-form"
              form={form}
              layout="vertical"
              scrollToFirstError
              autoComplete="off"
              onFinish={onFinish}
            >
              <Form.Item
                name="folders"
                rules={[{ required: true, message: "Vui lòng chọn học phần!" }]}
              >
                <Checkbox.Group>
                  <Flex vertical gap="small">
                    {listFolder.map((item) => {
                      return (
                        <Checkbox
                          value={item._id}
                          key={item._id}
                          style={{ lineHeight: "32px" }}
                          disabled={
                            foldersHaveQuiz.find(
                              (folderId) => folderId === item._id
                            )
                              ? true
                              : false
                          }
                        >
                          <div className="quiz-bar">{item.folder_name}</div>
                        </Checkbox>
                      );
                    })}
                  </Flex>
                </Checkbox.Group>
              </Form.Item>
            </Form>
          </Flex>
        </Spin>
      </Modal>
      <CreateFolderModal
        isOpen={openAddFolderModal}
        handleCancel={() => setOpenAddFolderModal(false)}
        reloadData={getFolders}
      />
    </>
  );
};

export default AddToFolder;

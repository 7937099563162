import { CaretDownFilled } from "@ant-design/icons";
import { Dropdown, Flex, MenuProps } from "antd";
import Icon from "components/Icon";
import { DisplayQuizTypeEnum, displayQuizOptions } from "constant/quiz";
import { Link, useParams } from "react-router-dom";
import "./style.scss";

interface Props {
  path: string;
  icon?: string;
  label: string;
  className?: string;
}

const LabelItem = (props: Props) => {
  const { path, icon, label, className } = props;
  return (
    <Link to={path} className={className}>
      <Flex align="center" gap={16}>
        {icon && <Icon type={icon} />}
        <span>{label}</span>
      </Flex>
    </Link>
  );
};

const SelectMode = ({ mode }: { mode: DisplayQuizTypeEnum }) => {
  const { id } = useParams();

  const items: MenuProps["items"] = [
    {
      label: (
        <LabelItem
          path={`/quizzes/${id}/flashcard`}
          icon="flashcard"
          label="Thẻ ghi nhớ"
        />
      ),
      key: DisplayQuizTypeEnum.FlashCard,
    },
    {
      label: (
        <LabelItem path={`/quizzes/${id}/learn`} icon="learn" label="Học" />
      ),
      key: DisplayQuizTypeEnum.Learn,
    },
    {
      label: (
        <LabelItem path={`/quizzes/${id}/test`} icon="test" label="Kiểm tra" />
      ),
      key: DisplayQuizTypeEnum.Test,
    },
    {
      label: (
        <LabelItem path="/" label="Trang chủ" className="border-top-divider" />
      ),
      key: "home",
    },
    {
      label: <LabelItem path="/search" label="Tìm kiếm" />,
      key: "find",
    },
  ];

  const getItems = (): MenuProps["items"] => {
    return items.filter((item) => item?.key !== mode) || [];
  };

  const getSelectedMode = () => {
    const selectedMode = displayQuizOptions.find((item) => item.value === mode);

    return selectedMode ? (
      <Flex align="center" gap={16} className="selected-mode">
        <Icon type={selectedMode.icon} />
        <span>{selectedMode.label}</span>
        <CaretDownFilled />
      </Flex>
    ) : null;
  };

  return (
    <Dropdown menu={{ items: getItems() }} trigger={["click"]}>
      {getSelectedMode()}
    </Dropdown>
  );
};

export default SelectMode;

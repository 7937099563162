import { Flex, Progress } from "antd";

const Submitting = () => {
  return (
    <Flex
      vertical
      align="center"
      style={{ margin: "auto", padding: "0px 16px" }}
    >
      <h2>Vui lòng đợi, đang tải kết quả của bạn</h2>
      <Progress
        style={{ maxWidth: "800px" }}
        percent={50}
        status="active"
        strokeColor={{ from: "#108ee9", to: "#87d068" }}
      />
    </Flex>
  );
};

export default Submitting;

import { StarFilled } from "@ant-design/icons";
import { Flex, Spin, Tooltip } from "antd";
import { alphabet } from "constant/quiz";
import { useStar } from "hook/useStar";
import { QuizInfoPageContext } from "provider/QuizInfoPageProvider";
import {
  Fragment,
  forwardRef,
  memo,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { getUserInfo } from "utils/localStorage";
import "./style.scss";

interface Props {
  flashcard: Question.Value;
}

const Flashcard = forwardRef((props: Props, ref) => {
  const star = useStar();
  const { flashcard } = props;
  const [isFlipped, setIsFlipped] = useState(false);
  const [loading, setLoading] = useState(false);
  const { toggleStarQuestion } = useContext(QuizInfoPageContext);

  const answer = useMemo(() => {
    if (flashcard.answers.length === 1) return flashcard.answers[0].text;

    let rightAnswer = "";

    for (let i = 0; i < flashcard.answers.length; i++) {
      if (flashcard.answers[i].is_correct) {
        rightAnswer = `${alphabet[i]}. ${flashcard.answers[i].text}`;
        break;
      }
    }

    return rightAnswer;
  }, [flashcard]);

  const toggleFlip = () => {
    setIsFlipped(true);
  };

  const resetCard = () => {
    setIsFlipped(false);
  };

  useImperativeHandle(ref, () => ({
    toggleFlip,
    resetCard,
  }));

  const toggleStar = () => {
    if (!getUserInfo()?._id) return;
    setLoading(true);

    star.toggleStarQuestion({
      question: flashcard?._id!,
      callback: {
        onSuccess: (status: number) => {
          toggleStarQuestion(flashcard?._id!, Boolean(status));
        },
        onFailure: (err: any) => {
          console.log(err);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <div
      className={`flip-card ${isFlipped ? "flip" : ""}`}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div className="flip-card-inner">
        <Flex
          vertical
          align="center"
          justify="center"
          className="flip-card-front"
        >
          <div>
            {flashcard.text}
            {flashcard.answers.length > 1 &&
              flashcard.answers.map((item, index) => {
                return (
                  <Fragment key={item._id}>
                    <br />
                    {`${alphabet[index]}. ${item.text}`}
                  </Fragment>
                );
              })}
          </div>
        </Flex>
        <Flex
          vertical
          align="center"
          justify="center"
          className="flip-card-back"
        >
          <div>{answer}</div>
        </Flex>
      </div>
      {getUserInfo()?._id && (
        <Tooltip placement="bottom" title="Gắn sao">
          <Flex
            align="center"
            justify="center"
            className={`feature-item ${flashcard?.is_star ? "active" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
              toggleStar();
            }}
          >
            <Spin spinning={loading}>
              <StarFilled />
            </Spin>
          </Flex>
        </Tooltip>
      )}
    </div>
  );
});

export default memo(Flashcard);

import {
  Button,
  Col,
  Flex,
  Form,
  FormProps,
  Image,
  Input,
  Row,
  Select,
} from "antd";
import { RoleEnum } from "constant/user";
import { useAuth } from "hook/useAuth";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";

const { Option } = Select;

const initialFormValues: Auth.RegisterParams = {
  email: "",
  password: "",
  confirmPassword: "",
  name: "",
  gender: null,
  role: RoleEnum.User,
};

const Register = () => {
  const { openNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish: FormProps<Auth.RegisterParams>["onFinish"] = (values) => {
    register(values);
  };

  const register = async (values: Auth.RegisterParams): Promise<void> => {
    setLoading(true);
    auth.register({
      params: { ...values, role: RoleEnum.User },
      callback: {
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <div>
      <Row className="register-page">
        <Col xs={0} md={10} lg={12} className="register-page__bg">
          <h1>Cách tốt nhất để học. Đăng ký miễn phí.</h1>
          <Link to="/">
            <Image src="/images/piki-logo-word.svg" alt="" preview={false} />
          </Link>
        </Col>
        <Col xs={24} md={14} lg={12} className="register-page__content">
          <div>
            <h2 className="title">Đăng ký</h2>
            <Form
              className="base-form"
              form={form}
              layout="vertical"
              initialValues={initialFormValues}
              onFinish={onFinish}
              scrollToFirstError
              autoComplete="off"
            >
              <Form.Item<Auth.RegisterParams>
                label="Email"
                name="email"
                hasFeedback
                rules={[
                  { required: true, message: "Vui lòng nhập địa chỉ email!" },
                  { type: "email", message: "Email không đúng định dạng!" },
                ]}
              >
                <Input allowClear placeholder="Nhập địa chỉ email của bạn" />
              </Form.Item>

              <Form.Item<Auth.RegisterParams>
                label="Tên người dùng"
                name="name"
                hasFeedback
                rules={[
                  { required: true, message: "Vui lòng nhập tên người dùng!" },
                ]}
              >
                <Input allowClear placeholder="Nhập tên của bạn" />
              </Form.Item>

              <Form.Item<Auth.RegisterParams>
                label="Mật khẩu"
                name="password"
                hasFeedback
                rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
              >
                <Input.Password
                  allowClear
                  placeholder="Nhập mật khẩu của bạn"
                />
              </Form.Item>

              <Form.Item<Auth.RegisterParams>
                label="Xác nhận mật khẩu"
                name="confirmPassword"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập xác nhận mật khẩu!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Xác nhận mật khẩu không khớp!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  allowClear
                  placeholder="Nhập lại mật khẩu của bạn"
                />
              </Form.Item>

              <Form.Item<Auth.RegisterParams>
                name="gender"
                label="Giới tính"
                hasFeedback
                rules={[
                  { required: true, message: "Vui lòng chọn giới tính!" },
                ]}
              >
                <Select placeholder="Chọn giới tính">
                  <Option value={true}>Nữ</Option>
                  <Option value={false}>Nam</Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  className="btn-submit btn-block"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                >
                  Đăng ký
                </Button>
              </Form.Item>
            </Form>
            <Flex vertical gap="large">
              <Button
                className="btn-block btn-outline"
                type="text"
                disabled={loading}
                onClick={() => navigate("/login")}
              >
                Bạn đã có tài khoản rồi? Đăng nhập
              </Button>
              <Button
                className="btn-block btn-outline"
                type="text"
                disabled={loading}
                onClick={() => navigate("/")}
              >
                Quay lại trang chủ
              </Button>
            </Flex>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Register;

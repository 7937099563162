import { useHttpRequest } from "./useHttpRequest";

export const useAccountAPI = () => {
  const httpRequest = useHttpRequest();

  const getMyProfile = async (): Promise<User.UpdateInfoResponse> =>
    httpRequest.GET("/accounts/profile");

  const getProfileById = async (id: string): Promise<User.UpdateInfoResponse> =>
    httpRequest.GET(`/accounts/${id}`);

  const updateUserInfo = async (
    params: User.UpdateInfoParams
  ): Promise<User.UpdateInfoResponse> =>
    httpRequest.PATCH("/accounts/update", params);

  const getMemberOfClass = async (id: string): Promise<any> =>
    httpRequest.GET(`/accounts/by-classroom/${id}`);

  return { updateUserInfo, getMyProfile, getProfileById, getMemberOfClass };
};

import { useHttpRequest } from "./useHttpRequest";

export const useSubjectAPI = () => {
  const httpRequest = useHttpRequest();

  const getSubjectList = async (): Promise<Subject.Value[]> =>
    httpRequest.GET("/subjects");

  return { getSubjectList };
};

import { Button, Flex } from "antd";
import FeatureCards from "components/FeatureCards";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <Flex vertical gap="4rem" className="landing no-padding">
      <Flex vertical className="banner">
        <div>
          <h1>Làm chủ việc học của bạn với thẻ ghi nhớ và các chế độ học</h1>
          <p>
            Tham gia cộng đồng người học của chúng tôi bằng cách sử dụng thẻ ghi
            nhớ Quizlet, bài kiểm tra thử và chế độ học để cải thiện điểm số.
          </p>
        </div>
        <Button type="primary" onClick={() => navigate("/login")}>
          Thử ngay
        </Button>
      </Flex>
      <section className="section-content">
        <Flex vertical gap="3rem">
          <FeatureCards />
        </Flex>
      </section>
    </Flex>
  );
};

export default LandingPage;

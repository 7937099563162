import { Button, Checkbox, Flex, Form, Modal, Spin } from "antd";
import { useFolder } from "hook/useFolder";
import { useQuiz } from "hook/useQuiz";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.scss";

interface Props {
  quizOfFolder: string[];
  isOpen: boolean;
  handleCancel: () => void;
  reloadQuizData: () => void;
}

const AddQuizModal = (props: Props) => {
  const { id } = useParams();
  const quiz = useQuiz();
  const folder = useFolder();
  const [form] = Form.useForm<Folder.UpdateFolder>();
  const { isOpen, handleCancel, reloadQuizData, quizOfFolder } = props;
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [listQuizzes, setListQuizzes] = useState<Quiz.FullQuizInfo[]>([]);

  useEffect(() => {
    if (isOpen) {
      getQuizzes();
      form.setFieldsValue({
        quizzes: quizOfFolder,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getQuizzes = () => {
    setLoading(true);

    quiz.getMyCreatedQuiz({
      callback: {
        onSuccess: (res: Quiz.FullQuizInfo[]) => {
          setListQuizzes(res);
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const updateFolder = () => {
    if (!id) return;
    setSubmitting(true);

    folder.updateFolder({
      id,
      params: form.getFieldsValue(),
      callback: {
        onSuccess: () => {
          reloadQuizData();
          handleCancel();
        },
        onFinish: () => {
          setSubmitting(false);
        },
      },
    });
  };

  const onFinish = (values: any) => {
    updateFolder();
  };

  return (
    <Modal
      className="add-quiz-model"
      title={<h2>Thêm học phần</h2>}
      open={isOpen}
      onCancel={() => handleCancel()}
      footer={
        <Flex justify="flex-end">
          <Button
            style={{ height: "46px" }}
            className="btn-submit"
            type="primary"
            size="large"
            loading={submitting}
            onClick={() => form.submit()}
          >
            Thêm học phần
          </Button>
        </Flex>
      }
      width={800}
      style={{ top: 20 }}
    >
      <Spin spinning={loading} style={{ minHeight: 400 }}>
        <div className="form-content">
          <Form
            className="base-form"
            form={form}
            layout="vertical"
            scrollToFirstError
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item<Folder.UpdateFolder>
              name="quizzes"
              rules={[{ required: true, message: "Vui lòng chọn học phần!" }]}
            >
              <Checkbox.Group>
                <Flex vertical gap="small">
                  {listQuizzes.map((item) => {
                    return (
                      <Checkbox
                        value={item._id}
                        key={item._id}
                        style={{ lineHeight: "32px" }}
                      >
                        <div className="quiz-bar">{item.title}</div>
                      </Checkbox>
                    );
                  })}
                </Flex>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default AddQuizModal;

import { useHttpRequest } from "./useHttpRequest";

export const useReviewAPI = () => {
  const httpRequest = useHttpRequest();

  const getReviewListByQuiz = async (
    id: string,
    params: App.Pagination
  ): Promise<Review.Pagination> =>
    httpRequest.GET(`/reviews/by-quiz/${id}`, { params });

  const checkExist = async (id: string): Promise<boolean> =>
    httpRequest.GET(`/reviews/check-exist/${id}`);

  const createReview = async (params: Review.Create): Promise<void> =>
    httpRequest.POST("/reviews", params);

  return { getReviewListByQuiz, createReview, checkExist };
};

import { useReviewAPI } from "api/useReviewAPI";
import { get, noop } from "lodash";

export const useReview = () => {
  const reviewAPI = useReviewAPI();

  const getReviewListByQuiz = async ({
    id,
    params,
    callback,
  }: {
    id: string;
    params: App.Pagination;
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await reviewAPI.getReviewListByQuiz(id, params);

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  const checkExist = async ({
    id,
    callback,
  }: {
    id: string;
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await reviewAPI.checkExist(id);

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  const createReview = async ({
    params,
    callback,
  }: {
    params: Review.Create;
    callback: App.Callback;
  }): Promise<void> => {
    const onSuccess = get(callback, "onSuccess", noop);
    const onFailure = get(callback, "onFailure", noop);
    const onFinish = get(callback, "onFinish", noop);

    try {
      const response = await reviewAPI.createReview(params);

      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      onFinish();
    }
  };

  return {
    getReviewListByQuiz,
    createReview,
    checkExist,
  };
};

import { PlusOutlined } from "@ant-design/icons";
import { Button, Empty, Flex, Spin, Table, TableProps, Tag } from "antd";
import { useAccount } from "hook/useAccount";
import { useCertificate } from "hook/useCertificate";
import { NotificationContext } from "provider/NotificationProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddCertificateModal from "./AddCertificateModal";
import AvatarContainer from "./AvatarContainer";
import UpdatePasswordModal from "./UpdatePasswordModal";
import UpdateUserInfoModal from "./UpdateUserInfoModal";
import "./style.scss";

const Setting = () => {
  const account = useAccount();
  const certificate = useCertificate();
  const { openNotification } = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);
  const [isUpdateUserInfo, setIsUpdateUserInfo] = useState(false);
  const [isUpdatePassword, setIsUpdatePassword] = useState(false);
  const [isLoadingCertificate, setIsLoadingCertificate] = useState(false);
  const [certificateList, setCertificateList] = useState<Certificate.Value[]>(
    []
  );
  const [openAddCertificate, setOpenAddCertificate] = useState(false);
  const [profile, setProfile] = useState<User.UpdateInfoResponse>({
    email: "",
    name: "",
    phone: "",
    gender: true,
  });

  useEffect(() => {
    getMyProfile();
    getCertificateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateUserInfo = useCallback(() => {
    getMyProfile();
    setIsUpdateUserInfo(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelUserInfo = useCallback(() => {
    setIsUpdateUserInfo(false);
  }, []);

  const handleUpdatePassword = useCallback(() => {
    setIsUpdatePassword(false);
  }, []);

  const handleCancelPassword = useCallback(() => {
    setIsUpdatePassword(false);
  }, []);

  const getMyProfile = () => {
    setLoading(true);
    account.getMyProfile({
      callback: {
        onSuccess: (response: User.UpdateInfoResponse) => {
          setProfile(response);
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  const getCertificateList = () => {
    setIsLoadingCertificate(true);
    certificate.getMyCertificateList({
      callback: {
        onSuccess: (response: Certificate.Value[]) => {
          setCertificateList(response);
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setIsLoadingCertificate(false);
        },
      },
    });
  };

  const columns: TableProps<Certificate.Value>["columns"] = [
    {
      title: "STT",
      dataIndex: "_id",
      key: "index",
      align: "center",
      render: (_: any, record: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Chủ đề",
      dataIndex: "subject",
      key: "subject",
      render: (_: any, record: Certificate.Value) => (
        <>{record?.subject?.subject_name}</>
      ),
      sorter: (a: Certificate.Value, b: Certificate.Value) =>
        a?.subject.subject_name.length - b?.subject.subject_name.length,
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (_: any, record: Certificate.Value) => (
        <>
          {record?.link && (
            <Link target="_blank" to={record?.link}>
              link
            </Link>
          )}
        </>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "verified",
      key: "verified",
      render: (verified: boolean) => (
        <>
          {verified ? (
            <Tag color="blue" style={{ padding: "4px 8px", fontSize: "14px" }}>
              Đã xác nhận
            </Tag>
          ) : (
            <Tag color="red" style={{ padding: "4px 8px", fontSize: "14px" }}>
              Chờ xác nhận
            </Tag>
          )}
        </>
      ),
      sorter: (a: Certificate.Value, b: Certificate.Value) =>
        Number(a?.verify) - Number(b?.verify),
    },
  ];

  return (
    <>
      <Flex vertical gap="2.5rem" className="setting">
        <h1 className="setting__title">Cài đặt</h1>
        <Flex vertical gap={8} className="setting__content">
          <Flex align="center" justify="space-between">
            <span className="container-title">Thông tin cá nhân</span>
            <Button
              type="text"
              className="btn-primary-transparent"
              onClick={() => setIsUpdateUserInfo(true)}
              disabled={loading}
            >
              Sửa
            </Button>
          </Flex>
          <Spin spinning={loading}>
            <div className="container-content">
              <AvatarContainer />
              <Flex vertical gap="small">
                <span className="title">Tên người dùng</span>
                <span className="content">{profile?.name}</span>
              </Flex>
              <Flex vertical gap="small">
                <span className="title">Email</span>
                <span className="content">{profile?.email}</span>
              </Flex>
              <Flex vertical gap="small">
                <span className="title">Giới tính</span>
                <span className="content">
                  {profile.gender === true
                    ? "Nữ"
                    : profile.gender === false
                    ? "Nam"
                    : ""}
                </span>
              </Flex>
              <Flex vertical gap="small">
                <span className="title">Số điện thoại</span>
                <span className="content">{profile?.phone}</span>
              </Flex>
            </div>
          </Spin>
        </Flex>

        <Flex vertical gap={8} className="setting__content">
          <Flex align="center" justify="space-between">
            <span className="container-title">Chứng chỉ</span>
            <Button
              type="primary"
              onClick={() => setOpenAddCertificate(true)}
              disabled={loading}
              icon={<PlusOutlined />}
            >
              Thêm chứng chỉ
            </Button>
          </Flex>
          <Spin spinning={isLoadingCertificate}>
            <div className="container-content">
              <Table
                dataSource={certificateList}
                columns={columns}
                loading={isLoadingCertificate}
                rowKey={(record) => record._id}
                showSorterTooltip={false}
                locale={{ emptyText: <Empty description="Dữ liệu rỗng" /> }}
              />
            </div>
          </Spin>
        </Flex>
        {/* <Flex vertical gap={8} className="setting__content">
          <span className="container-title">Tài khoản và quyền riêng tư</span>
          <div className="container-content">
            <Flex gap="small" align="center" justify="space-between">
              <span className="title">Đổi mật khẩu</span>
              <Button
                type="text"
                className="btn-primary-transparent"
                onClick={() => setIsUpdatePassword(true)}
              >
                Sửa
              </Button>
            </Flex>
            <Flex gap="large" align="center" justify="space-between">
              <div>
                <p className="title">Xóa tài khoản của bạn</p>
                <p className="content">
                  Thao tác này sẽ xóa tất cả dữ liệu của bạn và không thể hoàn
                  tác.
                </p>
              </div>

              <Button type="primary" danger size="large" className="btn-danger">
                Xóa tài khoản
              </Button>
            </Flex>
          </div>
        </Flex> */}
      </Flex>
      <UpdateUserInfoModal
        isOpen={isUpdateUserInfo}
        profileData={profile}
        handleCancel={handleCancelUserInfo}
        handleSave={handleUpdateUserInfo}
      />
      <UpdatePasswordModal
        isOpen={isUpdatePassword}
        handleCancel={handleCancelPassword}
        handleSave={handleUpdatePassword}
      />
      <AddCertificateModal
        isOpen={openAddCertificate}
        handleCancel={() => setOpenAddCertificate(false)}
        handleSave={getCertificateList}
      />
    </>
  );
};

export default Setting;

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FullscreenOutlined,
  PauseOutlined,
  ReloadOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Carousel,
  Dropdown,
  Flex,
  MenuProps,
  Progress,
  Radio,
  Tooltip,
} from "antd";
import { CarouselRef } from "antd/es/carousel";
import Flashcard from "components/Flashcard";
import Icon from "components/Icon";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";
import FinishReviewCards from "./FinishReviewCards";
import "./style.scss";
import { QuizInfoPageContext } from "provider/QuizInfoPageProvider";

interface Props {
  quizId: string;
  questionList: Question.Value[];
}

const FlashcardModeComponent = (props: Props) => {
  const navigate = useNavigate();
  const { quizId, questionList } = props;
  const carouselRef = useRef<CarouselRef>(null);
  const flashcardRef = useRef<any[]>([]);

  const [shuffle, setShuffle] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [play, setPlay] = useState(false);
  const [starMode, setStarMode] = useState(false);
  const [listQuestion, setListQuestion] = useState<Question.Value[]>([]);
  const [listOriginalQuestion, setListOriginalQuestion] = useState<
    Question.Value[]
  >([]);
  const [finish, setFinish] = useState(false);
  const [speed] = useState(3); // second

  const context = useContext(QuizInfoPageContext);

  useEffect(() => {
    setListQuestion(questionList);
    setListOriginalQuestion(questionList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionList]);

  useEffect(() => {
    if (context.listQuestion.filter((item) => item?.is_star).length === 0) {
      setStarMode(false);
      setTimeout(() => {
        setCurrentIndex(1);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.listQuestion]);

  const afterChange = (index: number) => {
    setCurrentIndex(index + 1);

    if (flashcardRef.current[index] && play) {
      setTimeout(() => {
        flashcardRef.current[index]?.toggleFlip();
      }, speed * 500);
    }

    if (play && index === listQuestion.length - 1) {
      setTimeout(() => {
        setFinish((preValue) => !preValue);
        setPlay((preValue) => !preValue);
      }, speed * 1000);
    }
  };

  const beforeChange = (index: number) => {
    setTimeout(() => {
      flashcardRef.current[index]?.resetCard();
    }, 500);
  };

  const flipFirstCard = (index: number) => {
    if (flashcardRef.current[index]) {
      setTimeout(() => {
        flashcardRef.current[index]?.toggleFlip();
      }, speed * 500);
    }
  };

  const getBadgeTitle = () => {
    if (play && shuffle) {
      return "Tự động cuộn - Trộn thẻ";
    } else if (play) {
      return "Tự động cuộn";
    } else if (shuffle) {
      return "Trộn thẻ";
    }
    return "";
  };

  const resetCard = () => {
    setCurrentIndex(1);
    setFinish(false);
  };

  const goToFirstCard = () => {
    carouselRef.current?.goTo(0);
  };

  const onClickShuffleCard = () => {
    if (shuffle) {
      setListQuestion(listOriginalQuestion);
    } else {
      const newShuffledList = shuffleArray([...listQuestion]);
      setListQuestion(newShuffledList);
    }

    setShuffle(!shuffle);
  };

  const shuffleArray = (array: Question.Value[]) => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  const getNumberOfStarQuestion = () => {
    return listQuestion.filter((item) => item?.is_star)?.length;
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <span className="add-dropdown-item">
          <Flex align="center" gap={16}>
            <Radio
              checked={starMode}
              onClick={() => {
                setStarMode(!starMode);
              }}
              disabled={getNumberOfStarQuestion() ? false : true}
            >
              <span>Chỉ học thuật ngữ có gắn sao</span>
            </Radio>
          </Flex>
        </span>
      ),
      key: "0",
    },
    {
      label: (
        <span className="add-dropdown-item" onClick={goToFirstCard}>
          <span>Khởi động lại Thẻ ghi nhớ</span>
        </span>
      ),
      key: "1",
    },
  ];

  const getListQuestion = () => {
    if (getUserInfo()?._id) {
      if (starMode) {
        return listQuestion.filter((item) => item?.is_star);
      } else {
        if (context.listQuestion.filter((item) => item?.is_star).length === 0) {
        }
      }
    }

    return listQuestion;
  };

  return (
    <div className="flashcard-mode-component">
      {finish ? (
        <FinishReviewCards
          listFlashcard={listOriginalQuestion}
          resetCard={resetCard}
        />
      ) : (
        <Flex vertical gap={16}>
          <div className="carousel-container">
            <Badge.Ribbon
              placement="start"
              className="notification"
              text={getBadgeTitle()}
            >
              <Carousel
                dots={false}
                ref={carouselRef}
                autoplay={play}
                afterChange={afterChange}
                beforeChange={beforeChange}
                className="flashcard-carousel"
                pauseOnHover={false}
                infinite={false}
                autoplaySpeed={speed * 1000}
              >
                {getListQuestion().map((item, index) => {
                  return (
                    <Flashcard
                      flashcard={item}
                      key={item._id}
                      ref={(ref) => {
                        flashcardRef.current[index] = ref;
                      }}
                    />
                  );
                })}
              </Carousel>
            </Badge.Ribbon>
          </div>

          <Progress
            percent={
              getListQuestion().length
                ? ((currentIndex - 1) / getListQuestion().length) * 100
                : 0
            }
            showInfo={false}
            size={["100%", 2]}
          />

          <Flex align="center" justify="space-between" gap="large">
            <Flex align="center" gap="large">
              <Tooltip
                placement="bottom"
                title={play ? "Tạm dừng" : "Bắt đầu"}
                arrow={false}
              >
                <Button
                  size="large"
                  shape="circle"
                  icon={<>{play ? <PauseOutlined /> : <Icon type="pause" />}</>}
                  className={`btn-border-gray ${play ? "on" : ""}`}
                  onClick={() => {
                    if (currentIndex === getListQuestion().length) {
                      carouselRef.current?.goTo(0);
                    } else {
                      flipFirstCard(currentIndex - 1);
                    }
                    setPlay(!play);
                  }}
                />
              </Tooltip>

              <Tooltip placement="bottom" title="Trộn thẻ" arrow={false}>
                <Button
                  size="large"
                  shape="circle"
                  icon={<Icon type="shuffle" />}
                  className={`btn-border-gray ${shuffle ? "on" : ""}`}
                  onClick={onClickShuffleCard}
                  disabled={play}
                />
              </Tooltip>
            </Flex>
            <Flex align="center" gap="small">
              <Button
                disabled={currentIndex === 1 || play}
                size="large"
                shape="circle"
                icon={<ArrowLeftOutlined />}
                className="btn-border-gray btn-control"
                onClick={() => {
                  carouselRef.current?.prev();
                }}
              />
              <div className="count">{`${currentIndex} / ${
                getListQuestion().length
              }`}</div>
              <Button
                disabled={play}
                size="large"
                shape="circle"
                icon={<ArrowRightOutlined />}
                className="btn-border-gray btn-control"
                onClick={() => {
                  if (currentIndex === getListQuestion().length) {
                    setFinish(true);
                  } else {
                    carouselRef.current?.next();
                  }
                }}
              />
            </Flex>
            <Flex align="center" gap="large">
              {getUserInfo()?._id ? (
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  placement="bottomRight"
                  disabled={play}
                >
                  <Button
                    size="large"
                    shape="circle"
                    icon={<SettingOutlined />}
                    className="btn-border-gray"
                  />
                </Dropdown>
              ) : (
                <Button
                  size="large"
                  shape="circle"
                  icon={<ReloadOutlined />}
                  className="btn-border-gray"
                  onClick={goToFirstCard}
                />
              )}
              <Tooltip placement="bottom" title="Toàn màn hình" arrow={false}>
                <Button
                  size="large"
                  shape="circle"
                  icon={<FullscreenOutlined />}
                  className="btn-border-gray btn-fullscreen"
                  onClick={() => navigate(`/quizzes/${quizId}/flashcard`)}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </Flex>
      )}
    </div>
  );
};

export default FlashcardModeComponent;

import { Flex, Tabs } from "antd";
import ReceivedRequest from "components/ReceivedRequest";
import { useState } from "react";
import SentRequest from "./SentRequest";
import "./style.scss";

const MyRequest = () => {
  const [activeTab, setActiveTab] = useState<string>("sent");
  const items = [
    { key: "sent", label: "Yêu cầu đã gửi", children: <SentRequest /> },
    {
      key: "received",
      label: "Yêu cầu đã nhận",
      children: <ReceivedRequest />,
    },
  ];

  return (
    <Flex vertical gap={24} className="my-request-page">
      <h1>Yêu cầu tham gia lớp học của bạn</h1>
      <Tabs
        activeKey={activeTab}
        items={items}
        onChange={(key: string) => setActiveTab(key)}
      />
    </Flex>
  );
};

export default MyRequest;

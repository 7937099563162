import { Button, Checkbox, Form, Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useClass } from "hook/useClass";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  reloadData?: () => void;
}

const initialFormValue: Class.CreateClass = {
  classroom_name: "",
  description: "",
  allow_edit: true,
};

const CreateClassModal = (props: Props) => {
  const classroom = useClass();
  const navigate = useNavigate();
  const { isOpen, handleCancel, reloadData } = props;
  const [form] = Form.useForm<Class.CreateClass>();
  const [submitting, setSubmitting] = useState(false);
  const { openNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue(initialFormValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const createFolder = () => {
    setSubmitting(true);

    classroom.createClass({
      params: form.getFieldsValue(),
      callback: {
        onSuccess: (res: Class.FullInfo) => {
          if (reloadData) {
            reloadData();
          } else {
            navigate(`/classes/${res._id}`);
          }
          handleCancel();
        },
        onFailure: (err: any) => {
          openNotification(err?.message || "", "Vui lòng thử lại", "error");
        },
        onFinish: () => {
          setSubmitting(false);
        },
      },
    });
  };

  return (
    <Modal
      className="create-class-modal"
      title={<h2>Tạo lớp mới</h2>}
      open={isOpen}
      onCancel={() => handleCancel()}
      footer={
        <Button
          style={{ height: "46px" }}
          className="btn-submit"
          type="primary"
          size="large"
          loading={submitting}
          onClick={() => form.submit()}
        >
          Tạo lớp
        </Button>
      }
      width={800}
      style={{ top: 20 }}
    >
      <Form
        className="base-form"
        form={form}
        layout="vertical"
        scrollToFirstError
        autoComplete="off"
        onFinish={createFolder}
      >
        <p>
          Sắp xếp tài liệu học của bạn và chia sẻ chúng với bạn học của bạn.
        </p>
        <Form.Item<Class.CreateClass>
          label="Tên lớp"
          name="classroom_name"
          hasFeedback
          rules={[{ required: true, message: "Vui lòng nhập tên lớp!" }]}
        >
          <Input allowClear placeholder="Nhập tên lớp" />
        </Form.Item>

        <Form.Item<Class.CreateClass> label="Mô tả" name="description">
          <TextArea allowClear placeholder="Mô tả" />
        </Form.Item>

        <Form.Item<Class.CreateClass> name="allow_edit" valuePropName="checked">
          <Checkbox style={{ fontSize: "1rem" }}>
            Cho phép các thành viên trong lớp thêm và bỏ học phần
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateClassModal;

import CacheKey from "constant/app";

export function setAccessToken(token: string): void {
  localStorage.setItem(CacheKey.TOKEN, token);
}

export function getAccessToken(): string {
  return localStorage.getItem(CacheKey.TOKEN) || "";
}

// export function setRefreshToken(token: string): void {
//   localStorage.set(CacheKey.REFRESH_TOKEN, token, {
//     path: "/",
//   });
// }

// export function getRefreshToken(): string {
//   return localStorage.get(CacheKey.REFRESH_TOKEN);
// }

export function setUserInfo(user: Auth.LoginResponseParams): void {
  localStorage.setItem(CacheKey.USER_INFO, JSON.stringify(user));
}

export function getUserInfo(): Auth.LoginResponseParams | undefined {
  const info = localStorage?.getItem(CacheKey.USER_INFO);
  if (info) {
    return JSON.parse(info);
  }

  return undefined;
}

export function setNearlyQuiz(quizzes: string[]): void {
  localStorage.setItem(CacheKey.NEARLY_QUIZ, JSON.stringify(quizzes));
}

export function getNearlyQuiz(): string[] | undefined {
  const quizzes = localStorage?.getItem(CacheKey.NEARLY_QUIZ);
  if (quizzes) {
    return JSON.parse(quizzes);
  }

  return undefined;
}

export function setNearlyUser(userId: string): void {
  localStorage.setItem(CacheKey.NEARLY_USER, JSON.stringify(userId));
}

export function getNearlyUser(): string | undefined {
  const quizzes = localStorage?.getItem(CacheKey.NEARLY_USER);
  if (quizzes) {
    return JSON.parse(quizzes);
  }

  return undefined;
}

// export function setRefreshTime(time: string): void {
//   localStorage.set(CacheKey.REFRESH_TIME, time);
// }

// export function getRefreshTime(): string {
//   return localStorage.get(CacheKey.REFRESH_TIME);
// }

export function isAuthenticated(): boolean {
  const token = getAccessToken();
  return !!token;
}

export function revokeUser(): void {
  localStorage.clear();
}

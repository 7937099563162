import { Button } from "antd";
import { NotificationContext } from "provider/NotificationProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "utils/localStorage";
import { socket } from "utils/socket";

const reconnectLimit = 10;
const reconnectInterval = 2000; // milliseconds

const SocketLayout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isConnect, setIsConnect] = useState(socket.connected);
  const [reconnectTimes, setReconnectTimes] = useState(0);
  const { openNotification } = useContext(NotificationContext);
  const contactIdRef = useRef(location.pathname);

  useEffect(() => {
    function onConnect() {
      console.log("connect");
      //   openNotification("Kết nối socket thành công", "", "success", 2);
      setIsConnect(true);
      setReconnectTimes(0);
    }

    function onDisconnect() {
      console.log("disconnect");
      setIsConnect(false);
    }

    function onConnectError() {
      console.log("connect_error");
      setIsConnect(false);
    }
    if (getUserInfo()?._id) {
      socket.on("connect", onConnect);
      socket.on("connect_error", onConnectError);
      socket.on("disconnect", onDisconnect);
    }

    return () => {
      socket.removeAllListeners();
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    contactIdRef.current = location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    const newMessage = (arg: Chat.Message) => {
      console.log("newMessage");
      if (!contactIdRef.current.includes("chat")) {
        openNotification(
          arg?.sender?.name || "",
          arg.text,
          "info",
          10,
          (api) => (
            <Button
              type="primary"
              size="small"
              block
              onClick={() => {
                api.destroy();
                navigate("/chat");
              }}
            >
              Xem
            </Button>
          )
        );
      }
    };

    const newContact = (arg: any) => {
      socket.emit("room");
    };

    if (isConnect) {
      socket.emit("room");

      socket.on("joinStatus", (arg: any) => {
        console.log("joinStatus", arg);
      });

      socket.on("newMessage", newMessage);

      socket.on("newContact", newContact);
    } else {
      if (getUserInfo()?._id) {
        if (reconnectTimes < reconnectLimit) {
          const reconnectTimer = setTimeout(() => {
            // openNotification("Đang kết nối socket", "", "warning", 2);
            socket.connect();
            setReconnectTimes(reconnectTimes + 1);
          }, reconnectInterval);

          return () => clearTimeout(reconnectTimer);
        } else {
          // openNotification(
          //   "Sự cố kết nối socket",
          //   "Vui lòng kiểm tra lại kết nối, tảilaij trang hoặc đăng nhập lại.",
          //   "error"
          // );
          socket.disconnect();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnect, reconnectTimes]);

  return <>{children}</>;
};

export default SocketLayout;

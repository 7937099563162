import { useHttpRequest } from "./useHttpRequest";

export const useChatAPI = () => {
  const httpRequest = useHttpRequest();

  const getMyContact = async (): Promise<Chat.Contact> =>
    httpRequest.GET("/contacts");

  const getAllMessagesByContactId = async (
    contactId: string
  ): Promise<Chat.Message> =>
    httpRequest.GET(`/messages/by-contact/${contactId}`);

  const createContact = async (id: string): Promise<boolean> =>
    httpRequest.POST("/contacts", { other_account: id });

  const createMessage = async (params: Chat.Create): Promise<void> =>
    httpRequest.POST("/messages", params);

  return {
    getMyContact,
    getAllMessagesByContactId,
    createContact,
    createMessage,
  };
};

import { useHttpRequest } from "./useHttpRequest";

export const useCertificateAPI = () => {
  const httpRequest = useHttpRequest();

  const getMyCertificateList = async (): Promise<Certificate.Value[]> =>
    httpRequest.GET("/certificates");

  const createCertificate = async (params: Certificate.Create): Promise<void> =>
    httpRequest.POST("/certificates", params);

  const updateCertificate = async (params: Certificate.Create): Promise<void> =>
    httpRequest.PATCH("/certificates", params);

  return { getMyCertificateList, createCertificate, updateCertificate };
};

import { get } from "lodash";
import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import axios, { HttpStatusCode } from "axios";
import { useNavigate } from "react-router";
import { getAccessToken, getUserInfo, revokeUser } from "utils/localStorage";

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const AxiosRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  try {
    const token = getAccessToken();

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  } catch (error) {
    throw new Error(error as string);
  }
};

export const useHttpRequest = () => {
  const navigate = useNavigate();
  let instance: AxiosInstance | null = null;

  const http = () => {
    return instance ? instance : initHttp();
  };

  const initHttp = (): AxiosInstance => {
    const http = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers,
    });

    http.interceptors.request.use(AxiosRequest, (error) =>
      Promise.reject(error)
    );

    http.interceptors.response.use(
      (response) => {
        return response.data?.data || response.data;
      },
      (error) => {
        const status = get(error, "response.status");
        const errorData = get(error, "response.data");
        switch (status) {
          case HttpStatusCode.Unauthorized: {
            const userInfo = getUserInfo();
            if (userInfo) {
              revokeUser();
              navigate("/login");
            }
            break;
          }
          case HttpStatusCode.NotFound:
          case HttpStatusCode.BadRequest: {
            return Promise.reject({ ...errorData, status });
          }
          case HttpStatusCode.Forbidden:
          case HttpStatusCode.InternalServerError:
          case HttpStatusCode.TooManyRequests:
            break;
          default:
            break;
        }
        return Promise.reject(errorData);
      }
    );

    instance = http;
    return http;
  };

  const REQUEST = <T, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> => {
    return http().request(config);
  };

  const GET = <T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> => {
    return http().get<T, R>(url, config);
  };

  const POST = <T, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> => {
    return http().post<T, R>(url, data, config);
  };

  const PATCH = <T, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> => {
    return http().patch<T, R>(url, data, config);
  };

  const PUT = <T, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> => {
    return http().put<T, R>(url, data, config);
  };

  const DELETE = <T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> => {
    return http().delete<T, R>(url, config);
  };

  return {
    REQUEST,
    GET,
    POST,
    PATCH,
    PUT,
    DELETE,
  };
};

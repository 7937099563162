import { notification as AntNotification } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import React, { createContext } from "react";

export const NotificationContext = createContext({
  openNotification: (
    message: string,
    description: string,
    type: "error" | "success" | "info" | "warning" | "open" = "success",
    duration?: number,
    btn?: (api: NotificationInstance) => React.ReactNode
  ) => {},
});

const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [api, contextHolder] = AntNotification.useNotification();

  const openNotificationWithIcon = (
    message: string,
    description: string,
    type: "error" | "success" | "info" | "warning" | "open" = "success",
    duration?: number,
    btn?: (api: NotificationInstance) => React.ReactNode
  ) => {
    api[type]({
      message: message,
      description: description,
      duration: duration || 5,
      btn: (btn && btn(api)) || undefined,
    });
  };

  return (
    <NotificationContext.Provider
      value={{ openNotification: openNotificationWithIcon }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

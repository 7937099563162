import { Button, Flex, Modal, Spin } from "antd";
import { useQuestion } from "hook/useQuestion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  quizInfo?: Quiz.FullQuizInfo;
}

const ReviewQuizModal = (props: Props) => {
  const { isOpen, handleCancel, quizInfo } = props;
  const navigate = useNavigate();
  const question = useQuestion();
  const [loading, setLoading] = useState(true);
  const [listQuestion, setListQuestion] = useState<Question.Value[]>([]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizInfo]);

  const getData = () => {
    if (!quizInfo?._id) return;

    setLoading(true);

    question.getQuestionsAnswerOfQuiz({
      id: quizInfo._id,
      callback: {
        onSuccess: (res: Question.Value[]) => {
          setListQuestion(res);
        },
        onFailure: (err: any) => {},
        onFinish: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <Modal
      title={<h2>{quizInfo?.title}</h2>}
      open={isOpen}
      centered
      onCancel={() => handleCancel()}
      footer={
        <>
          {quizInfo && (
            <Flex justify="end">
              <Button
                style={{ height: "46px" }}
                className="btn-submit"
                type="primary"
                size="large"
                onClick={() => navigate(`/quizzes/${quizInfo._id}`)}
              >
                Học
              </Button>
            </Flex>
          )}
        </>
      }
      width={800}
      className="review-quiz-modal"
      style={{ top: 20 }}
    >
      <Spin spinning={loading}>
        <Flex gap="large" vertical>
          {listQuestion.map((item) => {
            return (
              <div key={item._id} className="review-question">
                <p>{item.text}</p>
                <p>
                  {item.answers.find((item) => item.is_correct === true)?.text}
                </p>
              </div>
            );
          })}
        </Flex>
      </Spin>
    </Modal>
  );
};

export default ReviewQuizModal;
